/*Table of Contents
01 body
02 Helper Classes
   02-1 short codes
03 navigation
   03-1 side menu
04 owl carousel
05 revolution slider
06 half-section
07 page-header
08 work process
09 our team
10 gallery portfolio
11 mobile apps & features
12 Counters
13 pricings
14 background parallax
15 Testimonials
16 Partner
17 our blog
   17-1 sidebar
   17-2 widgets
18 Ccntact US
19 404 error
20 Accordions
21 footer
22 preloader

Table Of Contents ends */


/*Global Styling*/
/*@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Raleway:200,300,400,500,600,700,800');*/
body {
    padding: 0;
    margin: 0;
    font-family: 'Raleway', sans-serif;
    font-size: 1rem;
    color: #a5a5a5;
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

body.full-page {
    overflow: hidden !important;
}

html {
    font-size: 16px;
}
ol,ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
a, a:hover, a:focus {
    outline: none;
    text-decoration: none;
    color: inherit;
}
input:focus{
    outline: none;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0;
}
h1 {
    font-size: 3.75rem;
}
h2 {
    font-size: 3.5rem;
}
h3 {
    font-size: 1.875rem;
}
h4 {
    font-size: 1.25rem;
}
h5{
    font-size: 1rem;
}
h6{
    font-size: 0.875rem;
}
p{
    font-size: 14px;
}
p.title {
    font-size: 1rem;
}
.block{
    display: block;
}
.italic {
    font-style: italic;
}
button{
    line-height: 1;
}


/*common font family*/
p, .heading-title > span, .process-wrapp li > .pro-step,
.progress-bars .progress p, .progress-bars .progress .progress-bar span,
.price-table .ammount .dur, .pagination li > a, .counters .count_nums,
.price-table .ammount h2, .price-table ul li, .webcats li a > span,
.getin_form .form-control{
    font-family: 'Open Sans', sans-serif;
}

/*fontAwesome*/
.zeus.tparrows.tp-rightarrow::before,
.zeus.tparrows.tp-leftarrow::before,
.price-table ul li > span::before,
.testimonial-quote h3::before,
.testimonial-quote h3::after,
blockquote.blockquote::before{
    font-family:'FontAwesome';
}

/* Helper Classes & Shorcodes */
.padding {
    padding: 7.5rem 0;
}
.padding_top {
    padding-top:7.5rem;
}
.padding_bottom {
    padding-bottom: 7.5rem;
}
.margin_bottom {
    margin-bottom: 7.5rem;
}
.margin_top {
    margin-top: 7.5rem;
}
.padding_half {
    padding: 4.375rem 0;
}
.margin_half {
    margin: 4.375rem 0;
}
.padding_bottom_half {
    padding-bottom: 4.375rem;
}
.margin_bottom_half{
    margin-bottom: 4.375rem;
}
.padding_top_half {
    padding-top: 4.375rem;
}
.heading_space {
    margin-bottom: 4.125rem;
}
.bottom5 {
    margin-bottom: 5px;
}
.bottom10 {
    margin-bottom: 10px;
}
.top10 {
    margin-top: 10px;
}
.bottom15 {
    margin-bottom: 15px;
}
.top15 {
    margin-top: 15px;
}
.top20 {
    margin-top: 20px;
}
.bottom20 {
    margin-bottom: 20px;
}
.bottom25 {
    margin-bottom: 25px;
}
.top25 {
    margin-top: 25px;
}
.bottom30 {
    margin-bottom: 30px;
}
.top30 {
    margin-top: 30px;
}
.bottom35 {
    margin-bottom: 35px;
}
.top40 {
    margin-top: 40px;
}
.bottom40 {
    margin-bottom: 40px;
}
.bottom45 {
    margin-bottom: 45px;
}
.top50 {
    margin-top: 50px;
}
.top60{
    margin-top: 60px;
}
.bottom60{
    margin-bottom: 60px;
}
.bottom0 {
    margin-bottom: 0;
}
.nomargin {
    margin: 0;
}
.nopadding{
    padding: 0;
}
.fontbold{
    font-weight: bold;
}
.fontmedium {
    font-weight: 500;
}
.font-light{
    font-weight: 300;
}
.font-xlight {
    font-weight: 200;
}
.bglight {
    background: #f6f6f6;
}
.bgdefault {
    background: #00bcd4;
}
.whitecolor {
    color: #ffffff;
}
.darkcolor {
    color: #212331;
}
.extradark-color {
    color: #212331;
}
.defaultcolor {
    color: #00bcd4;
}
section{
    position: relative;
}
.container-padding {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
}
.gradient_bg{
    background: -webkit-linear-gradient(90deg, #423f9c 31%, #862359 69%);
    background: -webkit-gradient(linear, left top, right top, color-stop(31%, #423f9c), color-stop(69%, #862359));
    background: -webkit-linear-gradient(left, #423f9c 31%, #862359 69%);
    background: -o-linear-gradient(left, #423f9c 31%, #862359 69%);
    background: linear-gradient(90deg, #423f9c 31%, #862359 69%);
}
.gradient_bg_default{
    background: -webkit-linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
    background: -webkit-gradient(linear, left top, right top, color-stop(31%, #00e4ce), color-stop(69%, #00abc9));
    background: -webkit-linear-gradient(left, #00e4ce 31%, #00abc9 69%);
    background: -o-linear-gradient(left, #00e4ce 31%, #00abc9 69%);
    background: linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
}
.gradient_bg_alt{
    background: -webkit-linear-gradient(90deg, #90A4AE 31%, #607D8B 69%);
    background: -webkit-gradient(linear, left top, right top, color-stop(31%, #90A4AE), color-stop(69%, #607D8B));
    background: -webkit-linear-gradient(left, #90A4AE 31%, #607D8B 69%);
    background: -o-linear-gradient(left, #90A4AE 31%, #607D8B 69%);
    background: linear-gradient(90deg, #90A4AE 31%, #607D8B 69%);
}

/*heading Titles */
.heading-title > span{
    font-size: 1.25rem;
    display: block;
    text-transform: capitalize;
    color: #00bcd4;
}
.whitecolor.heading-title > span{
    color: #fff;
}
.heading-title h2{
    font-weight: 300;
}


/*img wrap*/
.image {
    overflow: hidden;
}
a.image{
    display: block;
}
.image img {
    width: 100%;
    display: block;
}
.image, .image img {
    position: relative;
}

/*hover on images*/
.hover-effect::before,
.hover-effect::after {
    content: "";
    background: #fff;
    height: 0;
    width: 0;
    z-index: 1;
    position: absolute;
    -webkit-transition-duration: 1.3s;
    -o-transition-duration: 1.3s;
    transition-duration: 1.3s;
}
.hover-effect::before {
    right: 0;
    opacity: 1;
    top: 0;
}
.hover-effect::after {
    bottom: 0;
    opacity: .7;
    left: 0;
}
.hover-effect:hover::after, .hover-effect:hover::before {
    height: 100%;
    opacity: 0;
    width: 100%;
}


/*to align elements center*/
.center-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
/*Back To Top*/
.back-top {
    color: #fff;
    right: 20px;
    font-size: 26px;
    position: fixed;
    z-index: 1600;
    opacity: 0;
    visibility: hidden;
    bottom: 70px;
    background: -webkit-linear-gradient(90deg, #423f9c, #862359);
    background: -webkit-gradient(linear, left top, right top, color-stop(#423f9c), color-stop(#862359));
    background: -webkit-linear-gradient(left, #423f9c, #862359);
    background: -o-linear-gradient(left, #423f9c, #862359);
    background: -webkit-gradient(linear, left top, right top, from(#423f9c), to(#862359));
    background: linear-gradient(90deg, #423f9c, #862359);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 36px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.back-top-visible {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}
.back-top::before {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    background: -webkit-linear-gradient(90deg, #643094, #00bcd4);
    background: -webkit-gradient(linear, left top, right top, color-stop(#643094), color-stop(#00bcd4));
    background: -webkit-linear-gradient(left, #643094, #00bcd4);
    background: -o-linear-gradient(left, #643094, #00bcd4);
    background: -webkit-gradient(linear, left top, right top, from(#643094), to(#00bcd4));
    background: linear-gradient(90deg, #643094, #00bcd4);
}
.back-top:hover::before, .back-top:focus::before {
    opacity: 1;
    visibility: visible;
}
.back-top:hover, .back-top:focus {
    color: #fff;
}

/* ----- Social Icons ----- */
ul.social-icons-simple li,
ul.social-icons li {
    display: inline-block;
}
ul.social-icons li a {
    height: 36px;
    line-height: 36px;
    width: 36px;
    font-size: 17px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin: 0 0.1rem;
    text-align: center;
    display: block;
    color: #676767;
    background: #00bcd4;
}
ul.social-icons.whitebg li a {
    background: #fff;
}
ul.social-icons-simple li a {
    height: 24px;
    line-height: 24px;
    width: 24px;
    margin: 0 8px;
    font-size: 18px;
    text-align: center;
    display: inline-block;
}
ul.social-icons.white li a,
ul.social-icons-simple.white li a{
    color: #fff;
}
ul.social-icons-simple li:last-child a,
ul.social-icons li:last-child a{
    margin-right: 0;
}
ul.social-icons-simple li:first-child a,
ul.social-icons li:first-child a{
    margin-left: 0;
}
ul.social-icons-simple li a:hover,
ul.social-icons-simple li a:focus{
    color: #00bcd4;
}
ul.social-icons li a:hover,
ul.social-icons li a:focus,
ul.social-icons.white li a:hover,
ul.social-icons.white li a:focus{
    color: #fff;
    -webkit-box-shadow: 0 0 15px 30px #00bcd4 inset;
    box-shadow: 0 0 15px 30px #00bcd4 inset;
}



/*-----Buttons-----*/
.button {
    position: relative;
    display: inline-block;
    font-size: 13px;
    padding: 1rem 2.5rem;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    z-index: 1;
    border: 1px solid transparent;
    -webkit-border-radius: 30px;
    border-radius: 30px;
    overflow: hidden;
}
.square-layout .button {
    -webkit-border-radius: 0;
    border-radius: 0;
}
.btnprimary, .btnprimary-alt{
    background: #00bcd4;
}
.btnsecondary{
    background: #643094;
}
.btnwhite{
    background: #fff;
    color: #212331;
}
.btnwhite-hole {
    background: transparent;
    border: 1px solid #fff;
}
.btnwhite:hover, .btnwhite:focus{
    background: #00bcd4;
}

.btnwhite-hole:hover, .btnwhite-hole:focus {
    color: #212331;
    background: #fff;
}
.button > i {
    vertical-align: middle;
    margin-top: -3px;
}
.btnprimary:hover, .btnprimary:focus{
    background: #643094;
    border: 1px solid #643094;
}
.btnprimary-alt:hover, .btnprimary-alt:focus {
    background: transparent;
    border: 1px solid #00bcd4;
    color: #00bcd4;
}
.rev_slider .btnprimary-alt:hover, .rev_slider .btnprimary-alt:focus {
    background: transparent;
    border: 1px solid #fff;
}

.btnsecondary:hover, .btnsecondary:focus {
    background: #00bcd4;
    border: 1px solid #00bcd4;
}
.btnsecondary.hvrwhite:hover, .btnsecondary.hvrwhite:focus,
.btnprimary.hvrwhite:hover, .btnprimary.hvrwhite:focus {
    background: #fff;
    border: 1px solid #fff;
    color: #212331;
}

.btn-gradient-hvr::before {
    background: -webkit-linear-gradient(90deg, #423f9c, #862359);
    background: -webkit-gradient(linear, left top, right top, color-stop(#423f9c), color-stop(#862359));
    background: -webkit-linear-gradient(left, #423f9c, #862359);
    background: -o-linear-gradient(left, #423f9c, #862359);
    background: -webkit-gradient(linear, left top, right top, from(#423f9c), to(#862359));
    background: linear-gradient(90deg, #423f9c, #862359);
}
.btn-gradient-hvr::before,
.scndry-gradient-hvr::before {
    content: "";
    left: 0; right: 0;
    top: 0; bottom: 0;
    position: absolute;
    z-index: -1;
    opacity: 0; visibility: hidden;
}
.scndry-gradient-hvr::before {
    background: -webkit-linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
    background: -webkit-gradient(linear, left top, right top, color-stop(31%, #00e4ce), color-stop(69%, #00abc9));
    background: -webkit-linear-gradient(left, #00e4ce 31%, #00abc9 69%);
    background: -o-linear-gradient(left, #00e4ce 31%, #00abc9 69%);
    background: linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
}
.btn-gradient-hvr:hover::before,
.btn-gradient-hvr:focus::before,
.scndry-gradient-hvr:hover::before,
.scndry-gradient-hvr:focus::before{
    opacity: 1;
    visibility: visible;
}
.btn-gradient-hvr:hover, .btn-gradient-hvr:focus {
    border-color: #643094;
}
.scndry-gradient-hvr:hover, .scndry-gradient-hvr:focus {
    border-color: #00bcd4;
}

.btnwhite-hole, .btnwhite:hover, .btnwhite:focus,
.btnprimary, .btnsecondary, .btnprimary-alt,
.btnprimary:hover, .btnprimary:focus,
.btnsecondary:hover, .btnsecondary:focus,
.rev_slider .btnprimary-alt:hover, .rev_slider .btnprimary-alt:focus{
    color: #fff;
}


/* transitions common*/
a, *::before, *::after,img, span, input, button,
.navbar, .fixedmenu, .tp-bullet, .owl-dot, .owl-prev, .owl-next{
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}
i::before,i::after{
    -webkit-transition: all 0s !important;
    -o-transition: all 0s !important;
    transition: all 0s !important;
}

/*-------------------------------*/
/*Navigation Starts */
/*-------------------------------*/
.navbar {
    padding-bottom: 0;
    padding-top: 0;
    z-index: 1000;
}
.center-brand {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.center-brand, .transparent-bg {
    left: 0;
    top: 0;
}
.center-brand, .transparent-bg,
.fixed-bottom, .bg-transparent-light,
.bg-transparent-white,
.bg-invisible, .boxed-nav {
    position: absolute;
    width: 100%;
}
.bg-invisible .container {
    opacity: 0;
    visibility: hidden;
}
.fixed-bottom {
    bottom: 0;
}
.bg-white {
    position: relative;
}
.bg-white,
.boxed-nav .container {
    background: #fff;
}
.boxed-nav {
    top: 20px;

}
.fixedmenu {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    margin: 0;
    padding-bottom: 0;
    padding-top: 0;
    z-index: 1002;
    -webkit-animation-name: animationFade;
    -o-animation-name: animationFade;
    animation-name: animationFade;
    -webkit-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;

    -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
    background: -webkit-linear-gradient(90deg, #423f9c 31%, #862359 69%);
    background: -webkit-gradient(linear, left top, right top, color-stop(31%, #423f9c), color-stop(69%, #862359));
    background: -webkit-linear-gradient(left, #423f9c 31%, #862359 69%);
    background: -o-linear-gradient(left, #423f9c 31%, #862359 69%);
    background: linear-gradient(90deg, #423f9c 31%, #862359 69%);
}






















.center-brand.fixedmenu,
.bg-white.fixedmenu,
.bg-transparent-light.fixedmenu,
.bg-transparent-white.fixedmenu,
.bg-invisible.fixedmenu,
.boxed-nav.fixedmenu {
    background: #fff;
}
.fixed-bottom.fixedmenu{
    bottom: auto;
    -webkit-animation: none;
    -o-animation: none;
    animation: none;
}
.bg-invisible.fixedmenu .container {
    opacity: 1;
    visibility: visible;
}
@-webkit-keyframes animationFade {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
@-o-keyframes animationFade {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        -o-transform: none;
        transform: none;
    }
}
@keyframes animationFade {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        -o-transform: none;
        transform: none;
    }
}
.nav-whitebg {
    background: #fff;
    -webkit-transition: all .9s ease;
    -o-transition: all .9s ease;
    transition: all .9s ease;
}
.navbar.sidebar-nav {
    background: #fff;
    border: none;
    display: inline-table;
    width: 290px;
    -webkit-border-radius: 0;
    border-radius: 0;
    position: fixed;
    top: 0;
    z-index: 110;
    height: 100%;
    padding: 3rem 2rem;
    border-right: 1px solid rgba(0,0,0,.07);
    left: 0;
    overflow-x: hidden;
}

.navbar-brand  {
    margin: 16px 0;
    padding: 0;
    width: 140px;
    position: relative;
}
.center-brand .navbar-brand {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0;
}
.navbar-brand > img {
    width: 100%;
}
.navbar.fixedmenu .logo-default,
.navbar  .logo-scrolled{
    display: none;
}
.navbar.fixedmenu .logo-scrolled,
.navbar .logo-default{
    display: inline-block;
}

.navbar.sidebar-nav .navbar-nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
}
.navbar-nav .nav-item {
    margin: 16px 5px;
    position: relative;
}
.navbar.sidebar-nav .navbar-nav .nav-item {
    margin: 10px 0;
}
.navbar-nav .nav-item:first-child {
    margin-left: 0;
}
.navbar-nav .nav-item:last-child {
    margin-right: 0;
}
.navbar-nav .nav-link {
    padding: 8px 1.25rem !important;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 14px;
    color: #212331;
    position: relative;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    overflow: hidden;
}
.sidebar-nav .navbar-nav .nav-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.square-layout .navbar-nav .nav-link {
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 1px solid transparent;
}
.center-brand .navbar-nav .nav-link,
.transparent-bg .navbar-nav .nav-link,
.fixed-bottom .navbar-nav .nav-link{
    color: #fff;
}
.center-brand.fixedmenu .navbar-nav .nav-link {
    color: #212331;
}
.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus,
.sidebar-nav .navbar-nav .nav-link.active{
    color: #00bcd4;
}
.fixedmenu .navbar-nav .nav-link.active,
.fixedmenu .navbar-nav .nav-link.active:hover,
.fixedmenu .navbar-nav .nav-link.active:focus,
.center-brand.fixedmenu .navbar-nav .nav-link.active{
    background: #00bcd4;
    color: #fff;
}
.square-layout .navbar-nav .nav-link.active,
.square-layout .navbar-nav .nav-link:hover,
.square-layout .navbar-nav .nav-link:focus{
    border: 1px solid #c1c1c1;
    background: transparent !important;
}
.square-layout .fixedmenu .navbar-nav .nav-link.active,
.square-layout .fixedmenu .navbar-nav .nav-link:hover,
.square-layout .fixedmenu .navbar-nav .nav-link:focus{
    border: 1px solid #c1c1c1;
    background: transparent !important;
    color: #212331 !important;
}

.center-brand .navbar-nav .nav-link:first-of-type {
    background: rgba(0,0,0,0);
}


/*toggle responsive*/
.navbar-toggler {
    position: relative;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
}
.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0;
}
.navbar-toggler span {
    display: block;
    background: #fff;
    height: 2px;
    width: 25px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    position: relative;
    left: 0;
    opacity: 1;
}
.bg-white .navbar-toggler span,
.bg-transparent-light .navbar-toggler span,
.bg-invisible .navbar-toggler span,
.navbar.sidebar-nav .navbar-toggler span,
.boxed-nav .navbar-toggler span {
    background: #212331;
}
.center-brand.fixedmenu .navbar-toggler span {
    background: -webkit-linear-gradient(90deg, #643094, #00bcd4);
    background: -webkit-gradient(linear, left top, right top, color-stop(#643094), color-stop(#00bcd4));
    background: -webkit-linear-gradient(left, #643094, #00bcd4);
    background: -o-linear-gradient(left, #643094, #00bcd4);
    background: -webkit-gradient(linear, left top, right top, from(#643094), to(#00bcd4));
    background: linear-gradient(90deg, #643094, #00bcd4);
}
.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
    -webkit-transition: transform .35s ease-in-out;
    -o-transition: transform .35s ease-in-out;
    -webkit-transition: -webkit-transform .35s ease-in-out;
    transition: -webkit-transform .35s ease-in-out;
    -o-transition: -o-transform .35s ease-in-out;
    transition: transform .35s ease-in-out;
    transition: transform .35s ease-in-out, -webkit-transform .35s ease-in-out, -o-transform .35s ease-in-out;
}
.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute;
    left: 12px;
    top: 10px;
    -webkit-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    opacity: 0.9;
}
.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 3px;
    visibility: hidden;
    background-color: transparent;
}
.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute;
    left: 12px;
    top: 10px;
    -webkit-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
    opacity: 0.9;
}


/*-----Side Menu----*/
.side-menu {
    width: 40%;
    position: fixed;
    right: 0;
    top: 0;
    background: #00bcd4;
    z-index: 1032;
    height: 100%;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    -o-transition: -o-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease, -o-transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
    overflow: hidden;
}
.side-menu.left {
    left: 0; right: auto;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}
.side-menu.before-side {
    width: 280px;
}
.just-sidemenu .side-menu {
    background: -webkit-linear-gradient(90deg, #423f9c, #862359);
    background: -webkit-gradient(linear, left top, right top, color-stop(#423f9c), color-stop(#862359));
    background: -webkit-linear-gradient(left, #423f9c, #862359);
    background: -o-linear-gradient(left, #423f9c, #862359);
    background: -webkit-gradient(linear, left top, right top, from(#423f9c), to(#862359));
    background: linear-gradient(90deg, #423f9c, #862359);
}
.transparent-sidemenu .side-menu {
    background: rgba(0,0,0,.95);
}
.side-menu.side-menu-active,
.side-menu.before-side{
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.pul-menu .side-menu.side-menu-active {
    visibility: visible; opacity: 1;
}
.side-menu .navbar-brand {
    margin: 0 0 2.5rem 0;
}

/*Side overlay*/
#close_side_menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    -webkit-transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    -o-transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    display: none;
    z-index: 1031;
    opacity: 0.4;
}

/*side clode btn*/
.side-menu .btn-close {
    height: 33px;
    width: 33px;
    -webkit-box-shadow: 0 4px 4px -4px rgba(0,0,0,0.15);
    box-shadow: 0 4px 4px -4px rgba(0,0,0,0.15);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    position: absolute;
    top: 30px;
    right: 15px;
    cursor: pointer;
}
.side-menu.before-side .btn-close{
    display: none;
}
.just-sidemenu #sidemenu_toggle {
    position: fixed;
    z-index: 999;
    /*background: #fff;*/
    right: 0;
    top: 16px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .15);
    box-shadow: 0 0 20px rgba(0, 0, 0, .15);
}
.side-menu .btn-close::before, .side-menu .btn-close::after {
    position: absolute;
    left: 16px;
    content: ' ';
    height: 24px;
    width: 2px;
    background: #fff;
    top: 5px;
}
.side-menu .btn-close:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
.side-menu .btn-close:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

/*side open btn*/
.sidemenu_btn {
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    width: 36px;
    padding: 6px;
    margin-right: 1rem;
    position: absolute;
    right: 15px;
}
.sidemenu_btn.left {
    left: 15px;
    right: auto;
}
.sidemenu_btn > span {
    height: 2px;
    width: 100%;
    background: #212331;
    display: block;
}
.center-brand .sidemenu_btn > span,
.transparent-bg .sidemenu_btn > span,
.fixed-bottom .sidemenu_btn > span,
.just-sidemenu .toggle_white.sidemenu_btn > span,
.bg-invisible .sidemenu_btn > span,
.boxed-nav .sidemenu_btn > span {
    background: #fff;
}
.center-brand.fixedmenu .sidemenu_btn > span,
.bg-invisible.fixedmenu .sidemenu_btn > span,
.boxed-nav.fixedmenu .sidemenu_btn > span {
    background: #212331;
}
.sidemenu_btn > span:nth-child(2) {
    margin: 4px 0;
}

.side-menu .inner-wrapper {
    padding: 3.5rem 5rem;
    height: 100%;
    position: relative;
    overflow-y: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.pul-menu.pushwrap .side-menu .inner-wrapper{
    padding: 3.5rem 2.5rem;
}
.side-menu .side-nav {
    margin-bottom: 30px;
    display: block;
}
.side-nav .navbar-nav .nav-item{
    display: block;
    margin: 10px 0; padding: 0 !important;
    opacity: 0;
    -webkit-transition: all 0.8s ease 500ms;
    -o-transition: all 0.8s ease 500ms;
    transition: all 0.8s ease 500ms;

    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
}
.side-nav .navbar-nav .nav-item:first-child {
    -webkit-transition-delay: .1s;
    -o-transition-delay: .1s;
    transition-delay: .1s;
}
.side-nav .navbar-nav .nav-item:nth-child(2){
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s;
}
.side-nav .navbar-nav .nav-item:nth-child(3) {
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
}
.side-nav .navbar-nav .nav-item:nth-child(4) {
    -webkit-transition-delay: .4s;
    -o-transition-delay: .4s;
    transition-delay: .4s;
}
.side-nav .navbar-nav .nav-item:nth-child(5) {
    -webkit-transition-delay: .5s;
    -o-transition-delay: .5s;
    transition-delay: .5s;
}
.side-nav .navbar-nav .nav-item:nth-child(6) {
    -webkit-transition-delay: .6s;
    -o-transition-delay: .6s;
    transition-delay: .6s;
}
.side-nav .navbar-nav .nav-item:nth-child(7) {
    -webkit-transition-delay: .7s;
    -o-transition-delay: .7s;
    transition-delay: .7s;
}
.side-nav .navbar-nav .nav-item:nth-child(8) {
    -webkit-transition-delay: .8s;
    -o-transition-delay: .8s;
    transition-delay: .8s;
}
.side-nav .navbar-nav .nav-item:nth-child(9) {
    -webkit-transition-delay: .9s;
    -o-transition-delay: .9s;
    transition-delay: .9s;
}
.side-menu.side-menu-active .side-nav .navbar-nav .nav-item {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
.side-nav .navbar-nav .nav-link {
    display: inline-table;
    color: #fff;
    padding: 2px 0 3px 0 !important;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: normal;
    position: relative;
    -webkit-border-radius: 0;
    border-radius: 0;
}
.side-nav .navbar-nav .nav-link::after{
    content: "";
    position: absolute;
    background: #fff;
    display: inline-block;
    width: 0;
    height: 3px;
    bottom: 0; left: 0;
    overflow: hidden;
    -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
}
.transparent-sidemenu .side-nav .navbar-nav .nav-link::after {
    display: none;
}
.side-nav .navbar-nav .nav-link:hover::after,
.side-nav .navbar-nav .nav-link:focus::after,
.side-nav .navbar-nav .nav-link.active::after {
    width: 100%;
}
.side-nav .navbar-nav .nav-link.active {
    background: transparent;
}
.transparent-sidemenu .side-nav .navbar-nav .nav-link:hover,
.transparent-sidemenu .side-nav .navbar-nav .nav-link:focus {
    margin-left: 5px;
}
.side-menu p{
    font-size: 13px;
    margin-top: .5rem;
    margin-bottom: 0;
}

/*Header Social Icons*/
.navbar .social-icons li a {
    height: 28px; width: 28px;
    line-height: 28px;
    font-size: 14px;
}
.sidebar-nav .social-icons {
    position: absolute;
    bottom: 30px;
}
.side-menu .social-icons-simple li a:hover,
.side-menu .social-icons-simple li a:focus {
    color: #fff;
}

/*wrapper on opened sidebar*/
.wrapper {
    padding-left: 290px;
}
.wrapper .full-screen {
    width: 100% !important;
}


@media (max-width: 1366px) {
    .center-brand .sidemenu_btn {
        right: 0;
    }
}
@media (max-width: 1200px) {

    .side-menu .inner-wrapper {
        padding: 2rem 3.5rem;
    }

}
@media (min-width: 1025px) and (max-width: 1091px) {
    .navbar-nav.ml-auto {
        margin-right: 30px;
    }
    .sidemenu_btn {
        right: 0;
    }
}
@media (max-width: 1024px) {

    .boxed-nav .sidemenu_btn {
        display: none !important;
    }
    .center-brand .navbar-brand{
        width: 100px;
    }
    .navbar-nav .nav-link {
        font-size: 13px;
    }
}
@media (min-width: 810px) and (max-width: 1024px) {
    .sidemenu_btn {
        position: relative;
    }
}
@media (max-width: 992px) {
    .boxed-nav {
        background: #fff;
    }
    .center-brand .navbar-brand {
        position: relative;
        top: auto;
        margin: 16px 0;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
    .navbar-nav .nav-item {
        margin: 5px 0;
    }
    .center-brand .navbar-nav .nav-link,
    .transparent-bg .navbar-nav .nav-link {
        background: #00bcd4;
        color: #fff;
    }
    .center-brand.fixedmenu .navbar-nav .nav-link,
    .transparent-bg.fixedmenu .navbar-nav .nav-link {
        background: transparent;
    }

    .side-menu {
        width: 50%;
    }
    .side-menu .inner-wrapper {
        padding: 2rem 2.5rem;
    }

    .side-nav .navbar-nav .nav-link {
        font-size: 2rem;
    }

}
@media (max-width: 768px) {
    .center-brand .navbar-brand{
        width: 100px;
    }
    .side-menu {
        width: 55%;
    }
    .side-nav .navbar-nav .nav-link {
        font-size: 1.5rem;
    }
}

@media (max-width: 767px) {
    .navbar.sidebar-nav {
        width: 100%;
        height: auto;
        padding: 0 1rem;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
    }
    .wrapper {
        padding-left: 0;
    }
    .sidemenu_btn {
        margin-top: 4px;
        right: 0;
        position: absolute;
    }
    .side-menu {
        width: 300px;
    }
    .side-nav .navbar-nav .nav-item {
        margin: 5px 0;
    }
    .side-nav p {
        display: none;
    }
    .sidebar-nav .social-icons{
        display: none;
    }
}

/*-------------------------------*/
/*Navigation Ends */
/*-------------------------------*/


/*-------------------------------*/
/*OWl Slider*/
/*-------------------------------*/
/*Dots*/
.owl-dots {
    margin-top: 2rem;
    text-align: center;
}
.vertical-dot .owl-dots {
    display: inline-table;
    width: 24px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 40px;
}
.owl-dots .owl-dot {
    height: 12px;
    width: 12px;
    background: transparent;
    margin: 2px 3px;
    position: relative;
    border: 1px solid #00bcd4;
    display: inline-block;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: rgba(255, 255, 255, .35);
    cursor: pointer;
}
#text-fading .owl-dots .owl-dot {
    background: #fff;
    border: none;
}s
 .vertical-dot .owl-dots .owl-dot {
     border: none;
     background: rgba(255, 255, 255, .5);
 }

.owl-dots .owl-dot::after {
    background-color: #00bcd4;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: hidden;
    width: 100%;
}
.vertical-dot .owl-dots .owl-dot::after {
    display: none;
}
.vertical-dot .owl-dots .owl-dot.active {
    height: 15px;
    width: 15px;
    background: #fff;
}
.owl-dots .owl-dot.active::after,
.owl-dots .owl-dot:hover:after {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
}

.animate-out {
    -webkit-animation-delay : 0ms;
    -o-animation-delay : 0ms;
    animation-delay : 0ms;
}

/*Buttons*/
.owl-nav .owl-prev{
    left: 5px;
}
.owl-nav .owl-next{
    right: 5px;
}
.owl-nav .owl-prev, .owl-nav .owl-next{
    background: #00bcd4;
    font-size: 14px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    position: absolute;
    top: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #fff;
    opacity: 0;
    visibility: hidden;
}
.owl-carousel:hover .owl-nav .owl-prev,
.owl-carousel:hover .owl-nav .owl-next {
    opacity: 1;
    visibility: visible;
}
.owl-nav .owl-prev:hover, .owl-nav .owl-next:hover,
.owl-nav .owl-prev:focus, .owl-nav .owl-next:focus{
    background: #643094;
}









/*Service Slider*/
#services-slider {
    margin: -140px 0 0;
    min-height: 350px;
    position: relative;
    z-index: 21;
}
#services-slider.owl-carousel .owl-stage-outer{
    padding-top: 25px;
    padding-bottom: 25px;
}
#services-slider .service-box{
    position: relative;
    padding: 3rem 1rem;
    background: #00bcd4;
    color: #fff;
    text-align: center;
    -webkit-transition: all .5s ease .1s;
    -o-transition: all .5s ease .1s;
    transition: all .5s ease .1s;
    z-index: 2;
    overflow: hidden;
}
#services-slider .service-box::before {
    content: "";
    top: -100px;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    background: -webkit-linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
    background: -webkit-gradient(linear, left top, right top, color-stop(31%, #00e4ce), color-stop(69%, #00abc9));
    background: -webkit-linear-gradient(left, #00e4ce 31%, #00abc9 69%);
    background: -o-linear-gradient(left, #00e4ce 31%, #00abc9 69%);
    background: linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
}
#services-slider .owl-item.center .service-box::before {
    background: -webkit-linear-gradient(90deg, #423f9c 31%, #862359 69%);
    background: -webkit-gradient(linear, left top, right top, color-stop(31%, #423f9c), color-stop(69%, #862359));
    background: -webkit-linear-gradient(left, #423f9c 31%, #862359 69%);
    background: -o-linear-gradient(left, #423f9c 31%, #862359 69%);
    background: linear-gradient(90deg, #423f9c 31%, #862359 69%);
}
#services-slider .service-box > span {
    display: inline-block;
    font-size: 32px;
    height: 50px;
    width: 50px;
    overflow: hidden;
}
#services-slider .service-box > span > i {
    -webkit-transition: all .9s ease .2s;
    -o-transition: all .9s ease .2s;
    transition: all .9s ease .2s;
}
#services-slider .service-box h4{
    font-weight: bold;
}
#services-slider .service-box:hover span {
    font-size: 38px;
}
#services-slider .owl-item.center .service-box {
    background: #643094;
    padding: 4.5rem 1rem;
    margin-top: -25px;
}
#services-slider .service-box:hover::before {
    opacity: 1;
    visibility: visible;
    top: 0;
}



@media screen and (max-width: 768px) {
    #services-slider {
        margin: -70px 0 0;
    }
}

/*-------------------------------*/
/*OWL Slider ends*/
/*-------------------------------*/


/*-------------------------------*/
/*Revolution SLider*/
/*-------------------------------*/
#main-banner .fontbold {
    font-weight: 800;
}
.tp-bullet {
    height: 15px;
    width: 15px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: rgba(255, 255, 255, .3);
}
.tp-bullet:hover, .tp-bullet:focus{
    background: rgba(255, 255, 255, .6);
}
.tp-bullet::before {
    content: "";
    height: 100%; width: 100%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: scale(.5);
    -ms-transform: scale(.5);
    -o-transform: scale(.5);
    transform: scale(.5);
    position: absolute;
    left: 0; top: 0;
    background: #fff;
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}
.tp-bullet.selected::before {
    opacity: 1;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}

.uranus .tp-bullet-inner{
    background: #00bcd4;
}
.uranus .tp-bullet::before {
    display: none;
}
.uranus .tp-bullet.selected, .uranus .tp-bullet:hover {
    -webkit-box-shadow: 0 0 0 2px #643094;
    box-shadow: 0 0 0 2px #643094;
}
.uranus .tp-bullet.selected .tp-bullet-inner,
.uranus .tp-bullet:hover .tp-bullet-inner {
    background: #643094;
}

/*
FIXME WEBPACK LOADER PROBLEM
.tp-carousel-wrapper {
    cursor: url('../images/openhand.cur'), move;
}
*/
.rev_slider li.rev_gradient::after {
    background: -webkit-linear-gradient(90deg, #48459f, #8a2a5d);
    background: -webkit-gradient(linear, left top, right top, color-stop(#48459f), color-stop(#8a2a5d));
    background: -webkit-linear-gradient(left, #48459f, #8a2a5d);
    background: -o-linear-gradient(left, #48459f, #8a2a5d);
    background: -webkit-gradient(linear, left top, right top, from(#48459f), to(#8a2a5d));
    background: linear-gradient(90deg, #48459f, #8a2a5d);
}
.rev_slider li.rev_gradient::after,
.banner-overlay::after  {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.banner-overlay::after {
    background: rgba(0,0,0,.39);
}
.zeus.tparrows.tp-rightarrow::before{
    content: "\f105";
}
.zeus.tparrows.tp-leftarrow::before {
    content: "\f104";
}
/*
@media (max-width: 767px) {
   .rev_slider .button {
	display: inline-block !important;
      left: 50% !important;
      -webkit-transform: translateX(-50%) !important;
      -ms-transform: translateX(-50%) !important;
      -o-transform: translateX(-50%) !important;
      transform: translateX(-50%) !important;
   }
}*/


/*-------------------------------*/
/* Main Banner Ends*/
/*-------------------------------*/


/*-------------------------------*/
/* Horizontal Half Blocks*/
/*-------------------------------*/
.half-section{
    width: 100%;
}
.half-section,
.half-section .img-container{
    position: relative;
    overflow: hidden;
}
.half-section .img-container{}
.half-section .imgone{
    background: url("../images/split-img1.jpg") no-repeat;
}
.half-section .imgtwo{
    background: url("../images/split-img2.jpg") no-repeat;
}
@media (min-width: 768px) and (max-width: 1024px){
    .split-box .padding {
        padding: 3rem 0;
    }
}
@media (max-width: 992px){
    .split-box{
        padding-left: 15px;
        padding-right: 15px;
    }
    .half-section .img-container {
        margin-bottom: 25px;
    }

}
@media screen and (max-width: 767px) {
    #twocopies .half-section:first-of-type .container-fluid {
        border-bottom: 1px solid #ececec;
    }
}


/*-------------------------------*/
/*Horizontal Half Blocks ends*/
/*-------------------------------*/


/*-------------------------------*/
/*Page Headers */
/*-------------------------------*/
.page-header {
    background: url(../images/page-header.jpg) no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
}
.page-header .page-titles {
    position: relative;
    margin-top: 5rem;
    text-transform: capitalize;
}
.breadcrumb {
    background: transparent;
    padding: 0;
}
.breadcrumb .breadcrumb-item {
    color: #fff;
    font-size: 14px;
}
.breadcrumb .breadcrumb-item:hover,
.breadcrumb .breadcrumb-item:focus,
.breadcrumb .breadcrumb-item.active {
    color: #00bcd4;
}
#particles canvas {
    position: absolute;
}

/*-------------------------------*/
/*Page Headers */
/*-------------------------------*/


/*-------------------------------*/
/* Work Process */
/*-------------------------------*/
.process-wrapp {
    overflow: hidden;
    display: table;
    table-layout:fixed;
    margin: 0 15px;
}
.process-wrapp, .process-wrapp li,
.process-wrapp li > .pro-step {
    position: relative;
    z-index: 1;
}
.process-wrapp li {
    text-align: center;
    padding: 70px  15px 0 15px;
    display: table-cell;
}
.process-wrapp li:hover {
    cursor: pointer;
}
.process-wrapp li > .pro-step {
    display: inline-block;
    height: 90px;
    width: 90px;
    line-height: 90px;
    border: 1px solid #fff;
    font-size: 28px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.process-wrapp li p:first-child {
    font-size: 15px;
}
.process-wrapp li p:last-child {
    font-size: 13px;
}
.process-wrapp li:hover > .pro-step {
    -webkit-box-shadow: 0 0 25px 50px #fff inset;
    box-shadow: 0 0 25px 50px #fff inset;
    color: #00bcd4;
}
.process-wrapp li::before, .process-wrapp li::after {
    content: "";
    height: 1px;
    top: 115px;
    background: rgba(255,255,255, .5);
    position: absolute;
    width: 50%;
    z-index: -1;
    margin: 0 -45px;
}
.process-wrapp li:first-child::before,
.process-wrapp li:last-child::after {
    opacity: 0;
    visibility: hidden;
}
.process-wrapp li::before {
    left: 0;
}
.process-wrapp li::after {
    right: 0;
}

/*process with box */
.process-number {
    background: #fff;
    padding: 0 1rem 2.5rem 1rem;
    position: relative;
    text-align: center;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}
.process-number,
.process-number .pro-step {
    -webkit-transition: all .5s linear;
    -o-transition: all .5s linear;
    transition: all .5s linear;
}
.process-number:hover {
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .15);
    box-shadow: 0 0 20px rgba(0, 0, 0, .15);
    cursor: pointer;
}
.process-number .pro-step {
    font-weight: bold;
    font-size: 1.75rem;
    font-family: 'Open Sans', sans-serif;
    margin-right: 5px;
    color: #fff;
    line-height: 1;
    background: #00bcd4;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    margin-top: -50px;
}
.process-number .pro-step::after {
    content: "";
    height: 5px;
    width: 5px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    background: #fff;
    margin-left: 3px;
}
.process-number.even .pro-step{
    background: #643094;
}

/*Process icoin inside*/
.process-number.iconic {
    padding: 2.5rem 1rem;
}
.process-number.iconic > span {
    height: 90px;
    width: 90px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    line-height: 90px;
    display: inline-block;
    text-align: center;
    font-size: 48px;
    background: #00bcd4;
    color: #fff;
}
.process-number.iconic:hover > span {
    -webkit-box-shadow: 0 0 20px 45px #643094 inset;
    box-shadow: 0 0 20px 45px #643094 inset;
}


@media screen and (max-width: 992px) {
    .process-wrapp {
        text-align: center;
    }
    .process-wrapp li {
        display: inline-block;
        max-width: 300px;
    }
    .process-wrapp li:first-child::before,
    .process-wrapp li:last-child::after {
        opacity: 1;
        visibility: visible;
    }
}


/*-------------------------------*/
/*Work Process ends */
/*-------------------------------*/


/*-------------------------------*/
/* Our Team */
/*-------------------------------*/
.team-box,
.team-box .team-content {
    -webkit-transition: all .5s ease-in .1s;
    -o-transition: all .5s ease-in .1s;
    transition: all .5s ease-in .1s;
}
.team-box {
    position: relative;
    padding: 20px 20px 100px 20px;
    overflow: hidden;
}
.team-box.grey-shade {
    padding: 0;
    background: #f6f6f6;
}
.team-box.no-hover {
    padding: 0;
    overflow: inherit;
}
.team-box::before {
    content: "";
    border: 20px solid #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 50px;
}
.team-box.grey-shade::before,
.team-box.no-hover::before {
    display: none;
}
.team-box.grey-shade img{
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.team-box.grey-shade:hover img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
}
.team-box .team-content {
    padding: 30px 1.25rem;
    position: absolute;
    overflow: hidden;
    bottom: 0;
    left: 20px;
    right: 20px;
    -webkit-transform: translateY(53%);
    -ms-transform: translateY(53%);
    -o-transform: translateY(53%);
    transform: translateY(53%);
    text-align: center;
}
.team-box.single .team-content {
    -webkit-transform: translateY(38%);
    -ms-transform: translateY(38%);
    -o-transform: translateY(38%);
    transform: translateY(38%);
}
.team-box.no-hover .team-content {
    -webkit-box-shadow: 0px 10px 5px -10px #b7b7b7;
    box-shadow: 0px 10px 5px -10px #b7b7b7;
    background: #fff;
}
.team-box.no-hover .team-content,
.team-box.grey-shade .team-content {
    -webkit-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    position: relative;
    left: 0; right: 0;
}

.team-box .team-content h3 {
    font-weight: normal;
    font-size: 1.75rem;
}
.team-box.no-hover .team-content h3 {
    color: #212331;
}
.team-box .team-content p {
    font-size: 12px;
}
.team-box.no-hover .team-content p {
    font-size: 12px;
    margin-bottom: 10px;
}
.team-box:hover .team-content,
.team-box.single:hover .team-content {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}
.team-box.no-hover .team-content::before {
    content: "";
    height: 100%;
    width: 100%;
    background: -webkit-linear-gradient(90deg, #423f9c, #862359);
    background: -webkit-gradient(linear, left top, right top, color-stop(#423f9c), color-stop(#862359));
    background: -webkit-linear-gradient(left, #423f9c, #862359);
    background: -o-linear-gradient(left, #423f9c, #862359);
    background: -webkit-gradient(linear, left top, right top, from(#423f9c), to(#862359));
    background: linear-gradient(90deg, #423f9c, #862359);
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
}
.team-box.no-hover:hover .team-content::before {
    opacity: 1;
    visibility: visible;
}
.team-box.no-hover:hover .team-content::before,
.team-box.no-hover:hover .team-content,
.team-box.no-hover:hover .team-content h3 ,
.team-box.no-hover:hover .team-content ul.social-icons li a {
    color: #fff;
}

.team-box .overlay {
    top: 0; left: 0;
    right: 0; bottom: 0;
}
.team-box:hover .overlay {
    opacity: 1;
    visibility: visible;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.team-box ul.social-icons li a {
    height: 30px;
    width: 30px;
    line-height: 30px;
    font-size: 14px;
}
.team-box ul.social-icons.white{}

/*Progress Bars*/
.progress-bars .progress {
    position: relative;
    overflow: visible;
    height: 12px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    opacity: 0;
}
.progress-bars .progress + .progress {
    margin-top: 40px;
}
.progress-bars .progress p {
    margin-bottom: 10px;
    position: relative;
    left: 0;
    display: inline-block;
}
.progress-bars .progress p,
.progress-bars .progress .progress-bar span {
    top: -24px;
}
.progress-bars .progress .progress-bar {
    float: none;
    position: absolute;
    height: 100%;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: width 1s ease-in-out .3s;
    -o-transition: width 1s ease-in-out .3s;
    transition: width 1s ease-in-out .3s;
    background: -webkit-linear-gradient(90deg, #f6663f 31%, #ed145b 69%);
    background: -webkit-gradient(linear, left top, right top, color-stop(31%, #f6663f), color-stop(69%, #ed145b));
    background: -webkit-linear-gradient(left, #f6663f 31%, #ed145b 69%);
    background: -o-linear-gradient(left, #f6663f 31%, #ed145b 69%);
    background: linear-gradient(90deg, #f6663f 31%, #ed145b 69%);
}
.progress-bars .progress .progress-bar span {
    display: block;
    position: absolute;
    right: 0;
    width: 40px;
    padding: 0;
}

@media (max-width: 992px) {
    .team-box {
        padding: 20px 20px 89px 20px;
    }
    .team-box .team-content {
        -webkit-transform: translateY(56%);
        -ms-transform: translateY(56%);
        -o-transform: translateY(56%);
        transform: translateY(56%);
    }
    .team-box.single .team-content {
        -webkit-transform: translateY(40%);
        -ms-transform: translateY(40%);
        -o-transform: translateY(40%);
        transform: translateY(40%);
    }
}

@media (max-width: 768px) {
    .team-box {
        padding: 20px 20px 85px 20px;
    }
    .team-box .team-content {
        -webkit-transform: translateY(57%);
        -ms-transform: translateY(57%);
        -o-transform: translateY(57%);
        transform: translateY(57%);
    }

}

/*-------------------------------*/
/*Our Team ends */
/*-------------------------------*/


/*-------------------------------*/
/* Gallery Portfolio */
/*-------------------------------*/
/*filters*/
.cbp-l-filters .cbp-filter-item {
    cursor: pointer;
    margin: 0 .2rem;
    display: inline-block;
}
.cbp-l-filters .cbp-filter-item span {
    color: #b7b7b7;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    position: relative;
    padding: 5px 1.125rem;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    border: 1px solid #b7b7b7;
}
.cbp-l-filters .cbp-filter-item-active {
    cursor: default;
}
.cbp-l-filters .cbp-filter-item:hover span {
    border: 1px solid #643094;
    color: #fff;
    background: #643094;
}
.cbp-l-filters .cbp-filter-item-active span,
.cbp-l-filters .cbp-filter-item:focus span {
    border: 1px solid #00bcd4;
    color: #fff;
    background: #00bcd4;
}
#portfolio_top {
    position: relative;
    overflow: hidden;
}
#portfolio_top::before,
#portfolio_top::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    background: #fff;
    height: 90px;
    width: 100%;
}



















#portfolio_top::before {
    top: 0;
}
#portfolio_top::after {
    bottom: 0;
}
.text_wrap {
    padding: 50px 2rem 0.5rem 2rem;
}
.itemshadow .cbp-item-wrapper {
    -webkit-box-shadow: 0 0 4px 1px rgba(101, 101, 101, 0.2);
    box-shadow: 0 0 4px 1px rgba(101, 101, 101, 0.2);
    padding-bottom: 2px;
    padding-top: 5px;
}
.border-portfolio .cbp-item img {
    border: 20px solid #fff;
}
.bottom-text {
    display: table;
}
.bottom-text > .cells {
    display: table-cell;
    vertical-align: middle;
    padding: 15px;
}
.bottom-text p {
    font-size: 15px;
    margin-bottom: 0;
}
.bottom-text .port_head {
    font-weight: bold;
    font-size: 5.625rem;
    line-height: 1;
    font-family: 'Open Sans', sans-serif;
}

/*Overlays*/
.overlay {
    position: absolute;
    left: 20px; top: 20px;
    right: 20px; bottom: 20px;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: rgba(0, 207, 204, .9);
    -webkit-transition: all .5s ease-in .1s;
    -o-transition: all .5s ease-in .1s;
    transition: all .5s ease-in .1s;
}
.simple_overlay .overlay{
    -webkit-border-radius: 0;
    border-radius: 0;
}
.dark_overlay .overlay {
    background: rgba(33,35,49, .9);
}
.cbp-item:hover .overlay,
.cbp-item:focus .overlay {
    opacity: 1; visibility: visible;
    -webkit-border-radius: 0;
    border-radius: 0;
}
.cbp-item:hover .overlay{
    opacity: 1; visibility: visible;
}
.overlay > .plus{
    display: inline-block;
    height: 70px;
    width: 70px;
    position: relative;
    overflow: hidden;
}
.overlay > .plus::before,
.overlay > .plus::after {
    content: " ";
    position: absolute;
    background: #fff;
}
.overlay > .plus::after {
    height: 2px;
    margin-top: -1px;
    top: 50%;
    left: 5px;
    right: 5px;
}
.overlay > .plus::before {
    width: 2px;
    margin-left: -1px;
    left: 50%;
    top: 5px;
    bottom: 5px;
}
.overlay p {
    font-size: 13px;
}
.gradient_text {
    background: -webkit-gradient(linear,left top, right top,from(#00e3ce) , to(#00abc9));
    background: -webkit-linear-gradient(left,#00e3ce , #00abc9);
    background: -o-linear-gradient(left,#00e3ce , #00abc9);
    background: linear-gradient(90deg,#00e3ce , #00abc9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/*Single Porfolio*/
.item-one {
    background: url(../images/bg-single-1.jpg) no-repeat;
}
.item-two {
    background: url(../images/bg-single-2.jpg) no-repeat;
}
.item-three {
    background: url(../images/bg-single-3.jpg) no-repeat;
}
.item-four {
    background: url(../images/bg-single-4.jpg) no-repeat;
}
.item-five {
    background: url(../images/bg-single-5.jpg) no-repeat;
}
.item-six {
    background: url(../images/bg-single-6.jpg) no-repeat;
}
.item-seven {
    background: url(../images/bg-single-7.jpg) no-repeat;
}
.item-eight {
    background: url(../images/bg-single-8.jpg) no-repeat;
}
.item-nine {
    background: url(../images/bg-single-9.jpg) no-repeat;
}

.single-items {
    background-attachment: fixed;
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
    position: relative;
    width: 100%;
}

@media (max-width: 768px) {
    #portfolio_top::after {
        background: inherit;
    }
    .bottom-text > .cells {
        display: table;
    }
}

@media (max-width: 767px) {
    #portfolio_top::before {
        display: none;
    }
    #portfolio_top .text_wrap {
        padding-top: 0;
    }
    #portfolio_top .bottom-text {
        text-align: center;
        display: block;
    }
    .bottom-text > .cells {
        display: inline-block;
        width: 100%;
        padding: 30px 15px;
    }

}



/*-------------------------------*/
/*Gallery ends */
/*-------------------------------*/


/*-------------------------------*/
/* Mobile Apps & Features */
/*-------------------------------*/

/*single feature*/
.single-feature, .bg-apps {
    position: relative;
}
/*apps background*/
.bg-apps {
    background: url(../images/bg-apps.jpg) no-repeat;
    background-attachment: fixed;
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
}
.bg-apps .image {}

/*app features*/
.feature-item .icon {
    -webkit-transition: all .5s ease-in;
    -o-transition: all .5s ease-in;
    transition: all .5s ease-in;
}
.opacities {
    opacity: .5;
}
#app-feature .feature-item {
    padding: 15px 0;
}
#app-feature .feature-item:hover {
    cursor: pointer;
}
.feature-item img,
.feature-item .icon{
    display: inline-block;
}
.feature-item img {
    max-width: 290px;
}
.feature-item h4 {
    font-weight: 600;
    color: #222222;
    font-size: 1.125rem;
    margin-bottom: 10px;
}
.feature-item .icon {
    background: #00bcd4;
    width: 90px;
    height: 90px;
    text-align: center;
    position: relative;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    z-index: 1;
    font-size: 34px;
    line-height: 90px;
    color: #fff;
}
.feature-item .icon::before {
    content: "";
    background: transparent;
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    position: absolute;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: 4px solid #fff;
}
.feature-item:hover .icon {
    -webkit-animation: itg_pulse 1s infinite;
    -o-animation: itg_pulse 1s infinite;
    animation: itg_pulse 1s infinite;
    -webkit-box-shadow:0 0 0 0 rgba(100, 48, 148, 1);
    box-shadow:0 0 0 0 rgba(100, 48, 148, 1);
}

@-webkit-keyframes itg_pulse {
    0% {
        -webkit-box-shadow: 0 0 0 5px rgba(100, 48, 148, 0.9);
        box-shadow: 0 0 0 5px rgba(100, 48, 148, 0.9);
    }
    25% {
        -webkit-box-shadow: 0 0 0 10px rgba(100, 48, 148, 0.6);
        box-shadow: 0 0 0 10px rgba(100, 48, 148, 0.6);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(100, 48, 148, 0.3);
        box-shadow: 0 0 0 15px rgba(100, 48, 148, 0.3);
    }
    100% {
        -webkit-box-shadow: 0 0 0 60px rgba(100, 48, 148, 0.1);
        box-shadow: 0 0 0 20px rgba(100, 48, 148, 0.1);
    }
}
@-o-keyframes itg_pulse {
    0% {
        box-shadow: 0 0 0 5px rgba(100, 48, 148, 0.9);
    }
    25% {
        box-shadow: 0 0 0 10px rgba(100, 48, 148, 0.6);
    }
    70% {
        box-shadow: 0 0 0 15px rgba(100, 48, 148, 0.3);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(100, 48, 148, 0.1);
    }
}
@keyframes itg_pulse {
    0% {
        -webkit-box-shadow: 0 0 0 5px rgba(100, 48, 148, 0.9);
        box-shadow: 0 0 0 5px rgba(100, 48, 148, 0.9);
    }
    25% {
        -webkit-box-shadow: 0 0 0 10px rgba(100, 48, 148, 0.6);
        box-shadow: 0 0 0 10px rgba(100, 48, 148, 0.6);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(100, 48, 148, 0.3);
        box-shadow: 0 0 0 15px rgba(100, 48, 148, 0.3);
    }
    100% {
        -webkit-box-shadow: 0 0 0 60px rgba(100, 48, 148, 0.1);
        box-shadow: 0 0 0 20px rgba(100, 48, 148, 0.1);
    }
}

#app-feature .feature-item:hover .icon {
    background: #643094;
}
#app-feature .content-left {
    text-align: right;
}
#app-feature .content-right {
    text-align: left;
}
#app-feature .content-left span {
    float: right;
    margin-left: 15px;
}
#app-feature .content-right span {
    float: left;
    margin-right: 15px;
}
#app-feature .content-left .text{
    margin-right: 105px;
}
#app-feature .content-right .text{
    margin-left: 105px;
}

.feature-item.active {
    background: #f7f7f7;
}
.image.mobile-apps > img {
    opacity: 0;
}
.image.mobile-apps.active > img {
    opacity: 1;
}


@media screen and (max-width: 992px) {
    #app-feature,
    #app-feature .content-left,
    #app-feature .content-right {
        text-align: center;
    }
    #app-feature .feature-item {
        padding: 0;
        max-width: 300px;
        display: inline-block;
        float: none;
    }
    #app-feature .content-left span,
    #app-feature .content-right span {
        float: none;
        margin: 0 auto 15px auto;
        display: inline-block;
    }

    #app-feature .content-left .text,
    #app-feature .content-right .text {
        margin: 0;
    }
}

/*-------------------------------*/
/*Mobile Apps ends */
/*-------------------------------*/


/*-------------------------------*/
/* Counters */
/*-------------------------------*/
.fact-iconic, .counter-photo {
    position: relative;
}
.counter-photo ::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 17px;
    background: #00bcd4;
    top: 10px;
    left: 10px;
    bottom: 0;
    display: inline-block;
    z-index: 1;
}
.counter-photo img {
    z-index: 2;
    padding: 0 15px 15px 0;
}
.fact-iconic h3, .counters .count_nums {
    font-weight: bold;
}
.fact-iconic h3::after {
    content: "";
    height: 3px;
    width: 50px;
    background: #ffffff;
    display: block;
    margin-top: 15px;
}
.text-center .fact-iconic h3::after,
.text-center.fact-iconic h3::after {
    margin-left: auto;
    margin-right: auto;
}
.icon-counters.table-display {
    display: table;
}
.icon-counters .img-icon {
    text-align: center;
    font-size: 40px;
    height: 70px;
    width: 70px;
    line-height: 70px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .7s ease-in .3s;
    -o-transition: all .7s ease-in .3s;
    transition: all .7s ease-in .3s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.bg-counter-light .icon-counters .img-icon {
    height: 100px;
    width: 100px;
    line-height: 100px;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}
.icon-counters.table-display .img-icon {
    display: table-cell;
    vertical-align: middle;
    font-size: 50px;
    width: 90px;
}
.icon-counters.table-display .img-icon,
.icon-counters.table-display:hover .img-icon,
.bg-counter-light .icon-counters .img-icon,
.bg-counter-light .icon-counters:hover .img-icon {
    -webkit-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
}
.icon-counters:hover .img-icon {
    -webkit-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}
.bg-counter-light .icon-counters:hover .img-icon {
    color: #fff;
    background: #00bcd4;
}
.icon-counters .img-icon,
.counters .count_nums, .counters i {
    display: inline-block;
}
.counters .count_nums {
    font-size: 1.875rem;
}
.icon-counters.table-display .count_nums {
    font-size: 2.5rem;
}
.counters .count_nums, .counters i {
    vertical-align: middle;
}
.bg-counter-light {
    background: url(../images/bg-counter-light.jpg) no-repeat;
    background-attachment: fixed;
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
}

@media screen and (max-width: 767px) {
    .fact-iconic h3::after {
        margin-left: auto;
        margin-right: auto;
    }
}
/*-------------------------------*/
/*Counters ends */
/*-------------------------------*/


/*-------------------------------*/
/* Pricings */
/*-------------------------------*/
.price-table, .price-table *{
    -webkit-transition: all .4s ease-in;
    -o-transition: all .4s ease-in;
    transition: all .4s ease-in;
}
.pricing-bg {
    background: url(../images/page-header-2.jpg)no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    width: 100%;
}
.pricing-bg::before {
    content: "";
    height: 50%;
    width: 100%;
    bottom: 0;
    position: absolute;
    background: #f6f6f6;
}
.price-table {
    padding: 4.375rem 5.5rem;
    border: 1px solid #f6f3f3;
    background: #fff;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
.square-layout .price-table{
    -webkit-border-radius: 0;
    border-radius: 0;
}
.three-col-pricing .price-table,
.centered-table .price-table {
    padding: 3rem 1.5rem;
}
.three-col-pricing .price-table,
.centered-table .price-table,
.three-col-pricing .price-table ul,
.centered-table .price-table ul{
    text-align: center;
}
.price-table h3 {
    font-size: 1.875rem;
}
.price-table h3, .price-table .ammount h2 {
    font-weight: normal;
}
.centered-table .price-table h3,
.centered-table .price-table .ammount h2 {
    font-weight: bold;
}
.price-table .ammount *,
.price-table ul, .price-table ul li > span{
    display: inline-block;
}
.price-table .ammount *{
    vertical-align: middle;
}
.price-table .ammount .dur {
    font-size: 1rem;
    color: #545661;
    margin-top: 10px;
}
.price-table .ammount i {
    margin-top: -5px;
    font-size: 80%;
    margin-right: -5px;
}
.price-table .ammount h2 {
    line-height: 1;
    font-size: 3.125rem;
}
.price-table.active .ammount h2 {
    color: #643094;
}
.price-table ul {
    text-align: left;
}

.price-table ul li {
    margin-top: 1.25rem;
}
.price-table ul li{
    color: #6e6e6e;
    font-size: 14px;
}
.price-table ul li.not-support{
    color: #9b9b9b;
}
.price-table ul li.not-support > span {
    text-decoration: line-through;
}
.price-table ul li > span {
    padding-left: 20px;
}
.price-table ul li > span::before {
    content: "\f00c";
    color: #30e512;
    margin-right: 8px;
    width: 20px;
    margin-left: -20px;
}
.price-table ul li.not-support > span::before{
    content: "\f00d";
    color: #ff434b;
}
.centered-table .price-table ul li > span::before {
    display: none;
}
.centered-table .price-table ul li > span::after {
    content: "";
    width: 100%;
    height: 1px;
    background: #f6f6f6;
    margin: 0 auto;
    display: inline-block;
}
.centered-table .price-table ul li:last-child > span::after {
    width: 0;
}

.price-table:hover {
    background: #00bcd4;
    border-color: #00bcd4;
}
.price-table.active:hover {
    background: #643094;
    border-color: #643094;
}
.price-table:hover *,
.price-table:hover .ammount *,
.price-table:hover .ammount h2,
.price-table:hover .ammount .dur,
.price-table:hover ul li > span::before,
.price-table:hover .btnsecondary,
.price-table:focus .btnsecondary,
.price-table:hover .btnprimary,
.price-table:focus .btnprimary{
    color: #fff;
}
.price-table:hover .btnsecondary,
.price-table:focus .btnsecondary,
.price-table:hover .btnprimary,
.price-table:focus .btnprimary {
    border-color: #fff;
    background: transparent;
}
.price-table .btnsecondary:hover,
.price-table .btnsecondary:focus,
.price-table .btnprimary:hover,
.price-table .btnprimary:focus {
    border-color: #fff;
    background: #fff;
    color: #212331;
}
.price-table:hover {
    -webkit-box-shadow: 0 0 5px rgba(101, 101, 101, 0.2);
    box-shadow: 0 0 5px rgba(101, 101, 101, 0.2);
}

@media (max-width: 992px) {
    .price-table{
        padding: 3.375rem 4rem;
    }
}
@media (max-width: 768px) {
    .price-table{
        padding: 2.375rem 2rem;
    }
}
@media (max-width: 768px) {
    .price-table{
        padding: 2.375rem 2rem;
    }
}

/*-------------------------------*/
/* Pricings ends */
/*-------------------------------*/


/*-------------------------------*/
/*Backgrounds Parallax */
/*-------------------------------*/

/*banner parallax*/
#video-parallax{
    background: url(../images/bg-video.jpg) no-repeat;
}
.rotating-slider {
    background: url(../images/page-header-2.jpg)no-repeat;
}
.rotating-words {
    background: url(../images/page-header-2.jpg)no-repeat;
}
.rotating-slider {
    background: url(../images/nexpi_img/blocchi.jpg)no-repeat;
}
.rotating-slider.studio-ver{
    background: url(../images/studio-fade.jpg)no-repeat;
}
.ineractive-ver {
    background: url(../images/banner-interactive-agency.jpg)no-repeat;
}
.rotating-slider, .rotating-words, #video-parallax, .rotating-slider, .ineractive-ver{
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    width: 100%;
}

#bgndVideo {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
iframe  {
    height: 100%;
    width: 100%;
}
.jquery-background-video-pauseplay {
    opacity: 0;
}

.fontregular{
    font-weight: normal;
}
.video-parallax h2{
    font-size: 3.125rem;
}
.video-parallax .heading-title > span {
    font-weight: 300;
    font-size: 1.25rem;
    text-transform: inherit;
}
.video-parallax .button {
    padding-bottom: .9rem;
}
.button-play {
    height: 70px;
    width: 70px;
    line-height: 70px;
    color: #fff;
    display: inline-block;
    text-align: center;
    font-size: 30px;
    top: 50%; left: 50%;
    z-index: 2;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    background: -webkit-linear-gradient(90deg, #423f9c, #862359);
    background: -webkit-gradient(linear, left top, right top, color-stop(#423f9c), color-stop(#862359));
    background: -webkit-linear-gradient(left, #423f9c, #862359);
    background: -o-linear-gradient(left, #423f9c, #862359);
    background: -webkit-gradient(linear, left top, right top, from(#423f9c), to(#862359));
    background: linear-gradient(90deg, #423f9c, #862359);
}
.button-play, .button-play::before {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
}
.button-play::before {
    content: "";
    top: 0; left: 0;
    right: 0; bottom: 0;
    z-index: -1;
    opacity: 0; visibility: hidden;
    background: -webkit-linear-gradient(90deg, #643094, #00bcd4);
    background: -webkit-gradient(linear, left top, right top, color-stop(#643094), color-stop(#00bcd4));
    background: -webkit-linear-gradient(left, #643094, #00bcd4);
    background: -o-linear-gradient(left, #643094, #00bcd4);
    background: -webkit-gradient(linear, left top, right top, from(#643094), to(#00bcd4));
    background: linear-gradient(90deg, #643094, #00bcd4);
}
.button-play:hover::before,
.button-play:focus::before {
    opacity: 1;
    visibility: visible;
}
.button-play:hover, .button-play:focus {
    color: #fff;
}

/*Background Video*/
.bg-video-container::before{
    content: "";
    top: 0; left: 0;
    bottom: 0; right: 0;
    position: absolute;
    background: rgba(33,35,49,.7);
    z-index: 1;
}
.bg-video-container .video-content {
    position: relative;
    z-index: 5;
}

/*Background Particle*/
#particles-js {
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: .6;
}

/*-------------------------------*/
/*Backgrounds Parallax ends */
/*-------------------------------*/


/*-------------------------------*/
/* Testimonials*/
/*-------------------------------*/
.testimonial-wrapp,
.testimonial-wrapp .testimonial-text,
.testimonial-wrapp .testimonial-photo,
.testimonial-wrapp .quoted, .testimonial-quote{
    position: relative;
    -webkit-transition: all .4s ease-in;
    -o-transition: all .4s ease-in;
    transition: all .4s ease-in;
}
.testimonial-wrapp,
.testimonial-wrapp .testimonial-text {
    text-align: center;
}
.testimonial-wrapp .quoted,
.testimonial-wrapp .testimonial-photo {
    display: inline-block;
}
.testimonial-wrapp .quoted {
    background: #643094;
    color: #fff;
    font-size: 20px;
    line-height: 50px;
    height: 50px;
    width: 50px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%);
    z-index: 1;
}
.testimonial-wrapp .testimonial-text {
    background: #f6f6f6;
    padding: 4.25rem 2.5rem;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    border: 1px solid #f1f1f1;
}
.testimonial-wrapp .testimonial-photo {
    height: 100px;
    width: 100px;
    margin-top: -50px;
}
.testimonial-wrapp .testimonial-photo,
.testimonial-wrapp .testimonial-photo > img{
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
#testimonial-slider .owl-item:nth-child(2n) .testimonial-wrapp .quoted,
#testimonial-slider .owl-item:nth-child(2n) .testimonial-wrapp:hover .testimonial-text{
    background: #00bcd4;
}
.testimonial-wrapp:hover .testimonial-text{
    background: #643094;
    color: #fff;
}

/*Testimonial Quotes*/
.testimonial-bg{
    background: url(../images/bg-testimonial.jpg) no-repeat;
}
.testimonial-bg-light {
    background: url(../images/bg-testimonial-light.jpg) no-repeat;
}
.testimonial-bg, .testimonial-bg-light{
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    width: 100%;
}


.testimonial-quote {
    text-align: left;
    padding-top: 10px;
}
.no-quote .testimonial-quote {
    text-align: center;
}
.testimonial-quote h3{
    position: relative;
}
.no-quote .testimonial-quote h3::before,
.no-quote .testimonial-quote h3::after {
    display: none;
}
.testimonial-quote h3::before, .testimonial-quote h3::after {
    display: inline-block;
    font-size: 17px;
}
.testimonial-quote h3::before {
    content: "\f10d";
    margin-right: 3px;
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
}
.testimonial-quote h3::after{
    content: "\f10e";
    margin-left: 3px;
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
}
.testimonial-quote h6{
    font-weight: 300;
}

#owl-thumbs.owl-dots{
    text-align: left;
}
#owl-thumbs.owl-dots .owl-dot {
    background: transparent;
    height: 60px;
    margin: 0;
    position: relative;
    width: 60px;
    border: 5px solid rgba(255,255,255,.3);
    margin-left: -30px;
    opacity: .65;
    -webkit-transform: scale(.95);
    -ms-transform: scale(.95);
    -o-transform: scale(.95);
    transform: scale(.95);
}
.testimonial-bg-light #owl-thumbs.owl-dots .owl-dot {
    border: 5px solid rgba(0,0,0,.3);
}
#owl-thumbs.owl-dots .owl-dot > img {
    width: 100%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
#owl-thumbs.owl-dots .owl-dot:first-child{
    margin-left: 0;
}
#owl-thumbs.owl-dots .owl-dot::after{
    display: none;
}
#owl-thumbs.owl-dots .owl-dot:hover,
#owl-thumbs.owl-dots .owl-dot.active{
    z-index: 5;
    opacity: 1;
    border: 5px solid rgba(255,255,255,.53);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}
.testimonial-bg-light #owl-thumbs.owl-dots .owl-dot.active {
    border: 5px solid rgba(0,0,0,.53);
}

/*-------------------------------*/
/* Testimonials ends*/
/*-------------------------------*/


/*-------------------------------*/
/* Partner/ Logo's*/
/*-------------------------------*/
#partners-slider .item {
    text-align: center;
}
#partners-slider .item,
#partners-slider .logo-item {
    position: relative;
}
#partners-slider .logo-item {
    width: 160px;
    height: 60px;
    overflow: hidden;
}
#partners-slider .logo-item, #partners-slider  .logo-item > img{
    display: inline-block;
}
.logo-item > img {
    width: 98%;
    position: absolute;
    top: 50%; left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    cursor: pointer;
}

/*-------------------------------*/
/* Partner/ Logo's ends*/
/*-------------------------------*/


/*-------------------------------*/
/* Our Blog*/
/*-------------------------------*/
.news_item{
    background:#fff;
}
.news_item, .eny_profile,
blockquote.blockquote {
    position: relative;
}
.news_item.shadow{
    -webkit-box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.news_item .news_desc{
    padding:40px 30px;
}

.meta-tags li, .meta-tags li a,
blockquote.blockquote::before,
ul.rounded li::before {
    display:inline-block;
}
.meta-tags > li + li{
    margin-left: 10px;
}
.meta-tags li a {
    color: #a5a5a5;
    font-size: 12px;
    font-weight: 600;
}
.meta-tags li a > i {
    font-size: 17px;
    margin-right: 5px;
}
.meta-tags li a > i,
.meta-tags > li + li::before  {
    vertical-align: middle;
}
.news_item:hover h3,
.meta-tags li a:hover,
.meta-tags li a:focus{
    color:#00bcd4;
}


/*Pagination*/
.pagination li > a {
    height: 36px;
    width: 36px;
    padding: 0;
    line-height: 36px;
    font-size: 18px;
    color: #a5a5a5;
    text-align: center;
    margin: 0 4px;
    border: none;
}
.pagination li > a,
.pagination li:first-child  a,
.pagination li:last-child  a {
    -webkit-border-radius: 50% !important;
    border-radius: 50% !important;
}
.pagination li > a:hover, .pagination li > a:focus {
    background: #00bcd4;
}
.pagination li > a:hover, .pagination li > a:focus,
.pagination li.active > a{
    color: #fff;
}
.pagination li.active > a {
    background: #643094 !important;
}


blockquote.blockquote {
    font-style: italic;
}
blockquote.blockquote::before {
    content: "\f10d";
    width: 40px;
    font-size: 28px;
    margin-right: 5px;
    color: #a1a1a1;
}
ul.rounded > li + li{
    margin-top: 20px;
}
ul.rounded li::before {
    content: "";
    height: 10px;
    width: 10px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #00bcd4;
    margin-right: 10px;
    vertical-align: middle;
}

/*Post Comments*/
.eny_profile {
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 30px;
}
.eny_profile .profile_photo, .eny_profile .profile_text {
    display: table-cell;
    vertical-align: top;
}
.eny_profile .profile_photo {
    height: 90px;
    width: 90px;
}
.eny_profile .profile_photo > img {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    width: 100%;
}
.eny_profile .profile_text {
    padding-left: 20px;
}

/*Widget Newlatter or search*/
.widget_search .input-group {
    width: 260px;
}
.widget_search .form-control {
    width: 214px;
    -webkit-border-bottom-left-radius: 20px;
    border-bottom-left-radius: 20px;
    -webkit-border-top-left-radius: 20px;
    border-top-left-radius: 20px;
}
.widget_search .form-control, .widget_search .input-group-addon {
    border-color: #d6d5d5;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: table-cell;
}
.widget_search .input-group-addon {
    height: 38px;
    background-color: #00bcd4;
    color: #fff;
    position: relative;
    top: 0;
    left: -5px;
    width: 40px;
    -webkit-border-top-right-radius: 20px;
    border-top-right-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.widget_search .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #414141;
}

/*web Tags*/
.webtags li {
    display: inline-block;
    margin: 1px;
}
.webtags li a, .btn_reply {
    -webkit-border-radius: 24px;
    border-radius: 24px;
    color: #6a6a6a;
    font-size: 12px;
    margin: 2px 0;
    padding: 5px 16px;
    border: 1px solid #c7c7c7;
    display: block;
}
.webtags li a:hover, .webtags li a:focus {
    color: #fff;
    background: #00bcd4;
    border: 1px solid #00bcd4;
}
/*Recent Sidebar*/
.single_post {
    overflow: hidden;
    display: table;
}
.single_post .post, .single_post .text {
    display: table-cell;
}
.single_post .post {
    height: 60px;
    width: 60px;
}
.single_post .post > img {
    width: 100%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.single_post .text {
    vertical-align: middle;
    padding-left: 10px;
    font-size: 14px;
}
.single_post .text > a{
    font-weight: 600;
}
.single_post span{
    font-size: 11px;
    display: block;
}
.single_post:hover a, .single_post:focus a {
    color: #00bcd4;
}

/*Categories*/
.webcats li, .webcats li a {
    display: block;
}
.webcats > li + li {
    margin-top: 10px;
}
.webcats li a::before {
    content: "";
    height: 8px;
    width: 8px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #00bcd4;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}
.webcats li a > span {
    display: inline-block;
    font-weight: 600;
    margin-left: 5px;
    font-size: .875rem;
}
.webcats li a:hover, .webcats li a:focus {
    color: #00bcd4;
}

.whitebox .widget {
    padding: 40px 30px;
    background: #fff;
    -webkit-box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.1);
}

/*-------------------------------*/
/* Our Blog ends*/
/*-------------------------------*/

/*-------------------------------*/
/* Ccntact US*/
/*-------------------------------*/
#map-container {
    height: 450px;
    width: 100%;
    position: relative;
}
.our-address h5 {
    color: #222;
    font-weight: bold;
}
.our-address .pickus {
    font-size: 13px;
    text-transform: uppercase;
    color: #00bcd4;
    font-weight: 800;
    position: relative;
}
.our-address .pickus,
.our-address .pickus::after {
    display: inline-block;
}
.our-address .pickus::after {
    content: attr(data-text);
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    color: #212331;
    overflow: hidden;
    -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
}
.our-address .pickus:hover::after {
    width: 100%;
}
.our-address .pickus:hover,
.our-address .pickus:focus {
    color: #212331;
}

/*Contact Form*/
.getin_form .form-control {
    border: none;
    border-bottom: 1px solid #a5a5a5;
    padding: 12px 0;
    background: #fff;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 44px;
    color: #a5a5a5;
    font-size: 14px;
    position: relative;
    -webkit-transition: border .9s ease;
    -o-transition: border .9s ease;
    transition: border .9s ease;
}
textarea {
    min-height: 175px;
    resize: none;
}
button {
    border: none;
    cursor: pointer;
}
.getin_form .form-control:focus {
    border-bottom: 1px solid #212331;
}
.border-form .form-control {
    border: 1px solid #dedada;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-border-radius: 24px;
    border-radius: 24px;
}
.getin_form .button {
    width: 100%;
}
.logincontainer {
    padding: 3.5rem 1.5rem;
}
.border-form .form-control:focus {
    border: 1px solid #212331;
}

/*CheckBox*/
.form-check {
    position: relative;
    display: block;
    font-size: 13px;
}
.form-group label, .checkbox label {
    font-size: 12px;
    font-weight: 500;
    color: #a5a5a5;
    display: inline-block;
    position: relative;
    padding-left: 10px;
    min-height: 20px;
    cursor: pointer;
}

.form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.form-check input[type="checkbox"]:checked + label::before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f00c";
    font-size: 14px;
    text-align: center;
    line-height: 16px;
    font-weight: 300;
}
.form-check input[type="checkbox"]:checked + label::before {
    background-color: #fff;
}
.form-check input[type="checkbox"]:checked + label::before {
    background-color: #00bcd4;
    border-color: #00bcd4;
    color: #fff;
}
.log-meta {
    margin-bottom: 0;
}
.log-meta > a:hover, .log-meta > a:focus {
    color: #00bcd4;
}


/* Form general- */
::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
    color: #a5a5a5;
}
:-moz-placeholder,
.form-control:-moz-placeholder {
    /* Firefox 18- */
    color: #a5a5a5;
}
::-moz-placeholder,
.form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: #a5a5a5;
}
:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
    color: #a5a5a5;
}
textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder {
    color: #a5a5a5;
    opacity: 1;
}

/*-------------------------------*/
/*  COntact US */
/*-------------------------------*/


/*-------------------------------*/
/* Error */
/*-------------------------------*/
#error .error,  .error h1 {
    position:relative;
}
.error h1,  .error h2 {
    font-weight: bold;
}
.error h1 {
    color:#ebebeb;
    font-size:10rem;
}
.error h2 {
    color:#414141;
    font-size: 3.2rem;
    left: 50%;
    position: absolute;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
#error p {
    margin-left: 10%;
    margin-right: 10%;
}

/*-------------------------------*/
/* Error ends */
/*-------------------------------*/


/*-------------------------------*/
/* Tabs & Accordions*/
/*-------------------------------*/
.accordion .card-header:after {
    font-family: 'FontAwesome';
    content: "\f068";
    float: right;
}
.accordion .card-header.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\f067";
}
/*-------------------------------*/
/* Tabs & Accordions*/
/*-------------------------------*/


/*-------------------------------*/
/*Crypto*/
/*-------------------------------*/
.crypto-ver{
    background: url(../images/banner-crypto.jpg) no-repeat fixed center center;
    -webkit-background-size: cover;
    background-size: cover;
}
.btc-calcus {
    background: #fff;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    padding: 2.5rem 1rem;
}
.btc-calcus .cal-convertor .ccc-widget.ccc-converter > div:first-child {
    border: none !important;
    padding: 0px !important;
    background: none !important;
}
.convertForm label.priceLabel {
    font-weight: 300;
    padding-bottom: 0;
}
.convertForm label.priceLabel,
.convertForm  .fromSymbolLabel,
.convertForm .styledSelect {
    font-size: 14px;
    color: #212331 !important;
}
.convertForm  .fromSymbolLabel {
    line-height: 44px;
    border: 1px solid #00bcd4;
    background: #00bcd4;
    -webkit-border-top-right-radius: 20px;
    border-top-right-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.convertForm input.priceInput {
    font-family: 'Open Sans', sans-serif;
    height: 45px;
    -webkit-border-top-left-radius: 20px !important;
    border-top-left-radius: 20px !important;
    -webkit-border-bottom-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
}
.convertForm .styledSelect {
    -webkit-border-top-right-radius: 20px;
    border-top-right-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 45px;
}
.convertForm .poweredByWrapper {
    display: none;
}
.section-converter-ccc {
    margin-bottom: 20px;
}

/*-------------------------------*/
/* Crypto ends */
/*-------------------------------*/




/*-------------------------------*/
/* Footer*/
/*-------------------------------*/
footer .copyrights{
    font-size: 13px;
}
.footer-logo {
    display: inline-block;
    max-width: 230px;
}
footer ul.social-icons:not(.small) li a {
    height: 60px;
    width: 60px;
    line-height: 60px;
    font-size: 26px;
    border: none;
    background: transparent;
}
footer ul.social-icons li a {
    background: #f5f5f5;
}
footer .copyrights > a:hover,
footer .copyrights > a:focus {
    color: #00bcd4;
}

/*-------------------------------*/
/* Footer ends*/
/*-------------------------------*/


/*-------------------------------*/
/*PreLoader*/
/*-------------------------------*/
.loader {
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1111;
    background:#fff;
    overflow-x:hidden;
}
.loader-inner {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.loader-inner > img {
    width: 120px;
    max-width: 120px;
    display: inline-block;
}

.loader-blocks {
    height: 58px;
    width: 58px;
}
.loader span {
    background: -webkit-linear-gradient(90deg, #423f9c 31%, #862359 69%);
    background: -webkit-gradient(linear, left top, right top, color-stop(31%, #423f9c), color-stop(69%, #862359));
    background: -webkit-linear-gradient(left, #423f9c 31%, #862359 69%);
    background: -o-linear-gradient(left, #423f9c 31%, #862359 69%);
    background: linear-gradient(90deg, #423f9c 31%, #862359 69%);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: block;
    height: 12px;
    opacity: 0;
    position: absolute;
    width: 12px;
    animation: load 4.6s ease-in-out infinite;
    -o-animation: load 4.6s ease-in-out infinite;
    -ms-animation: load 4.6s ease-in-out infinite;
    -webkit-animation: load 4.6s ease-in-out infinite;
    -moz-animation: load 4.6s ease-in-out infinite;
}
.loader span.block-1 {
    animation-delay: 1.06s;
    -o-animation-delay: 1.06s;
    -ms-animation-delay: 1.06s;
    -webkit-animation-delay: 1.06s;
    -moz-animation-delay: 1.06s;
    left: 0px;
    top: 0px;
}
.loader span.block-2 {
    animation-delay: 0.97s;
    -o-animation-delay: 0.97s;
    -ms-animation-delay: 0.97s;
    -webkit-animation-delay: 0.97s;
    -moz-animation-delay: 0.97s;
    left: 16px;
    top: 0px;
}
.loader span.block-3 {
    animation-delay: 0.87s;
    -o-animation-delay: 0.87s;
    -ms-animation-delay: 0.87s;
    -webkit-animation-delay: 0.87s;
    -moz-animation-delay: 0.87s;
    left: 31px;
    top: 0px;
}
.loader span.block-4 {
    animation-delay: 0.78s;
    -o-animation-delay: 0.78s;
    -ms-animation-delay: 0.78s;
    -webkit-animation-delay: 0.78s;
    -moz-animation-delay: 0.78s;
    left: 47px;
    top: 0px;
}
.loader span.block-5 {
    animation-delay: 0.69s;
    -o-animation-delay: 0.69s;
    -ms-animation-delay: 0.69s;
    -webkit-animation-delay: 0.69s;
    -moz-animation-delay: 0.69s;
    left: 0px;
    top: 16px;
}
.loader span.block-6 {
    animation-delay: 0.6s;
    -o-animation-delay: 0.6s;
    -ms-animation-delay: 0.6s;
    -webkit-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
    left: 16px;
    top: 16px;
}
.loader span.block-7 {
    animation-delay: 0.51s;
    -o-animation-delay: 0.51s;
    -ms-animation-delay: 0.51s;
    -webkit-animation-delay: 0.51s;
    -moz-animation-delay: 0.51s;
    left: 31px;
    top: 16px;
}
.loader span.block-8 {
    animation-delay: 0.41s;
    -o-animation-delay: 0.41s;
    -ms-animation-delay: 0.41s;
    -webkit-animation-delay: 0.41s;
    -moz-animation-delay: 0.41s;
    left: 47px;
    top: 16px;
}
.loader span.block-9 {
    animation-delay: 0.32s;
    -o-animation-delay: 0.32s;
    -ms-animation-delay: 0.32s;
    -webkit-animation-delay: 0.32s;
    -moz-animation-delay: 0.32s;
    left: 0px;
    top: 31px;
}
.loader span.block-10 {
    animation-delay: 0.23s;
    -o-animation-delay: 0.23s;
    -ms-animation-delay: 0.23s;
    -webkit-animation-delay: 0.23s;
    -moz-animation-delay: 0.23s;
    left: 16px;
    top: 31px;
}
.loader span.block-11 {
    animation-delay: 0.14s;
    -o-animation-delay: 0.14s;
    -ms-animation-delay: 0.14s;
    -webkit-animation-delay: 0.14s;
    -moz-animation-delay: 0.14s;
    left: 31px;
    top: 31px;
}
.loader span.block-12 {
    animation-delay: 0.05s;
    -o-animation-delay: 0.05s;
    -ms-animation-delay: 0.05s;
    -webkit-animation-delay: 0.05s;
    -moz-animation-delay: 0.05s;
    left: 47px;
    top: 31px;
}
.loader span.block-13 {
    animation-delay: -0.05s;
    -o-animation-delay: -0.05s;
    -ms-animation-delay: -0.05s;
    -webkit-animation-delay: -0.05s;
    -moz-animation-delay: -0.05s;
    left: 0px;
    top: 47px;
}
.loader span.block-14 {
    animation-delay: -0.14s;
    -o-animation-delay: -0.14s;
    -ms-animation-delay: -0.14s;
    -webkit-animation-delay: -0.14s;
    -moz-animation-delay: -0.14s;
    left: 16px;
    top: 47px;
}
.loader span.block-15 {
    animation-delay: -0.23s;
    -o-animation-delay: -0.23s;
    -ms-animation-delay: -0.23s;
    -webkit-animation-delay: -0.23s;
    -moz-animation-delay: -0.23s;
    left: 31px;
    top: 47px;
}
.loader span.block-16 {
    animation-delay: -0.32s;
    -o-animation-delay: -0.32s;
    -ms-animation-delay: -0.32s;
    -webkit-animation-delay: -0.32s;
    -moz-animation-delay: -0.32s;
    left: 47px;
    top: 47px;
}
@keyframes load {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-97px);
        -o-transform: translateY(-97px);
        transform: translateY(-97px);
    }
    15% {
        opacity: 0;
        -webkit-transform: translateY(-97px);
        -o-transform: translateY(-97px);
        transform: translateY(-97px);
    }
    30% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
    70% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
    85% {
        opacity: 0;
        -webkit-transform: translateY(97px);
        -o-transform: translateY(97px);
        transform: translateY(97px);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(97px);
        -o-transform: translateY(97px);
        transform: translateY(97px);
    }
}
@-o-keyframes load {
    0% {
        opacity: 0;
        -o-transform: translateY(-97px);
    }
    15% {
        opacity: 0;
        -o-transform: translateY(-97px);
    }
    30% {
        opacity: 1;
        -o-transform: translateY(0);
    }
    70% {
        opacity: 1;
        -o-transform: translateY(0);
    }
    85% {
        opacity: 0;
        -o-transform: translateY(97px);
    }
    100% {
        opacity: 0;
        -o-transform: translateY(97px);
    }
}
@-webkit-keyframes load {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-97px);
    }
    15% {
        opacity: 0;
        -webkit-transform: translateY(-97px);
    }
    30% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
    70% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
    85% {
        opacity: 0;
        -webkit-transform: translateY(97px);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(97px);
    }
}



/*-------------------------------*/
/*Loader ends*/
/*-------------------------------*/




@media screen and (max-width: 768px){
    .container {
        max-width: 98%;
    }
}

/*Large devices (desktops, less than 1200px)*/
@media (max-width: 1200px) {
    html{
        font-size: 15px;
    }
}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 992px) {
    html{
        font-size: 14px;
    }
    h2 {
        font-size: 3rem;
    }
    h3 {
        font-size: 1.5rem;
    }
    h4 {
        font-size: 1.125rem;
    }
    p {
        font-size: 14px;
    }
    .heading-title > span{
        font-size: 1rem;
    }
}

/*Small devices (landscape phones, less than 768px)*/
@media (max-width: 768px) {
    html{
        font-size: 13px;
    }
}
@media (max-width: 576px) {
    html{
        font-size: 12px;
    }
}

.buttonBar {
    display: none;
}

.navbar-brand img {
    width: 230px;
}

.feature-item .icon:hover {
    background: #0f4c81 !important;
}
.heading-title > span, .process-wrapp li:hover > .pro-step, #app-feature h4, h2.darkcolor ,
#contactus h5{
    color: #0f4c81 !important;
}

.feature-item .icon, .side-menu {
    background:#0f4c81 !important;
}

.navbar-nav .nav-link {
    font-size: 16px;
}

.fixedmenu .navbar-nav .nav-link.active, .fixedmenu .navbar-nav .nav-link.active:hover, .fixedmenu .navbar-nav .nav-link.active:focus, .center-brand.fixedmenu .navbar-nav .nav-link.active {
    background: #0a3153 !important;
}
.navbar-nav .nav-link:hover, .navbar-nav .nav-link:focus, .sidebar-nav .navbar-nav .nav-link.active {
    color: #ccc !important;
}


.gradient_bg_default{
    background: rgb(157,157,157) !important;
    background: linear-gradient(90deg, rgba(157,157,157,1) 4%, rgba(37,69,97,1) 59%, rgba(10,49,83,1) 90%) !important;
}
#app-feature .feature-item:hover .icon {
    //background: #0a3153 !important;
    background: #4b4a4a !important;
}

.feature-item:hover .icon {
    -webkit-animation: itg_pulse 1s infinite;
    -o-animation: itg_pulse 1s infinite;
    animation: itg_pulse 1s infinite;
    -webkit-box-shadow: 0 0 0 0 #0a3153 !important;
    box-shadow: 0 0 0 0 #0a3153 !important;
}

#our-apps{

    background: rgb(255,255,255) !important;
    background: radial-gradient(circle, rgba(255,255,255,1) 22%, rgba(213,213,213,1) 100%) !important;
}


.btnprimary ,  .back-top{
    background: #4b4a4a!important;
    border: 1px solid #4b4a4a!important;
}
.btnprimary:hover, .btnprimary:focus, .back-top:hover, .back-top::before{
    background: #0f4c81!important;
    border: 1px solid #0f4c81!important;
}


.loader span {
    background: #0f4c81 !important;
}
