/*Bootstrap CSS*/
@import "bootstrap.min.css";

/*FontAwesome*/
@import "font-awesome.min.css";

/*Animate css*/
@import "animate.min.css";

/*Owl Slider*/
/*@import "owl.carousel.min.css";*/

/*CubePortfolio*/
/*@import "cubeportfolio.min.css";*/

/*FancyBox*/
@import "jquery.fancybox.min.css";

/*Youtube Background Video*/
@import "jquery.background-video.css";

/*Revolution Slider*/
/*@import "settings.css";
@import "layers.css";
@import "navigation.css";

*/







