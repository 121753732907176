/*Global Styling*/
body {
    font-family: 'Raleway', sans-serif;
    color: #a5a5a5;
}

/*common font family*/
p, .heading-title > span, .process-wrapp li > .pro-step,
.progress-bars .progress p, .progress-bars .progress .progress-bar span,
.price-table .ammount .dur, .pagination li > a, .counters .count_nums,
.price-table .ammount h2, .price-table ul li, .webcats li a > span,
.getin_form .form-control{
    font-family: 'Open Sans', sans-serif;
}

/* Helper Classes & Shorcodes */
.bglight {
    background: #f6f6f6;
}
.bgdefault {
    background: #212331;
}
.whitecolor {
    color: #ffffff;
}
.darkcolor {
    color: #212331;
}
.extradark-color {
    color: #212331;
}
.defaultcolor {
    color: #212331;
}
section{
    position: relative;
}
.gradient_bg{
    background: #f2c900;
}
.gradient_bg_default{
    background: #212331;
}

/*heading Titles */
.heading-title > span{
    color: #f2c900;
}
.whitecolor.heading-title > span{
    color: #fff;
}


 /*hover on images*/
.hover-effect::before,
.hover-effect::after {
   background: #fff;
}

/*Back To Top*/
.back-top {
   color: #fff;
   background: #f2c900;
}
.back-top::before {
   background: #212331;
}
.back-top:hover, .back-top:focus {
   color: #fff;
}

/* ----- Social Icons ----- */
ul.social-icons li a {
   color: #676767;
}
ul.social-icons.white li a,
ul.social-icons-simple.white li a{
    color: #fff;
}
ul.social-icons-simple li a:hover,
ul.social-icons-simple li a:focus{
    color: #212331;
}
ul.social-icons li a:hover,
ul.social-icons li a:focus,
ul.social-icons.white li a:hover,
ul.social-icons.white li a:focus{
    color: #fff;
   -webkit-box-shadow: 0 0 15px 30px #212331 inset;
   box-shadow: 0 0 15px 30px #212331 inset;
}



/*-----Buttons-----*/
.btnprimary, .btnprimary-alt{
    background: #212331;
}
.btnsecondary{
   background: #f2c900;
}
.btnwhite{
    background: #fff;
   color: #212331;
}
.btnwhite-hole {
   background: transparent;
   border: 1px solid #fff;
}
.btnwhite:hover, .btnwhite:focus{
    background: #212331;
}

.btnwhite-hole:hover, .btnwhite-hole:focus {
   color: #212331;
   background: #fff;
}
.btnprimary:hover, .btnprimary:focus{
   background: #f2c900;
   border: 1px solid #f2c900;
}
.btnprimary-alt:hover, .btnprimary-alt:focus {
   background: transparent;
   border: 1px solid #212331;
   color: #212331;
}
.rev_slider .btnprimary-alt:hover, .rev_slider .btnprimary-alt:focus {
   background: transparent;
   border: 1px solid #fff;
}

.btnsecondary:hover, .btnsecondary:focus {
   background: #212331;
   border: 1px solid #212331;
}
.btnsecondary.hvrwhite:hover, .btnsecondary.hvrwhite:focus,
.btnprimary.hvrwhite:hover, .btnprimary.hvrwhite:focus {
   background: #fff;
   border: 1px solid #fff;
   color: #212331;
}

.btn-gradient-hvr::before {
    background: -webkit-linear-gradient(90deg, #423f9c, #862359);
    background: -webkit-gradient(linear, left top, right top, color-stop(#423f9c), color-stop(#862359));
    background: -webkit-linear-gradient(left, #423f9c, #862359);
    background: -o-linear-gradient(left, #423f9c, #862359);
    background: -webkit-gradient(linear, left top, right top, from(#423f9c), to(#862359));
    background: linear-gradient(90deg, #423f9c, #862359);
}
.scndry-gradient-hvr::before {
    background: -webkit-linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
    background: -webkit-gradient(linear, left top, right top, color-stop(31%, #00e4ce), color-stop(69%, #00abc9));
    background: -webkit-linear-gradient(left, #00e4ce 31%, #00abc9 69%);
    background: -o-linear-gradient(left, #00e4ce 31%, #00abc9 69%);
    background: linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
}
.btn-gradient-hvr:hover, .btn-gradient-hvr:focus {
   border-color: #f2c900;
}
.scndry-gradient-hvr:hover, .scndry-gradient-hvr:focus {
   border-color: #212331;
}

.btnwhite-hole, .btnwhite:hover, .btnwhite:focus,
.btnprimary, .btnsecondary, .btnprimary-alt,
.btnprimary:hover, .btnprimary:focus,
.btnsecondary:hover, .btnsecondary:focus,
.rev_slider .btnprimary-alt:hover, .rev_slider .btnprimary-alt:focus{
   color: #fff;
}


/*-------------------------------*/
    /*Navigation Starts */
/*-------------------------------*/
.bg-white {
   background: #fff;
}
.fixedmenu {
   background: #212331;
}
.center-brand.fixedmenu,
.bg-white.fixedmenu,
.bg-transparent-light.fixedmenu,
.bg-transparent-white.fixedmenu{
   background: #fff;
}
.nav-whitebg {
   background: #fff;
}
.navbar-nav .nav-link {
   color: #212331;
}
.center-brand .navbar-nav .nav-link,
.transparent-bg .navbar-nav .nav-link,
.fixed-bottom .navbar-nav .nav-link{
   color: #fff;
}
.center-brand.fixedmenu .navbar-nav .nav-link {
   color: #212331;
}
.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus {
   color: #f2c900;
}
.fixedmenu .navbar-nav .nav-link.active,
.fixedmenu .navbar-nav .nav-link.active:hover,
.fixedmenu .navbar-nav .nav-link.active:focus,
.center-brand.fixedmenu .navbar-nav .nav-link.active{
   background: #f2c900;
   color: #fff;
}
.center-brand .navbar-nav .nav-link:first-of-type {
   background: rgba(0,0,0,0);
}


/*toggle responsive*/
.navbar-toggler span {
   background: #fff;
}
.bg-white .navbar-toggler span {
   background: #212331;
}
.center-brand.fixedmenu .navbar-toggler span {
   background: #212331;
}
.navbar-toggler:not(.collapsed) span:nth-child(2) {
    background-color: transparent;
}


/*-----Side Menu----*/
.side-menu {
	background: #212331;
}
.just-sidemenu .side-menu {
	background: #f2c900;
}
.transparent-sidemenu .side-menu {
    background: rgba(0,0,0,.95);
}
.side-menu.side-menu-active {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

/*Side overlay*/
#close_side_menu {
    background-color: #000;
}

/*side clode btn*/
.side-menu .btn-close::before, .side-menu .btn-close::after {
	background: #fff;
}

/*side open btn*/
.sidemenu_btn > span {
   background: #212331;
}
.center-brand .sidemenu_btn > span,
.transparent-bg .sidemenu_btn > span,
.fixed-bottom .sidemenu_btn > span,
.just-sidemenu .toggle_white.sidemenu_btn > span {
   background: #fff;
}
.center-brand.fixedmenu .sidemenu_btn > span{
   background: #212331;
}
.side-nav .navbar-nav .nav-link {
	color: #fff;
}
.side-nav .navbar-nav .nav-link::after{
   background: #fff;
}
.side-nav .navbar-nav .nav-link.active {
   background: transparent;
}


@media (max-width: 992px) {
   .center-brand .navbar-nav .nav-link,
   .transparent-bg .navbar-nav .nav-link {
      background: #212331;
   }
   .center-brand.fixedmenu .navbar-nav .nav-link,
   .transparent-bg.fixedmenu .navbar-nav .nav-link {
      background: transparent;
   }
}

/*-------------------------------*/
    /*Navigation Ends */
/*-------------------------------*/


/*-------------------------------*/
    /*OWl Slider*/
/*-------------------------------*/
/*Dots*/
.owl-dots .owl-dot {
   background: rgba(255, 255, 255, .35);

}
#text-fading .owl-dots .owl-dot {
	background: #fff;
}
.vertical-dot .owl-dots .owl-dot {
   background: rgba(255, 255, 255, .5);
}

.owl-dots .owl-dot::after {
   background-color: #212331;
}
.vertical-dot .owl-dots .owl-dot.active {
   background: #fff;
}

/*Buttons*/
.owl-nav .owl-prev, .owl-nav .owl-next{
    background: #212331;
    color: #fff;
}
.owl-nav .owl-prev:hover, .owl-nav .owl-next:hover,
.owl-nav .owl-prev:focus, .owl-nav .owl-next:focus{
	background: #f2c900;
}

/*Service Slider*/
#services-slider .service-box{
   background: #212331;
   color: #fff;
}
#services-slider .service-box::before {
   background: #212331;
}
#services-slider .owl-item.center .service-box::before {
   background: #f2c900;
}
#services-slider .owl-item.center .service-box {
   background: #f2c900;
}

/*-------------------------------*/
    /*OWL Slider ends*/
/*-------------------------------*/


/*-------------------------------*/
      /*Revolution SLider*/
/*-------------------------------*/
.tp-bullet {
   background: rgba(255, 255, 255, .3);
}
.tp-bullet:hover, .tp-bullet:focus{
   background: rgba(255, 255, 255, .6);
}
.tp-bullet::before {
   background: #fff;
}

.uranus .tp-bullet-inner{
   background: #212331;
}
.uranus .tp-bullet.selected, .uranus .tp-bullet:hover {
    -webkit-box-shadow: 0 0 0 2px #f2c900;
    box-shadow: 0 0 0 2px #f2c900;
}
.uranus .tp-bullet.selected .tp-bullet-inner,
.uranus .tp-bullet:hover .tp-bullet-inner {
   background: #f2c900;
}


.rev_slider li.rev_gradient::after {
   background: #f2c900;
}
.banner-overlay::after {
   background: rgba(0,0,0,.39);
}

/*-------------------------------*/
/* Main Banner Ends*/
/*-------------------------------*/


/*-------------------------------*/
    /* Horizontal Half Blocks*/
/*-------------------------------*/
.half-section .img-container{}
.half-section .imgone{
    background: url("../images/split-img1.jpg") no-repeat;
}
.half-section .imgtwo{
    background: url("../images/split-img2.jpg") no-repeat;
}

/*-------------------------------*/
    /*Horizontal Half Blocks ends*/
/*-------------------------------*/


/*-------------------------------*/
    /*Page Headers */
/*-------------------------------*/
.page-header {
   background: url(../images/page-header.jpg) no-repeat;
}
.breadcrumb {
	background: transparent;
}
.breadcrumb .breadcrumb-item {
	color: #fff;
 }
.breadcrumb .breadcrumb-item:hover,
.breadcrumb .breadcrumb-item:focus,
.breadcrumb .breadcrumb-item.active {
	color: #f2c900;
}
/*-------------------------------*/
    /*Page Headers */
/*-------------------------------*/


/*-------------------------------*/
    /* Work Process */
/*-------------------------------*/
.process-wrapp li > .pro-step {
   border: 1px solid #fff;
   color: #fff;
}
.process-wrapp li:hover > .pro-step {
   -webkit-box-shadow: 0 0 25px 50px #fff inset;
   box-shadow: 0 0 25px 50px #fff inset;
   color: #212331;
}
.process-wrapp li::before, .process-wrapp li::after {
    background: rgba(255,255,255, .5);
}

/*process with box */
.process-number {
   background: #fff;
}
.process-number .pro-step {
	color: #fff;
	background: #d2d2d2;
}
.process-number .pro-step::after {
	background: #fff;
}
/*-------------------------------*/
    /*Work Process ends */
/*-------------------------------*/


/*-------------------------------*/
    /* Our Team */
/*-------------------------------*/
.team-box.grey-shade {
   -webkit-box-shadow: 0px 0px 0px 1px #b5b5b5;
   box-shadow: 0px 0px 0px 1px #b5b5b5;
}
.team-box::before {
   border: 20px solid #fff;
}

.team-box.no-hover .team-content {
   -webkit-box-shadow: 0px 10px 5px -10px #b7b7b7;
   box-shadow: 0px 10px 5px -10px #b7b7b7;
   background: #fff;
}
.team-box.no-hover .team-content h3 {
   color: #212331;
}
.team-box.no-hover .team-content::before {
   background: #f2c900;
}
.team-box.no-hover:hover .team-content::before,
.team-box.no-hover:hover .team-content,
.team-box.no-hover:hover .team-content h3 ,
.team-box.no-hover:hover .team-content ul.social-icons li a {
   color: #fff;
}

/*Progress Bars*/
.progress-bars .progress .progress-bar {
    background: -webkit-linear-gradient(90deg, #f6663f 31%, #ed145b 69%);
    background: -webkit-gradient(linear, left top, right top, color-stop(31%, #f6663f), color-stop(69%, #ed145b));
    background: -webkit-linear-gradient(left, #f6663f 31%, #ed145b 69%);
    background: -o-linear-gradient(left, #f6663f 31%, #ed145b 69%);
    background: linear-gradient(90deg, #f6663f 31%, #ed145b 69%);
}
/*-------------------------------*/
    /*Our Team ends */
/*-------------------------------*/


/*-------------------------------*/
    /* Gallery Portfolio */
/*-------------------------------*/
/*filters*/
.cbp-l-filters .cbp-filter-item span {
   color: #b7b7b7;
   border: 1px solid #b7b7b7;
}
.cbp-l-filters .cbp-filter-item:hover span {
    border: 1px solid #f2c900;
   color: #fff;
   background: #f2c900;
}
.cbp-l-filters .cbp-filter-item-active span,
.cbp-l-filters .cbp-filter-item:focus span {
   border: 1px solid #212331;
   color: #fff;
   background: #212331;
}
#portfolio_top::before,
#portfolio_top::after {
   background: #fff;
}
.border-portfolio .cbp-item img {
   border: 20px solid #fff;
}
.bottom-text .port_head {
   font-family: 'Open Sans', sans-serif;
}

/*Overlays*/
.overlay {
   background: rgba(242, 201, 0, .9);
}
.dark_overlay .overlay {
   background: rgba(33,35,49, .9);
}
.overlay > .plus::before,
.overlay > .plus::after {
   background: #fff;
}
.gradient_text {
   color: #00abc9;
}

/*Single Porfolio*/
.item-one {
   background: url(../images/bg-single-1.jpg) no-repeat;
}
.item-two {
   background: url(../images/bg-single-2.jpg) no-repeat;
}
.item-three {
   background: url(../images/bg-single-3.jpg) no-repeat;
}
.item-four {
   background: url(../images/bg-single-4.jpg) no-repeat;
}
.item-five {
   background: url(../images/bg-single-5.jpg) no-repeat;
}
.item-six {
   background: url(../images/bg-single-6.jpg) no-repeat;
}
.item-seven {
   background: url(../images/bg-single-7.jpg) no-repeat;
}
.item-eight {
   background: url(../images/arrow-slide1.jpg) no-repeat;
}
.item-nine {
   background: url(../images/arrow-slide1.jpg) no-repeat;
}
/*-------------------------------*/
    /*Gallery ends */
/*-------------------------------*/


/*-------------------------------*/
    /* Mobile Apps & Features */
/*-------------------------------*/
/*apps background*/
.bg-apps {
   background: url(../images/bg-apps.jpg) no-repeat;
}

/*app features*/
.feature-item h4 {
	color: #222222;
}
.feature-item .icon {
	background: #212331;
	color: #fff;
}
.feature-item .icon::before {
	background: transparent;
	border: 4px solid #fff;
}

#app-feature .feature-item:hover .icon {
   background: #f2c900;
}
.feature-item:hover .icon {
	-webkit-box-shadow: 0 0 0 0 rgba(242, 201, 0, 1);
	box-shadow: 0 0 0 0 rgba(242, 201, 0, 1);
}
@-webkit-keyframes itg_pulse {
    0% {
        -webkit-box-shadow: 0 0 0 5px rgba(242, 201, 0, 0.9);
        box-shadow: 0 0 0 5px rgba(242, 201, 0, 0.9);
    }
    25% {
        -webkit-box-shadow: 0 0 0 10px rgba(242, 201, 0, 0.6);
        box-shadow: 0 0 0 10px rgba(242, 201, 0, 0.6);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(242, 201, 0, 0.3);
        box-shadow: 0 0 0 15px rgba(242, 201, 0, 0.3);
    }
    100% {
        -webkit-box-shadow: 0 0 0 60px rgba(242, 201, 0, 0.1);
        box-shadow: 0 0 0 20px rgba(242, 201, 0, 0.1);
    }
}
@-o-keyframes itg_pulse {
    0% {
        box-shadow: 0 0 0 5px rgba(242, 201, 0, 0.9);
    }
    25% {
        box-shadow: 0 0 0 10px rgba(242, 201, 0, 0.6);
    }
    70% {
        box-shadow: 0 0 0 15px rgba(242, 201, 0, 0.3);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(242, 201, 0, 0.1);
   }
}
@keyframes itg_pulse {
    0% {
        -webkit-box-shadow: 0 0 0 5px rgba(242, 201, 0, 0.9);
        box-shadow: 0 0 0 5px rgba(242, 201, 0, 0.9);
    }
    25% {
        -webkit-box-shadow: 0 0 0 10px rgba(242, 201, 0, 0.6);
        box-shadow: 0 0 0 10px rgba(242, 201, 0, 0.6);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(242, 201, 0, 0.3);
        box-shadow: 0 0 0 15px rgba(242, 201, 0, 0.3);
    }
    100% {
        -webkit-box-shadow: 0 0 0 60px rgba(242, 201, 0, 0.1);
        box-shadow: 0 0 0 20px rgba(242, 201, 0, 0.1);
   }
}
.feature-item.active {
   background: #f7f7f7;
}
/*-------------------------------*/
    /*Mobile Apps ends */
/*-------------------------------*/


/*-------------------------------*/
    /* Counters */
/*-------------------------------*/
.fact-iconic h3::after {
   background: #ffffff;
}
.bg-counter-light .icon-counters:hover .img-icon {
   color: #fff;
   background: #212331;
}
.bg-counter-light {
   background: url(../images/bg-counter-light.jpg) no-repeat;
}
/*-------------------------------*/
    /*Counters ends */
/*-------------------------------*/


/*-------------------------------*/
    /* Pricings */
/*-------------------------------*/
.pricing-bg {
   background: url(../images/page-header-2.jpg)no-repeat;
}
.pricing-bg::before {
   background: #f6f6f6;
}
.price-table {
	border: 1px solid #f6f3f3;
    background: #fff;
}
.price-table .ammount .dur {
	color: #545661;
}
.price-table.active .ammount h2 {
   color: #f2c900;
}
.price-table ul li{
    color: #6e6e6e;
}
.price-table ul li.not-support{
   color: #9b9b9b;
}
.price-table ul li > span::before {
	color: #30e512;
}
.price-table ul li.not-support > span::before{
   color: #ff434b;
}
.centered-table .price-table ul li > span::after {
   background: #f6f6f6;
}

.price-table:hover {
   background: #212331;
   border-color: #212331;
}
.price-table.active:hover {
   background: #f2c900;
   border-color: #f2c900;
}
.price-table:hover *,
.price-table:hover .ammount *,
.price-table:hover .ammount h2,
.price-table:hover .ammount .dur,
.price-table:hover ul li > span::before,
.price-table:hover .btnsecondary,
.price-table:focus .btnsecondary,
.price-table:hover .btnprimary,
.price-table:focus .btnprimary{
   color: #fff;
}
.price-table:hover .btnsecondary,
.price-table:focus .btnsecondary,
.price-table:hover .btnprimary,
.price-table:focus .btnprimary {
   border-color: #fff;
   background: transparent;
}
.price-table .btnsecondary:hover,
.price-table .btnsecondary:focus,
.price-table .btnprimary:hover,
.price-table .btnprimary:focus {
   border-color: #fff;
   background: #fff;
   color: #212331;
}
/*-------------------------------*/
    /* Pricings ends */
/*-------------------------------*/


/*-------------------------------*/
    /*Backgrounds Parallax */
/*-------------------------------*/
/*banner parallax*/
#video-parallax{
    background: url(../images/bg-video.jpg) no-repeat;
}
.rotating-slider {
   background: url(../images/page-header-2.jpg)no-repeat;
}
.rotating-words {
   background: url(../images/page-header-2.jpg)no-repeat;
}
.rotating-slider {
   background: url(../images/banner-fade.jpg)no-repeat;
}
.button-play {
	color: #fff;
	background: #f2c900;
}
.button-play::before {
   background: #212331;
}
.button-play:hover, .button-play:focus {
   color: #fff;
}

/*Background Video*/
.bg-video-container::before{
   background: rgba(33,35,49,.7);
}
/*-------------------------------*/
    /*Backgrounds Parallax ends */
/*-------------------------------*/


/*-------------------------------*/
    /* Testimonials*/
/*-------------------------------*/
.testimonial-wrapp .quoted {
	background: #f2c900;
	color: #fff;
}
.testimonial-wrapp .testimonial-text {
	background: #f6f6f6;
	border: 1px solid #f1f1f1;
}
#testimonial-slider .owl-item:nth-child(2n) .testimonial-wrapp .quoted,
#testimonial-slider .owl-item:nth-child(2n) .testimonial-wrapp:hover .testimonial-text{
   background: #212331;
}
.testimonial-wrapp:hover .testimonial-text{
   background: #f2c900;
   color: #fff;
}

/*Testimonial Quotes*/
.testimonial-bg{
	background: url(../images/bg-testimonial.jpg) no-repeat;
}
.testimonial-bg-light {
	background: url(../images/bg-testimonial-light.jpg) no-repeat;
}
#owl-thumbs.owl-dots .owl-dot {
	background: transparent;
	border: 5px solid rgba(255,255,255,.3);
}
.testimonial-bg-light #owl-thumbs.owl-dots .owl-dot {
   border: 5px solid rgba(0,0,0,.3);
}
#owl-thumbs.owl-dots .owl-dot:hover,
#owl-thumbs.owl-dots .owl-dot.active{
   border: 5px solid rgba(255,255,255,.53);
}
.testimonial-bg-light #owl-thumbs.owl-dots .owl-dot.active {
   border: 5px solid rgba(0,0,0,.53);
}
/*-------------------------------*/
    /* Testimonials ends*/
/*-------------------------------*/


/*-------------------------------*/
    /* Partner/ Logo's*/
/*-------------------------------*/


/*-------------------------------*/
    /* Partner/ Logo's ends*/
/*-------------------------------*/


/*-------------------------------*/
    /* Our Blog*/
/*-------------------------------*/
.news_item{
	background:#fff;
}

.meta-tags li a {
	color: #a5a5a5;
}
.news_item:hover h3,
.meta-tags li a:hover,
.meta-tags li a:focus{
	color:#212331;
}

/*Pagination*/
.pagination li > a {
   color: #a5a5a5;
}
.pagination li > a:hover, .pagination li > a:focus {
   background: #212331;
}
.pagination li > a:hover, .pagination li > a:focus,
.pagination li.active > a{
   color: #fff;
}
.pagination li.active > a {
   background: #f2c900 !important;
}
blockquote.blockquote::before {
	color: #a1a1a1;
}
ul.rounded li::before {
	background: #212331;
}

/*Post Comments*/
.eny_profile {
	border-bottom: 1px solid #e4e4e4;
}

/*Widget Newlatter or search*/
.widget_search .form-control, .widget_search .input-group-addon {
	border-color: #d6d5d5;}
.widget_search .input-group-addon {
	background-color: #212331;
	color: #fff;
}
.widget_search .form-control:focus {
   border: 1px solid #414141;
}

/*web Tags*/
.webtags li a,
.btn_reply {
   color: #6a6a6a;
   border: 1px solid #c7c7c7;
}
.webtags li a:hover, .webtags li a:focus {
   color: #fff;
   background: #212331;
   border: 1px solid #212331;
}
/*Recent Sidebar*/
.single_post:hover a, .single_post:focus a {
   color: #212331;
}

/*Categories*/
.webcats li a::before {
	background: #212331;
}
.webcats li a:hover, .webcats li a:focus {
   color: #212331;
}
.whitebox .widget {
	background: #fff;
}
/*-------------------------------*/
    /* Our Blog ends*/
/*-------------------------------*/

/*-------------------------------*/
    /* Ccntact US*/
/*-------------------------------*/
.our-address h5 {
	color: #222;
}
.our-address .pickus {
   text-transform: uppercase;
   color: #212331;
}
.our-address .pickus::after {
	color: #f2c900;
}
.our-address .pickus:hover,
.our-address .pickus:focus {
   color: #f2c900;
}

/*Contact Form*/
.getin_form .form-control {
   border-bottom: 1px solid #a5a5a5;
   background: #fff;
   color: #a5a5a5;
}
.getin_form .form-control:focus {
   border-bottom: 1px solid #212331;
}
.border-form .form-control {
	border: 1px solid #dedada;
}
.border-form .form-control:focus {
   border: 1px solid #212331;
}

/*CheckBox*/
.form-group label, .checkbox label {
	color: #a5a5a5;
}
.form-check label::before {
	border: 1px solid #cccccc;
	background-color: #fff;
}
.form-check input[type="checkbox"]:checked + label::before {
	background-color: #fff;
}
.form-check input[type="checkbox"]:checked + label::before {
	background-color: #f2c900;
	border-color: #f2c900;
   color: #fff;
}
.log-meta > a:hover, .log-meta > a:focus {
   color: #f2c900;
}

/* Form general- */
::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
   color: #a5a5a5;
}
:-moz-placeholder,
.form-control:-moz-placeholder {
   /* Firefox 18- */
   color: #a5a5a5;
}
::-moz-placeholder,
.form-control::-moz-placeholder {
   /* Firefox 19+ */
   color: #a5a5a5;
}
:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
   color: #a5a5a5;
}
textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder {
   color: #a5a5a5;
}
/*-------------------------------*/
    /*  COntact US */
/*-------------------------------*/


/*-------------------------------*/
    /* Footer*/
/*-------------------------------*/

footer ul.social-icons:not(.small) li a {
   background: transparent;
}
footer ul.social-icons li a {
   background: #f5f5f5;
}
footer .copyrights > a:hover,
footer .copyrights > a:focus {
   color: #212331;
}
/*-------------------------------*/
    /* Footer ends*/
/*-------------------------------*/


/*-------------------------------*/
    /*PreLoader*/
/*-------------------------------*/
.loader {
  background:#fff;
}
.loader span {
   background: #212331;
}
/*-------------------------------*/
      /*Loader ends*/
/*-------------------------------*/
