@import url(plugins.css);
@import url(yellow.css);
:root {
  --blue: #1782f1;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --dark-green: #0D241C;
  --light-green: #98ABA5;
  --ash-gray: #C4C4C4;
  --light-brown: #C6B9A6;
  --dark-blue: #0f4c81;
  --primary: #337bb2;
  --secondary: #2c3b41;
  --success: #00a65a;
  --info: #00c0ef;
  --warning: #f39c12;
  --danger: #f56954;
  --light: #F5F3F4;
  --dark: #222d32;
  --dark-green: #0D241C;
  --light-green: #98ABA5;
  --ash-gray: #C4C4C4;
  --light-brown: #C6B9A6;
  --dark-blue: #0f4c81;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: Poppins, Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: Poppins, Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #337bb2;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #225277;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.25rem;
}

h2, .h2 {
  font-size: 1.8rem;
}

h3, .h3 {
  font-size: 1.575rem;
}

h4, .h4 {
  font-size: 1.35rem;
}

h5, .h5 {
  font-size: 1.125rem;
}

h6, .h6 {
  font-size: 0.9rem;
}

.lead {
  font-size: 1.125rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1110px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c6dae9;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #95bad7;
}

.table-hover .table-primary:hover {
  background-color: #b4cee2;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b4cee2;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c4c8ca;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #91999c;
}

.table-hover .table-secondary:hover {
  background-color: #b7bbbe;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #b7bbbe;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8e6d1;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7ad1a9;
}

.table-hover .table-success:hover {
  background-color: #a5dfc5;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a5dfc5;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8edfb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7adef7;
}

.table-hover .table-info:hover {
  background-color: #a0e7fa;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a0e7fa;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fce3bd;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f9cc84;
}

.table-hover .table-warning:hover {
  background-color: #fbd9a5;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fbd9a5;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fcd5cf;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #fab1a6;
}

.table-hover .table-danger:hover {
  background-color: #fbc0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fbc0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfcfc;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #faf9f9;
}

.table-hover .table-light:hover {
  background-color: #efefef;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #efefef;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c1c4c6;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8c9294;
}

.table-hover .table-dark:hover {
  background-color: #b4b7ba;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b4b7ba;
}

.table-dark-green,
.table-dark-green > th,
.table-dark-green > td {
  background-color: #bbc2bf;
}

.table-dark-green th,
.table-dark-green td,
.table-dark-green thead th,
.table-dark-green tbody + tbody {
  border-color: #818d89;
}

.table-hover .table-dark-green:hover {
  background-color: #aeb6b2;
}

.table-hover .table-dark-green:hover > td,
.table-hover .table-dark-green:hover > th {
  background-color: #aeb6b2;
}

.table-light-green,
.table-light-green > th,
.table-light-green > td {
  background-color: #e2e7e6;
}

.table-light-green th,
.table-light-green td,
.table-light-green thead th,
.table-light-green tbody + tbody {
  border-color: #c9d3d0;
}

.table-hover .table-light-green:hover {
  background-color: #d4dbda;
}

.table-hover .table-light-green:hover > td,
.table-hover .table-light-green:hover > th {
  background-color: #d4dbda;
}

.table-ash-gray,
.table-ash-gray > th,
.table-ash-gray > td {
  background-color: #eeeeee;
}

.table-ash-gray th,
.table-ash-gray td,
.table-ash-gray thead th,
.table-ash-gray tbody + tbody {
  border-color: #e0e0e0;
}

.table-hover .table-ash-gray:hover {
  background-color: #e1e1e1;
}

.table-hover .table-ash-gray:hover > td,
.table-hover .table-ash-gray:hover > th {
  background-color: #e1e1e1;
}

.table-light-brown,
.table-light-brown > th,
.table-light-brown > td {
  background-color: #efebe6;
}

.table-light-brown th,
.table-light-brown td,
.table-light-brown thead th,
.table-light-brown tbody + tbody {
  border-color: #e1dbd1;
}

.table-hover .table-light-brown:hover {
  background-color: #e5dfd6;
}

.table-hover .table-light-brown:hover > td,
.table-hover .table-light-brown:hover > th {
  background-color: #e5dfd6;
}

.table-dark-blue,
.table-dark-blue > th,
.table-dark-blue > td {
  background-color: #bccddc;
}

.table-dark-blue th,
.table-dark-blue td,
.table-dark-blue thead th,
.table-dark-blue tbody + tbody {
  border-color: #82a2bd;
}

.table-hover .table-dark-blue:hover {
  background-color: #abc1d3;
}

.table-hover .table-dark-blue:hover > td,
.table-hover .table-dark-blue:hover > th {
  background-color: #abc1d3;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #88b8dd;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 123, 178, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.125rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.7875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
  border-radius: 0;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00a65a;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.7875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(0, 166, 90, 0.9);
  border-radius: 0;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00a65a;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300a65a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #00a65a;
  box-shadow: 0 0 0 0.2rem rgba(0, 166, 90, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #00a65a;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300a65a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #00a65a;
  box-shadow: 0 0 0 0.2rem rgba(0, 166, 90, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00a65a;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #00a65a;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #00a65a;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00d976;
  background-color: #00d976;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 166, 90, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00a65a;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00a65a;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00a65a;
  box-shadow: 0 0 0 0.2rem rgba(0, 166, 90, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f56954;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.7875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(245, 105, 84, 0.9);
  border-radius: 0;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f56954;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f56954' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f56954' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #f56954;
  box-shadow: 0 0 0 0.2rem rgba(245, 105, 84, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #f56954;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f56954' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f56954' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #f56954;
  box-shadow: 0 0 0 0.2rem rgba(245, 105, 84, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f56954;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #f56954;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #f56954;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f89384;
  background-color: #f89384;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(245, 105, 84, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f56954;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f56954;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #f56954;
  box-shadow: 0 0 0 0.2rem rgba(245, 105, 84, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 123, 178, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #337bb2;
  border-color: #337bb2;
}

.btn-primary:hover {
  color: #fff;
  background-color: #2a6694;
  border-color: #28608a;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #2a6694;
  border-color: #28608a;
  box-shadow: 0 0 0 0.2rem rgba(82, 143, 190, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #337bb2;
  border-color: #337bb2;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #28608a;
  border-color: #255980;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 143, 190, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #2c3b41;
  border-color: #2c3b41;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #1d262a;
  border-color: #171f23;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #1d262a;
  border-color: #171f23;
  box-shadow: 0 0 0 0.2rem rgba(76, 88, 94, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #2c3b41;
  border-color: #2c3b41;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #171f23;
  border-color: #12181b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 88, 94, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #00a65a;
  border-color: #00a65a;
}

.btn-success:hover {
  color: #fff;
  background-color: #008045;
  border-color: #00733e;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #008045;
  border-color: #00733e;
  box-shadow: 0 0 0 0.2rem rgba(38, 179, 115, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #00a65a;
  border-color: #00a65a;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #00733e;
  border-color: #006637;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 179, 115, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #00c0ef;
  border-color: #00c0ef;
}

.btn-info:hover {
  color: #fff;
  background-color: #00a1c9;
  border-color: #0097bc;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #00a1c9;
  border-color: #0097bc;
  box-shadow: 0 0 0 0.2rem rgba(38, 201, 241, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #00c0ef;
  border-color: #00c0ef;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #0097bc;
  border-color: #008daf;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 201, 241, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #f39c12;
  border-color: #f39c12;
}

.btn-warning:hover {
  color: #fff;
  background-color: #d4860b;
  border-color: #c87f0a;
}

.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #d4860b;
  border-color: #c87f0a;
  box-shadow: 0 0 0 0.2rem rgba(212, 138, 21, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #f39c12;
  border-color: #f39c12;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #c87f0a;
  border-color: #bc770a;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 138, 21, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #f56954;
  border-color: #f56954;
}

.btn-danger:hover {
  color: #fff;
  background-color: #f34930;
  border-color: #f23f24;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #f34930;
  border-color: #f23f24;
  box-shadow: 0 0 0 0.2rem rgba(247, 128, 110, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #f56954;
  border-color: #f56954;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #f23f24;
  border-color: #f13418;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 128, 110, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #F5F3F4;
  border-color: #F5F3F4;
}

.btn-light:hover {
  color: #212529;
  background-color: #e4dee1;
  border-color: #ded7db;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e4dee1;
  border-color: #ded7db;
  box-shadow: 0 0 0 0.2rem rgba(213, 212, 214, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #F5F3F4;
  border-color: #F5F3F4;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #ded7db;
  border-color: #d8d0d4;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 212, 214, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #222d32;
  border-color: #222d32;
}

.btn-dark:hover {
  color: #fff;
  background-color: #13191b;
  border-color: #0d1214;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #13191b;
  border-color: #0d1214;
  box-shadow: 0 0 0 0.2rem rgba(67, 77, 81, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #222d32;
  border-color: #222d32;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #0d1214;
  border-color: #080b0c;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 77, 81, 0.5);
}

.btn-dark-green {
  color: #fff;
  background-color: #0D241C;
  border-color: #0D241C;
}

.btn-dark-green:hover {
  color: #fff;
  background-color: #030806;
  border-color: black;
}

.btn-dark-green:focus, .btn-dark-green.focus {
  color: #fff;
  background-color: #030806;
  border-color: black;
  box-shadow: 0 0 0 0.2rem rgba(49, 69, 62, 0.5);
}

.btn-dark-green.disabled, .btn-dark-green:disabled {
  color: #fff;
  background-color: #0D241C;
  border-color: #0D241C;
}

.btn-dark-green:not(:disabled):not(.disabled):active, .btn-dark-green:not(:disabled):not(.disabled).active,
.show > .btn-dark-green.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-dark-green:not(:disabled):not(.disabled):active:focus, .btn-dark-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 69, 62, 0.5);
}

.btn-light-green {
  color: #212529;
  background-color: #98ABA5;
  border-color: #98ABA5;
}

.btn-light-green:hover {
  color: #fff;
  background-color: #839a93;
  border-color: #7c948c;
}

.btn-light-green:focus, .btn-light-green.focus {
  color: #fff;
  background-color: #839a93;
  border-color: #7c948c;
  box-shadow: 0 0 0 0.2rem rgba(134, 151, 146, 0.5);
}

.btn-light-green.disabled, .btn-light-green:disabled {
  color: #212529;
  background-color: #98ABA5;
  border-color: #98ABA5;
}

.btn-light-green:not(:disabled):not(.disabled):active, .btn-light-green:not(:disabled):not(.disabled).active,
.show > .btn-light-green.dropdown-toggle {
  color: #fff;
  background-color: #7c948c;
  border-color: #758e86;
}

.btn-light-green:not(:disabled):not(.disabled):active:focus, .btn-light-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-light-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(134, 151, 146, 0.5);
}

.btn-ash-gray {
  color: #212529;
  background-color: #C4C4C4;
  border-color: #C4C4C4;
}

.btn-ash-gray:hover {
  color: #212529;
  background-color: #b1b1b1;
  border-color: #ababab;
}

.btn-ash-gray:focus, .btn-ash-gray.focus {
  color: #212529;
  background-color: #b1b1b1;
  border-color: #ababab;
  box-shadow: 0 0 0 0.2rem rgba(172, 172, 173, 0.5);
}

.btn-ash-gray.disabled, .btn-ash-gray:disabled {
  color: #212529;
  background-color: #C4C4C4;
  border-color: #C4C4C4;
}

.btn-ash-gray:not(:disabled):not(.disabled):active, .btn-ash-gray:not(:disabled):not(.disabled).active,
.show > .btn-ash-gray.dropdown-toggle {
  color: #212529;
  background-color: #ababab;
  border-color: #a4a4a4;
}

.btn-ash-gray:not(:disabled):not(.disabled):active:focus, .btn-ash-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-ash-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(172, 172, 173, 0.5);
}

.btn-light-brown {
  color: #212529;
  background-color: #C6B9A6;
  border-color: #C6B9A6;
}

.btn-light-brown:hover {
  color: #212529;
  background-color: #b7a78f;
  border-color: #b2a187;
}

.btn-light-brown:focus, .btn-light-brown.focus {
  color: #212529;
  background-color: #b7a78f;
  border-color: #b2a187;
  box-shadow: 0 0 0 0.2rem rgba(173, 163, 147, 0.5);
}

.btn-light-brown.disabled, .btn-light-brown:disabled {
  color: #212529;
  background-color: #C6B9A6;
  border-color: #C6B9A6;
}

.btn-light-brown:not(:disabled):not(.disabled):active, .btn-light-brown:not(:disabled):not(.disabled).active,
.show > .btn-light-brown.dropdown-toggle {
  color: #212529;
  background-color: #b2a187;
  border-color: #ad9a7f;
}

.btn-light-brown:not(:disabled):not(.disabled):active:focus, .btn-light-brown:not(:disabled):not(.disabled).active:focus,
.show > .btn-light-brown.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 163, 147, 0.5);
}

.btn-dark-blue {
  color: #fff;
  background-color: #0f4c81;
  border-color: #0f4c81;
}

.btn-dark-blue:hover {
  color: #fff;
  background-color: #0b385f;
  border-color: #0a3153;
}

.btn-dark-blue:focus, .btn-dark-blue.focus {
  color: #fff;
  background-color: #0b385f;
  border-color: #0a3153;
  box-shadow: 0 0 0 0.2rem rgba(51, 103, 148, 0.5);
}

.btn-dark-blue.disabled, .btn-dark-blue:disabled {
  color: #fff;
  background-color: #0f4c81;
  border-color: #0f4c81;
}

.btn-dark-blue:not(:disabled):not(.disabled):active, .btn-dark-blue:not(:disabled):not(.disabled).active,
.show > .btn-dark-blue.dropdown-toggle {
  color: #fff;
  background-color: #0a3153;
  border-color: #082a48;
}

.btn-dark-blue:not(:disabled):not(.disabled):active:focus, .btn-dark-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 103, 148, 0.5);
}

.btn-outline-primary {
  color: #337bb2;
  border-color: #337bb2;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #337bb2;
  border-color: #337bb2;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 123, 178, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #337bb2;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #337bb2;
  border-color: #337bb2;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 123, 178, 0.5);
}

.btn-outline-secondary {
  color: #2c3b41;
  border-color: #2c3b41;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #2c3b41;
  border-color: #2c3b41;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(44, 59, 65, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #2c3b41;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #2c3b41;
  border-color: #2c3b41;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(44, 59, 65, 0.5);
}

.btn-outline-success {
  color: #00a65a;
  border-color: #00a65a;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #00a65a;
  border-color: #00a65a;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 166, 90, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #00a65a;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #00a65a;
  border-color: #00a65a;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 166, 90, 0.5);
}

.btn-outline-info {
  color: #00c0ef;
  border-color: #00c0ef;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #00c0ef;
  border-color: #00c0ef;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 192, 239, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #00c0ef;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #00c0ef;
  border-color: #00c0ef;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 192, 239, 0.5);
}

.btn-outline-warning {
  color: #f39c12;
  border-color: #f39c12;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #f39c12;
  border-color: #f39c12;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 156, 18, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f39c12;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #f39c12;
  border-color: #f39c12;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 156, 18, 0.5);
}

.btn-outline-danger {
  color: #f56954;
  border-color: #f56954;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #f56954;
  border-color: #f56954;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 105, 84, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #f56954;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #f56954;
  border-color: #f56954;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 105, 84, 0.5);
}

.btn-outline-light {
  color: #F5F3F4;
  border-color: #F5F3F4;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #F5F3F4;
  border-color: #F5F3F4;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 243, 244, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #F5F3F4;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #F5F3F4;
  border-color: #F5F3F4;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 243, 244, 0.5);
}

.btn-outline-dark {
  color: #222d32;
  border-color: #222d32;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #222d32;
  border-color: #222d32;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 45, 50, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #222d32;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #222d32;
  border-color: #222d32;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 45, 50, 0.5);
}

.btn-outline-dark-green {
  color: #0D241C;
  border-color: #0D241C;
}

.btn-outline-dark-green:hover {
  color: #fff;
  background-color: #0D241C;
  border-color: #0D241C;
}

.btn-outline-dark-green:focus, .btn-outline-dark-green.focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 36, 28, 0.5);
}

.btn-outline-dark-green.disabled, .btn-outline-dark-green:disabled {
  color: #0D241C;
  background-color: transparent;
}

.btn-outline-dark-green:not(:disabled):not(.disabled):active, .btn-outline-dark-green:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark-green.dropdown-toggle {
  color: #fff;
  background-color: #0D241C;
  border-color: #0D241C;
}

.btn-outline-dark-green:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 36, 28, 0.5);
}

.btn-outline-light-green {
  color: #98ABA5;
  border-color: #98ABA5;
}

.btn-outline-light-green:hover {
  color: #212529;
  background-color: #98ABA5;
  border-color: #98ABA5;
}

.btn-outline-light-green:focus, .btn-outline-light-green.focus {
  box-shadow: 0 0 0 0.2rem rgba(152, 171, 165, 0.5);
}

.btn-outline-light-green.disabled, .btn-outline-light-green:disabled {
  color: #98ABA5;
  background-color: transparent;
}

.btn-outline-light-green:not(:disabled):not(.disabled):active, .btn-outline-light-green:not(:disabled):not(.disabled).active,
.show > .btn-outline-light-green.dropdown-toggle {
  color: #212529;
  background-color: #98ABA5;
  border-color: #98ABA5;
}

.btn-outline-light-green:not(:disabled):not(.disabled):active:focus, .btn-outline-light-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(152, 171, 165, 0.5);
}

.btn-outline-ash-gray {
  color: #C4C4C4;
  border-color: #C4C4C4;
}

.btn-outline-ash-gray:hover {
  color: #212529;
  background-color: #C4C4C4;
  border-color: #C4C4C4;
}

.btn-outline-ash-gray:focus, .btn-outline-ash-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 196, 196, 0.5);
}

.btn-outline-ash-gray.disabled, .btn-outline-ash-gray:disabled {
  color: #C4C4C4;
  background-color: transparent;
}

.btn-outline-ash-gray:not(:disabled):not(.disabled):active, .btn-outline-ash-gray:not(:disabled):not(.disabled).active,
.show > .btn-outline-ash-gray.dropdown-toggle {
  color: #212529;
  background-color: #C4C4C4;
  border-color: #C4C4C4;
}

.btn-outline-ash-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-ash-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-ash-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 196, 196, 0.5);
}

.btn-outline-light-brown {
  color: #C6B9A6;
  border-color: #C6B9A6;
}

.btn-outline-light-brown:hover {
  color: #212529;
  background-color: #C6B9A6;
  border-color: #C6B9A6;
}

.btn-outline-light-brown:focus, .btn-outline-light-brown.focus {
  box-shadow: 0 0 0 0.2rem rgba(198, 185, 166, 0.5);
}

.btn-outline-light-brown.disabled, .btn-outline-light-brown:disabled {
  color: #C6B9A6;
  background-color: transparent;
}

.btn-outline-light-brown:not(:disabled):not(.disabled):active, .btn-outline-light-brown:not(:disabled):not(.disabled).active,
.show > .btn-outline-light-brown.dropdown-toggle {
  color: #212529;
  background-color: #C6B9A6;
  border-color: #C6B9A6;
}

.btn-outline-light-brown:not(:disabled):not(.disabled):active:focus, .btn-outline-light-brown:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light-brown.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(198, 185, 166, 0.5);
}

.btn-outline-dark-blue {
  color: #0f4c81;
  border-color: #0f4c81;
}

.btn-outline-dark-blue:hover {
  color: #fff;
  background-color: #0f4c81;
  border-color: #0f4c81;
}

.btn-outline-dark-blue:focus, .btn-outline-dark-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(15, 76, 129, 0.5);
}

.btn-outline-dark-blue.disabled, .btn-outline-dark-blue:disabled {
  color: #0f4c81;
  background-color: transparent;
}

.btn-outline-dark-blue:not(:disabled):not(.disabled):active, .btn-outline-dark-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark-blue.dropdown-toggle {
  color: #fff;
  background-color: #0f4c81;
  border-color: #0f4c81;
}

.btn-outline-dark-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(15, 76, 129, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #337bb2;
  text-decoration: none;
}

.btn-link:hover {
  color: #225277;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #337bb2;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.7875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
  border-radius: 0;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.35rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.175rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #337bb2;
  background-color: #337bb2;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(51, 123, 178, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #88b8dd;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #afd0e8;
  border-color: #afd0e8;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.175rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.175rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #337bb2;
  background-color: #337bb2;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(51, 123, 178, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(51, 123, 178, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(51, 123, 178, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.175rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(51, 123, 178, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0;
  appearance: none;
}

.custom-select:focus {
  border-color: #88b8dd;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 123, 178, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.7875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.125rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #88b8dd;
  box-shadow: 0 0 0 0.2rem rgba(51, 123, 178, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0 0 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(51, 123, 178, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(51, 123, 178, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(51, 123, 178, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #337bb2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #afd0e8;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #337bb2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #afd0e8;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #337bb2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #afd0e8;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #337bb2;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.33125rem;
  padding-bottom: 0.33125rem;
  margin-right: 1rem;
  font-size: 1.125rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.1rem;
}

.card-title {
  margin-bottom: 0.6rem;
}

.card-subtitle {
  margin-top: -0.3rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.1rem;
}

.card-header {
  padding: 0.6rem 1.1rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-footer {
  padding: 0.6rem 1.1rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -0.55rem;
  margin-bottom: -0.6rem;
  margin-left: -0.55rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.55rem;
  margin-left: -0.55rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.6rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0;
}

.breadcrumb-item {
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #337bb2;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #225277;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 123, 178, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #337bb2;
  border-color: #337bb2;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #337bb2;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #28608a;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 123, 178, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #2c3b41;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #171f23;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(44, 59, 65, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #00a65a;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #00733e;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 166, 90, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #00c0ef;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #0097bc;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 192, 239, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #f39c12;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #c87f0a;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(243, 156, 18, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #f56954;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #f23f24;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 105, 84, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #F5F3F4;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #ded7db;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 243, 244, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #222d32;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #0d1214;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(34, 45, 50, 0.5);
}

.badge-dark-green {
  color: #fff;
  background-color: #0D241C;
}

a.badge-dark-green:hover, a.badge-dark-green:focus {
  color: #fff;
  background-color: black;
}

a.badge-dark-green:focus, a.badge-dark-green.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 36, 28, 0.5);
}

.badge-light-green {
  color: #212529;
  background-color: #98ABA5;
}

a.badge-light-green:hover, a.badge-light-green:focus {
  color: #212529;
  background-color: #7c948c;
}

a.badge-light-green:focus, a.badge-light-green.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(152, 171, 165, 0.5);
}

.badge-ash-gray {
  color: #212529;
  background-color: #C4C4C4;
}

a.badge-ash-gray:hover, a.badge-ash-gray:focus {
  color: #212529;
  background-color: #ababab;
}

a.badge-ash-gray:focus, a.badge-ash-gray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(196, 196, 196, 0.5);
}

.badge-light-brown {
  color: #212529;
  background-color: #C6B9A6;
}

a.badge-light-brown:hover, a.badge-light-brown:focus {
  color: #212529;
  background-color: #b2a187;
}

a.badge-light-brown:focus, a.badge-light-brown.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(198, 185, 166, 0.5);
}

.badge-dark-blue {
  color: #fff;
  background-color: #0f4c81;
}

a.badge-dark-blue:hover, a.badge-dark-blue:focus {
  color: #fff;
  background-color: #0a3153;
}

a.badge-dark-blue:focus, a.badge-dark-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(15, 76, 129, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 0 0 0 5px solid transparent;
  border-radius: 0;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.85rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #1b405d;
  background-color: #d6e5f0;
  border-color: #c6dae9;
}

.alert-primary hr {
  border-top-color: #b4cee2;
}

.alert-primary .alert-link {
  color: #102535;
}

.alert-secondary {
  color: #171f22;
  background-color: #d5d8d9;
  border-color: #c4c8ca;
}

.alert-secondary hr {
  border-top-color: #b7bbbe;
}

.alert-secondary .alert-link {
  color: #020304;
}

.alert-success {
  color: #00562f;
  background-color: #ccedde;
  border-color: #b8e6d1;
}

.alert-success hr {
  border-top-color: #a5dfc5;
}

.alert-success .alert-link {
  color: #002313;
}

.alert-info {
  color: #00647c;
  background-color: #ccf2fc;
  border-color: #b8edfb;
}

.alert-info hr {
  border-top-color: #a0e7fa;
}

.alert-info .alert-link {
  color: #003b49;
}

.alert-warning {
  color: #7e5109;
  background-color: #fdebd0;
  border-color: #fce3bd;
}

.alert-warning hr {
  border-top-color: #fbd9a5;
}

.alert-warning .alert-link {
  color: #4e3206;
}

.alert-danger {
  color: #7f372c;
  background-color: #fde1dd;
  border-color: #fcd5cf;
}

.alert-danger hr {
  border-top-color: #fbc0b7;
}

.alert-danger .alert-link {
  color: #59271f;
}

.alert-light {
  color: #7f7e7f;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}

.alert-light hr {
  border-top-color: #efefef;
}

.alert-light .alert-link {
  color: #656565;
}

.alert-dark {
  color: #12171a;
  background-color: #d3d5d6;
  border-color: #c1c4c6;
}

.alert-dark hr {
  border-top-color: #b4b7ba;
}

.alert-dark .alert-link {
  color: black;
}

.alert-dark-green {
  color: #07130f;
  background-color: #cfd3d2;
  border-color: #bbc2bf;
}

.alert-dark-green hr {
  border-top-color: #aeb6b2;
}

.alert-dark-green .alert-link {
  color: black;
}

.alert-light-green {
  color: #4f5956;
  background-color: #eaeeed;
  border-color: #e2e7e6;
}

.alert-light-green hr {
  border-top-color: #d4dbda;
}

.alert-light-green .alert-link {
  color: #373e3c;
}

.alert-ash-gray {
  color: #666666;
  background-color: #f3f3f3;
  border-color: #eeeeee;
}

.alert-ash-gray hr {
  border-top-color: #e1e1e1;
}

.alert-ash-gray .alert-link {
  color: #4d4d4d;
}

.alert-light-brown {
  color: #676056;
  background-color: #f4f1ed;
  border-color: #efebe6;
}

.alert-light-brown hr {
  border-top-color: #e5dfd6;
}

.alert-light-brown .alert-link {
  color: #4b463f;
}

.alert-dark-blue {
  color: #082843;
  background-color: #cfdbe6;
  border-color: #bccddc;
}

.alert-dark-blue hr {
  border-top-color: #abc1d3;
}

.alert-dark-blue .alert-link {
  color: #030d15;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.675rem;
  background-color: #e9ecef;
  border-radius: 0;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #337bb2;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #337bb2;
  border-color: #337bb2;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #1b405d;
  background-color: #c6dae9;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #1b405d;
  background-color: #b4cee2;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #1b405d;
  border-color: #1b405d;
}

.list-group-item-secondary {
  color: #171f22;
  background-color: #c4c8ca;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #171f22;
  background-color: #b7bbbe;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #171f22;
  border-color: #171f22;
}

.list-group-item-success {
  color: #00562f;
  background-color: #b8e6d1;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #00562f;
  background-color: #a5dfc5;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #00562f;
  border-color: #00562f;
}

.list-group-item-info {
  color: #00647c;
  background-color: #b8edfb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #00647c;
  background-color: #a0e7fa;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #00647c;
  border-color: #00647c;
}

.list-group-item-warning {
  color: #7e5109;
  background-color: #fce3bd;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7e5109;
  background-color: #fbd9a5;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7e5109;
  border-color: #7e5109;
}

.list-group-item-danger {
  color: #7f372c;
  background-color: #fcd5cf;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7f372c;
  background-color: #fbc0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7f372c;
  border-color: #7f372c;
}

.list-group-item-light {
  color: #7f7e7f;
  background-color: #fcfcfc;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #7f7e7f;
  background-color: #efefef;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #7f7e7f;
  border-color: #7f7e7f;
}

.list-group-item-dark {
  color: #12171a;
  background-color: #c1c4c6;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #12171a;
  background-color: #b4b7ba;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #12171a;
  border-color: #12171a;
}

.list-group-item-dark-green {
  color: #07130f;
  background-color: #bbc2bf;
}

.list-group-item-dark-green.list-group-item-action:hover, .list-group-item-dark-green.list-group-item-action:focus {
  color: #07130f;
  background-color: #aeb6b2;
}

.list-group-item-dark-green.list-group-item-action.active {
  color: #fff;
  background-color: #07130f;
  border-color: #07130f;
}

.list-group-item-light-green {
  color: #4f5956;
  background-color: #e2e7e6;
}

.list-group-item-light-green.list-group-item-action:hover, .list-group-item-light-green.list-group-item-action:focus {
  color: #4f5956;
  background-color: #d4dbda;
}

.list-group-item-light-green.list-group-item-action.active {
  color: #fff;
  background-color: #4f5956;
  border-color: #4f5956;
}

.list-group-item-ash-gray {
  color: #666666;
  background-color: #eeeeee;
}

.list-group-item-ash-gray.list-group-item-action:hover, .list-group-item-ash-gray.list-group-item-action:focus {
  color: #666666;
  background-color: #e1e1e1;
}

.list-group-item-ash-gray.list-group-item-action.active {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.list-group-item-light-brown {
  color: #676056;
  background-color: #efebe6;
}

.list-group-item-light-brown.list-group-item-action:hover, .list-group-item-light-brown.list-group-item-action:focus {
  color: #676056;
  background-color: #e5dfd6;
}

.list-group-item-light-brown.list-group-item-action.active {
  color: #fff;
  background-color: #676056;
  border-color: #676056;
}

.list-group-item-dark-blue {
  color: #082843;
  background-color: #bccddc;
}

.list-group-item-dark-blue.list-group-item-action:hover, .list-group-item-dark-blue.list-group-item-action:focus {
  color: #082843;
  background-color: #abc1d3;
}

.list-group-item-dark-blue.list-group-item-action.active {
  color: #fff;
  background-color: #082843;
  border-color: #082843;
}

.close {
  float: right;
  font-size: 1.35rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0 solid #dee2e6;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 0 solid #dee2e6;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Poppins, Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.7875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Poppins, Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.7875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #337bb2 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #28608a !important;
}

.bg-secondary {
  background-color: #2c3b41 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #171f23 !important;
}

.bg-success {
  background-color: #00a65a !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #00733e !important;
}

.bg-info {
  background-color: #00c0ef !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0097bc !important;
}

.bg-warning {
  background-color: #f39c12 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #c87f0a !important;
}

.bg-danger {
  background-color: #f56954 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #f23f24 !important;
}

.bg-light {
  background-color: #F5F3F4 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #ded7db !important;
}

.bg-dark {
  background-color: #222d32 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #0d1214 !important;
}

.bg-dark-green {
  background-color: #0D241C !important;
}

a.bg-dark-green:hover, a.bg-dark-green:focus,
button.bg-dark-green:hover,
button.bg-dark-green:focus {
  background-color: black !important;
}

.bg-light-green {
  background-color: #98ABA5 !important;
}

a.bg-light-green:hover, a.bg-light-green:focus,
button.bg-light-green:hover,
button.bg-light-green:focus {
  background-color: #7c948c !important;
}

.bg-ash-gray {
  background-color: #C4C4C4 !important;
}

a.bg-ash-gray:hover, a.bg-ash-gray:focus,
button.bg-ash-gray:hover,
button.bg-ash-gray:focus {
  background-color: #ababab !important;
}

.bg-light-brown {
  background-color: #C6B9A6 !important;
}

a.bg-light-brown:hover, a.bg-light-brown:focus,
button.bg-light-brown:hover,
button.bg-light-brown:focus {
  background-color: #b2a187 !important;
}

.bg-dark-blue {
  background-color: #0f4c81 !important;
}

a.bg-dark-blue:hover, a.bg-dark-blue:focus,
button.bg-dark-blue:hover,
button.bg-dark-blue:focus {
  background-color: #0a3153 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #337bb2 !important;
}

.border-secondary {
  border-color: #2c3b41 !important;
}

.border-success {
  border-color: #00a65a !important;
}

.border-info {
  border-color: #00c0ef !important;
}

.border-warning {
  border-color: #f39c12 !important;
}

.border-danger {
  border-color: #f56954 !important;
}

.border-light {
  border-color: #F5F3F4 !important;
}

.border-dark {
  border-color: #222d32 !important;
}

.border-dark-green {
  border-color: #0D241C !important;
}

.border-light-green {
  border-color: #98ABA5 !important;
}

.border-ash-gray {
  border-color: #C4C4C4 !important;
}

.border-light-brown {
  border-color: #C6B9A6 !important;
}

.border-dark-blue {
  border-color: #0f4c81 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0 !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-lg {
  border-radius: 0 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #337bb2 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #225277 !important;
}

.text-secondary {
  color: #2c3b41 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #0d1213 !important;
}

.text-success {
  color: #00a65a !important;
}

a.text-success:hover, a.text-success:focus {
  color: #005a31 !important;
}

.text-info {
  color: #00c0ef !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0083a3 !important;
}

.text-warning {
  color: #f39c12 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #b06f09 !important;
}

.text-danger {
  color: #f56954 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ef2b0e !important;
}

.text-light {
  color: #F5F3F4 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #d2c9ce !important;
}

.text-dark {
  color: #222d32 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #030404 !important;
}

.text-dark-green {
  color: #0D241C !important;
}

a.text-dark-green:hover, a.text-dark-green:focus {
  color: black !important;
}

.text-light-green {
  color: #98ABA5 !important;
}

a.text-light-green:hover, a.text-light-green:focus {
  color: #6f8880 !important;
}

.text-ash-gray {
  color: #C4C4C4 !important;
}

a.text-ash-gray:hover, a.text-ash-gray:focus {
  color: #9e9e9e !important;
}

.text-light-brown {
  color: #C6B9A6 !important;
}

a.text-light-brown:hover, a.text-light-brown:focus {
  color: #a89477 !important;
}

.text-dark-blue {
  color: #0f4c81 !important;
}

a.text-dark-blue:hover, a.text-dark-blue:focus {
  color: #07243c !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/*Table of Contents
01 body
02 Helper Classes
   02-1 short codes
03 navigation
   03-1 side menu
04 owl carousel
05 revolution slider
06 half-section
07 page-header
08 work process
09 our team
10 gallery portfolio
11 mobile apps & features
12 Counters
13 pricings
14 background parallax
15 Testimonials
16 Partner
17 our blog
   17-1 sidebar
   17-2 widgets
18 Ccntact US
19 404 error
20 Accordions
21 footer
22 preloader

Table Of Contents ends */
/*Global Styling*/
/*@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Raleway:200,300,400,500,600,700,800');*/
body {
  padding: 0;
  margin: 0;
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  color: #a5a5a5;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

body.full-page {
  overflow: hidden !important;
}

html {
  font-size: 16px;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a, a:hover, a:focus {
  outline: none;
  text-decoration: none;
  color: inherit;
}

input:focus {
  outline: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  font-size: 3.75rem;
}

h2 {
  font-size: 3.5rem;
}

h3 {
  font-size: 1.875rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

p {
  font-size: 14px;
}

p.title {
  font-size: 1rem;
}

.block {
  display: block;
}

.italic {
  font-style: italic;
}

button {
  line-height: 1;
}

/*common font family*/
p, .heading-title > span, .process-wrapp li > .pro-step,
.progress-bars .progress p, .progress-bars .progress .progress-bar span,
.price-table .ammount .dur, .pagination li > a, .counters .count_nums,
.price-table .ammount h2, .price-table ul li, .webcats li a > span,
.getin_form .form-control {
  font-family: 'Open Sans', sans-serif;
}

/*fontAwesome*/
.zeus.tparrows.tp-rightarrow::before,
.zeus.tparrows.tp-leftarrow::before,
.price-table ul li > span::before,
.testimonial-quote h3::before,
.testimonial-quote h3::after,
blockquote.blockquote::before {
  font-family: 'FontAwesome';
}

/* Helper Classes & Shorcodes */
.padding {
  padding: 7.5rem 0;
}

.padding_top {
  padding-top: 7.5rem;
}

.padding_bottom {
  padding-bottom: 7.5rem;
}

.margin_bottom {
  margin-bottom: 7.5rem;
}

.margin_top {
  margin-top: 7.5rem;
}

.padding_half {
  padding: 4.375rem 0;
}

.margin_half {
  margin: 4.375rem 0;
}

.padding_bottom_half {
  padding-bottom: 4.375rem;
}

.margin_bottom_half {
  margin-bottom: 4.375rem;
}

.padding_top_half {
  padding-top: 4.375rem;
}

.heading_space {
  margin-bottom: 4.125rem;
}

.bottom5 {
  margin-bottom: 5px;
}

.bottom10 {
  margin-bottom: 10px;
}

.top10 {
  margin-top: 10px;
}

.bottom15 {
  margin-bottom: 15px;
}

.top15 {
  margin-top: 15px;
}

.top20 {
  margin-top: 20px;
}

.bottom20 {
  margin-bottom: 20px;
}

.bottom25 {
  margin-bottom: 25px;
}

.top25 {
  margin-top: 25px;
}

.bottom30 {
  margin-bottom: 30px;
}

.top30 {
  margin-top: 30px;
}

.bottom35 {
  margin-bottom: 35px;
}

.top40 {
  margin-top: 40px;
}

.bottom40 {
  margin-bottom: 40px;
}

.bottom45 {
  margin-bottom: 45px;
}

.top50 {
  margin-top: 50px;
}

.top60 {
  margin-top: 60px;
}

.bottom60 {
  margin-bottom: 60px;
}

.bottom0 {
  margin-bottom: 0;
}

.nomargin {
  margin: 0;
}

.nopadding {
  padding: 0;
}

.fontbold {
  font-weight: bold;
}

.fontmedium {
  font-weight: 500;
}

.font-light {
  font-weight: 300;
}

.font-xlight {
  font-weight: 200;
}

.bglight {
  background: #f6f6f6;
}

.bgdefault {
  background: #00bcd4;
}

.whitecolor {
  color: #ffffff;
}

.darkcolor {
  color: #212331;
}

.extradark-color {
  color: #212331;
}

.defaultcolor {
  color: #00bcd4;
}

section {
  position: relative;
}

.container-padding {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.gradient_bg {
  background: -webkit-linear-gradient(90deg, #423f9c 31%, #862359 69%);
  background: -webkit-gradient(linear, left top, right top, color-stop(31%, #423f9c), color-stop(69%, #862359));
  background: -webkit-linear-gradient(left, #423f9c 31%, #862359 69%);
  background: -o-linear-gradient(left, #423f9c 31%, #862359 69%);
  background: linear-gradient(90deg, #423f9c 31%, #862359 69%);
}

.gradient_bg_default {
  background: -webkit-linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
  background: -webkit-gradient(linear, left top, right top, color-stop(31%, #00e4ce), color-stop(69%, #00abc9));
  background: -webkit-linear-gradient(left, #00e4ce 31%, #00abc9 69%);
  background: -o-linear-gradient(left, #00e4ce 31%, #00abc9 69%);
  background: linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
}

.gradient_bg_alt {
  background: -webkit-linear-gradient(90deg, #90A4AE 31%, #607D8B 69%);
  background: -webkit-gradient(linear, left top, right top, color-stop(31%, #90A4AE), color-stop(69%, #607D8B));
  background: -webkit-linear-gradient(left, #90A4AE 31%, #607D8B 69%);
  background: -o-linear-gradient(left, #90A4AE 31%, #607D8B 69%);
  background: linear-gradient(90deg, #90A4AE 31%, #607D8B 69%);
}

/*heading Titles */
.heading-title > span {
  font-size: 1.25rem;
  display: block;
  text-transform: capitalize;
  color: #00bcd4;
}

.whitecolor.heading-title > span {
  color: #fff;
}

.heading-title h2 {
  font-weight: 300;
}

/*img wrap*/
.image {
  overflow: hidden;
}

a.image {
  display: block;
}

.image img {
  width: 100%;
  display: block;
}

.image, .image img {
  position: relative;
}

/*hover on images*/
.hover-effect::before,
.hover-effect::after {
  content: "";
  background: #fff;
  height: 0;
  width: 0;
  z-index: 1;
  position: absolute;
  -webkit-transition-duration: 1.3s;
  -o-transition-duration: 1.3s;
  transition-duration: 1.3s;
}

.hover-effect::before {
  right: 0;
  opacity: 1;
  top: 0;
}

.hover-effect::after {
  bottom: 0;
  opacity: .7;
  left: 0;
}

.hover-effect:hover::after, .hover-effect:hover::before {
  height: 100%;
  opacity: 0;
  width: 100%;
}

/*to align elements center*/
.center-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*Back To Top*/
.back-top {
  color: #fff;
  right: 20px;
  font-size: 26px;
  position: fixed;
  z-index: 1600;
  opacity: 0;
  visibility: hidden;
  bottom: 70px;
  background: -webkit-linear-gradient(90deg, #423f9c, #862359);
  background: -webkit-gradient(linear, left top, right top, color-stop(#423f9c), color-stop(#862359));
  background: -webkit-linear-gradient(left, #423f9c, #862359);
  background: -o-linear-gradient(left, #423f9c, #862359);
  background: -webkit-gradient(linear, left top, right top, from(#423f9c), to(#862359));
  background: linear-gradient(90deg, #423f9c, #862359);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 36px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.back-top-visible {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.back-top::before {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  background: -webkit-linear-gradient(90deg, #643094, #00bcd4);
  background: -webkit-gradient(linear, left top, right top, color-stop(#643094), color-stop(#00bcd4));
  background: -webkit-linear-gradient(left, #643094, #00bcd4);
  background: -o-linear-gradient(left, #643094, #00bcd4);
  background: -webkit-gradient(linear, left top, right top, from(#643094), to(#00bcd4));
  background: linear-gradient(90deg, #643094, #00bcd4);
}

.back-top:hover::before, .back-top:focus::before {
  opacity: 1;
  visibility: visible;
}

.back-top:hover, .back-top:focus {
  color: #fff;
}

/* ----- Social Icons ----- */
ul.social-icons-simple li,
ul.social-icons li {
  display: inline-block;
}

ul.social-icons li a {
  height: 36px;
  line-height: 36px;
  width: 36px;
  font-size: 17px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin: 0 0.1rem;
  text-align: center;
  display: block;
  color: #676767;
  background: #00bcd4;
}

ul.social-icons.whitebg li a {
  background: #fff;
}

ul.social-icons-simple li a {
  height: 24px;
  line-height: 24px;
  width: 24px;
  margin: 0 8px;
  font-size: 18px;
  text-align: center;
  display: inline-block;
}

ul.social-icons.white li a,
ul.social-icons-simple.white li a {
  color: #fff;
}

ul.social-icons-simple li:last-child a,
ul.social-icons li:last-child a {
  margin-right: 0;
}

ul.social-icons-simple li:first-child a,
ul.social-icons li:first-child a {
  margin-left: 0;
}

ul.social-icons-simple li a:hover,
ul.social-icons-simple li a:focus {
  color: #00bcd4;
}

ul.social-icons li a:hover,
ul.social-icons li a:focus,
ul.social-icons.white li a:hover,
ul.social-icons.white li a:focus {
  color: #fff;
  -webkit-box-shadow: 0 0 15px 30px #00bcd4 inset;
  box-shadow: 0 0 15px 30px #00bcd4 inset;
}

/*-----Buttons-----*/
.button {
  position: relative;
  display: inline-block;
  font-size: 13px;
  padding: 1rem 2.5rem;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  z-index: 1;
  border: 1px solid transparent;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  overflow: hidden;
}

.square-layout .button {
  -webkit-border-radius: 0;
  border-radius: 0;
}

.btnprimary, .btnprimary-alt {
  background: #00bcd4;
}

.btnsecondary {
  background: #643094;
}

.btnwhite {
  background: #fff;
  color: #212331;
}

.btnwhite-hole {
  background: transparent;
  border: 1px solid #fff;
}

.btnwhite:hover, .btnwhite:focus {
  background: #00bcd4;
}

.btnwhite-hole:hover, .btnwhite-hole:focus {
  color: #212331;
  background: #fff;
}

.button > i {
  vertical-align: middle;
  margin-top: -3px;
}

.btnprimary:hover, .btnprimary:focus {
  background: #643094;
  border: 1px solid #643094;
}

.btnprimary-alt:hover, .btnprimary-alt:focus {
  background: transparent;
  border: 1px solid #00bcd4;
  color: #00bcd4;
}

.rev_slider .btnprimary-alt:hover, .rev_slider .btnprimary-alt:focus {
  background: transparent;
  border: 1px solid #fff;
}

.btnsecondary:hover, .btnsecondary:focus {
  background: #00bcd4;
  border: 1px solid #00bcd4;
}

.btnsecondary.hvrwhite:hover, .btnsecondary.hvrwhite:focus,
.btnprimary.hvrwhite:hover, .btnprimary.hvrwhite:focus {
  background: #fff;
  border: 1px solid #fff;
  color: #212331;
}

.btn-gradient-hvr::before {
  background: -webkit-linear-gradient(90deg, #423f9c, #862359);
  background: -webkit-gradient(linear, left top, right top, color-stop(#423f9c), color-stop(#862359));
  background: -webkit-linear-gradient(left, #423f9c, #862359);
  background: -o-linear-gradient(left, #423f9c, #862359);
  background: -webkit-gradient(linear, left top, right top, from(#423f9c), to(#862359));
  background: linear-gradient(90deg, #423f9c, #862359);
}

.btn-gradient-hvr::before,
.scndry-gradient-hvr::before {
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

.scndry-gradient-hvr::before {
  background: -webkit-linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
  background: -webkit-gradient(linear, left top, right top, color-stop(31%, #00e4ce), color-stop(69%, #00abc9));
  background: -webkit-linear-gradient(left, #00e4ce 31%, #00abc9 69%);
  background: -o-linear-gradient(left, #00e4ce 31%, #00abc9 69%);
  background: linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
}

.btn-gradient-hvr:hover::before,
.btn-gradient-hvr:focus::before,
.scndry-gradient-hvr:hover::before,
.scndry-gradient-hvr:focus::before {
  opacity: 1;
  visibility: visible;
}

.btn-gradient-hvr:hover, .btn-gradient-hvr:focus {
  border-color: #643094;
}

.scndry-gradient-hvr:hover, .scndry-gradient-hvr:focus {
  border-color: #00bcd4;
}

.btnwhite-hole, .btnwhite:hover, .btnwhite:focus,
.btnprimary, .btnsecondary, .btnprimary-alt,
.btnprimary:hover, .btnprimary:focus,
.btnsecondary:hover, .btnsecondary:focus,
.rev_slider .btnprimary-alt:hover, .rev_slider .btnprimary-alt:focus {
  color: #fff;
}

/* transitions common*/
a, *::before, *::after, img, span, input, button,
.navbar, .fixedmenu, .tp-bullet, .owl-dot, .owl-prev, .owl-next {
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

i::before, i::after {
  -webkit-transition: all 0s !important;
  -o-transition: all 0s !important;
  transition: all 0s !important;
}

/*-------------------------------*/
/*Navigation Starts */
/*-------------------------------*/
.navbar {
  padding-bottom: 0;
  padding-top: 0;
  z-index: 1000;
}

.center-brand {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.center-brand, .transparent-bg {
  left: 0;
  top: 0;
}

.center-brand, .transparent-bg,
.fixed-bottom, .bg-transparent-light,
.bg-transparent-white,
.bg-invisible, .boxed-nav {
  position: absolute;
  width: 100%;
}

.bg-invisible .container {
  opacity: 0;
  visibility: hidden;
}

.fixed-bottom {
  bottom: 0;
}

.bg-white {
  position: relative;
}

.bg-white,
.boxed-nav .container {
  background: #fff;
}

.boxed-nav {
  top: 20px;
}

.fixedmenu {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
  z-index: 1002;
  -webkit-animation-name: animationFade;
  -o-animation-name: animationFade;
  animation-name: animationFade;
  -webkit-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
  background: -webkit-linear-gradient(90deg, #423f9c 31%, #862359 69%);
  background: -webkit-gradient(linear, left top, right top, color-stop(31%, #423f9c), color-stop(69%, #862359));
  background: -webkit-linear-gradient(left, #423f9c 31%, #862359 69%);
  background: -o-linear-gradient(left, #423f9c 31%, #862359 69%);
  background: linear-gradient(90deg, #423f9c 31%, #862359 69%);
}

.center-brand.fixedmenu,
.bg-white.fixedmenu,
.bg-transparent-light.fixedmenu,
.bg-transparent-white.fixedmenu,
.bg-invisible.fixedmenu,
.boxed-nav.fixedmenu {
  background: #fff;
}

.fixed-bottom.fixedmenu {
  bottom: auto;
  -webkit-animation: none;
  -o-animation: none;
  animation: none;
}

.bg-invisible.fixedmenu .container {
  opacity: 1;
  visibility: visible;
}

@-webkit-keyframes animationFade {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-o-keyframes animationFade {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    -o-transform: none;
    transform: none;
  }
}

@keyframes animationFade {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    -o-transform: none;
    transform: none;
  }
}

.nav-whitebg {
  background: #fff;
  -webkit-transition: all .9s ease;
  -o-transition: all .9s ease;
  transition: all .9s ease;
}

.navbar.sidebar-nav {
  background: #fff;
  border: none;
  display: inline-table;
  width: 290px;
  -webkit-border-radius: 0;
  border-radius: 0;
  position: fixed;
  top: 0;
  z-index: 110;
  height: 100%;
  padding: 3rem 2rem;
  border-right: 1px solid rgba(0, 0, 0, 0.07);
  left: 0;
  overflow-x: hidden;
}

.navbar-brand {
  margin: 16px 0;
  padding: 0;
  width: 140px;
  position: relative;
}

.center-brand .navbar-brand {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0;
}

.navbar-brand > img {
  width: 100%;
}

.navbar.fixedmenu .logo-default,
.navbar .logo-scrolled {
  display: none;
}

.navbar.fixedmenu .logo-scrolled,
.navbar .logo-default {
  display: inline-block;
}

.navbar.sidebar-nav .navbar-nav {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.navbar-nav .nav-item {
  margin: 16px 5px;
  position: relative;
}

.navbar.sidebar-nav .navbar-nav .nav-item {
  margin: 10px 0;
}

.navbar-nav .nav-item:first-child {
  margin-left: 0;
}

.navbar-nav .nav-item:last-child {
  margin-right: 0;
}

.navbar-nav .nav-link {
  padding: 8px 1.25rem !important;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  color: #212331;
  position: relative;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  overflow: hidden;
}

.sidebar-nav .navbar-nav .nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.square-layout .navbar-nav .nav-link {
  -webkit-border-radius: 0;
  border-radius: 0;
  border: 1px solid transparent;
}

.center-brand .navbar-nav .nav-link,
.transparent-bg .navbar-nav .nav-link,
.fixed-bottom .navbar-nav .nav-link {
  color: #fff;
}

.center-brand.fixedmenu .navbar-nav .nav-link {
  color: #212331;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus,
.sidebar-nav .navbar-nav .nav-link.active {
  color: #00bcd4;
}

.fixedmenu .navbar-nav .nav-link.active,
.fixedmenu .navbar-nav .nav-link.active:hover,
.fixedmenu .navbar-nav .nav-link.active:focus,
.center-brand.fixedmenu .navbar-nav .nav-link.active {
  background: #00bcd4;
  color: #fff;
}

.square-layout .navbar-nav .nav-link.active,
.square-layout .navbar-nav .nav-link:hover,
.square-layout .navbar-nav .nav-link:focus {
  border: 1px solid #c1c1c1;
  background: transparent !important;
}

.square-layout .fixedmenu .navbar-nav .nav-link.active,
.square-layout .fixedmenu .navbar-nav .nav-link:hover,
.square-layout .fixedmenu .navbar-nav .nav-link:focus {
  border: 1px solid #c1c1c1;
  background: transparent !important;
  color: #212331 !important;
}

.center-brand .navbar-nav .nav-link:first-of-type {
  background: rgba(0, 0, 0, 0);
}

/*toggle responsive*/
.navbar-toggler {
  position: relative;
  border: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0;
}

.navbar-toggler span {
  display: block;
  background: #fff;
  height: 2px;
  width: 25px;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  position: relative;
  left: 0;
  opacity: 1;
}

.bg-white .navbar-toggler span,
.bg-transparent-light .navbar-toggler span,
.bg-invisible .navbar-toggler span,
.navbar.sidebar-nav .navbar-toggler span,
.boxed-nav .navbar-toggler span {
  background: #212331;
}

.center-brand.fixedmenu .navbar-toggler span {
  background: -webkit-linear-gradient(90deg, #643094, #00bcd4);
  background: -webkit-gradient(linear, left top, right top, color-stop(#643094), color-stop(#00bcd4));
  background: -webkit-linear-gradient(left, #643094, #00bcd4);
  background: -o-linear-gradient(left, #643094, #00bcd4);
  background: -webkit-gradient(linear, left top, right top, from(#643094), to(#00bcd4));
  background: linear-gradient(90deg, #643094, #00bcd4);
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  -webkit-transition: transform .35s ease-in-out;
  -o-transition: transform .35s ease-in-out;
  -webkit-transition: -webkit-transform .35s ease-in-out;
  transition: -webkit-transform .35s ease-in-out;
  -o-transition: -o-transform .35s ease-in-out;
  transition: transform .35s ease-in-out;
  transition: transform .35s ease-in-out, -webkit-transform .35s ease-in-out, -o-transform .35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 3px;
  visibility: hidden;
  background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
  opacity: 0.9;
}

/*-----Side Menu----*/
.side-menu {
  width: 40%;
  position: fixed;
  right: 0;
  top: 0;
  background: #00bcd4;
  z-index: 1032;
  height: 100%;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform .5s ease;
  transition: -webkit-transform .5s ease;
  -o-transition: -o-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease, -o-transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease;
  overflow: hidden;
}

.side-menu.left {
  left: 0;
  right: auto;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.side-menu.before-side {
  width: 280px;
}

.just-sidemenu .side-menu {
  background: -webkit-linear-gradient(90deg, #423f9c, #862359);
  background: -webkit-gradient(linear, left top, right top, color-stop(#423f9c), color-stop(#862359));
  background: -webkit-linear-gradient(left, #423f9c, #862359);
  background: -o-linear-gradient(left, #423f9c, #862359);
  background: -webkit-gradient(linear, left top, right top, from(#423f9c), to(#862359));
  background: linear-gradient(90deg, #423f9c, #862359);
}

.transparent-sidemenu .side-menu {
  background: rgba(0, 0, 0, 0.95);
}

.side-menu.side-menu-active,
.side-menu.before-side {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.pul-menu .side-menu.side-menu-active {
  visibility: visible;
  opacity: 1;
}

.side-menu .navbar-brand {
  margin: 0 0 2.5rem 0;
}

/*Side overlay*/
#close_side_menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  -webkit-transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  -o-transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  display: none;
  z-index: 1031;
  opacity: 0.4;
}

/*side clode btn*/
.side-menu .btn-close {
  height: 33px;
  width: 33px;
  -webkit-box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 30px;
  right: 15px;
  cursor: pointer;
}

.side-menu.before-side .btn-close {
  display: none;
}

.just-sidemenu #sidemenu_toggle {
  position: fixed;
  z-index: 999;
  /*background: #fff;*/
  right: 0;
  top: 16px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.side-menu .btn-close::before, .side-menu .btn-close::after {
  position: absolute;
  left: 16px;
  content: ' ';
  height: 24px;
  width: 2px;
  background: #fff;
  top: 5px;
}

.side-menu .btn-close:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.side-menu .btn-close:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/*side open btn*/
.sidemenu_btn {
  -webkit-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
  width: 36px;
  padding: 6px;
  margin-right: 1rem;
  position: absolute;
  right: 15px;
}

.sidemenu_btn.left {
  left: 15px;
  right: auto;
}

.sidemenu_btn > span {
  height: 2px;
  width: 100%;
  background: #212331;
  display: block;
}

.center-brand .sidemenu_btn > span,
.transparent-bg .sidemenu_btn > span,
.fixed-bottom .sidemenu_btn > span,
.just-sidemenu .toggle_white.sidemenu_btn > span,
.bg-invisible .sidemenu_btn > span,
.boxed-nav .sidemenu_btn > span {
  background: #fff;
}

.center-brand.fixedmenu .sidemenu_btn > span,
.bg-invisible.fixedmenu .sidemenu_btn > span,
.boxed-nav.fixedmenu .sidemenu_btn > span {
  background: #212331;
}

.sidemenu_btn > span:nth-child(2) {
  margin: 4px 0;
}

.side-menu .inner-wrapper {
  padding: 3.5rem 5rem;
  height: 100%;
  position: relative;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pul-menu.pushwrap .side-menu .inner-wrapper {
  padding: 3.5rem 2.5rem;
}

.side-menu .side-nav {
  margin-bottom: 30px;
  display: block;
}

.side-nav .navbar-nav .nav-item {
  display: block;
  margin: 10px 0;
  padding: 0 !important;
  opacity: 0;
  -webkit-transition: all 0.8s ease 500ms;
  -o-transition: all 0.8s ease 500ms;
  transition: all 0.8s ease 500ms;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
}

.side-nav .navbar-nav .nav-item:first-child {
  -webkit-transition-delay: .1s;
  -o-transition-delay: .1s;
  transition-delay: .1s;
}

.side-nav .navbar-nav .nav-item:nth-child(2) {
  -webkit-transition-delay: .2s;
  -o-transition-delay: .2s;
  transition-delay: .2s;
}

.side-nav .navbar-nav .nav-item:nth-child(3) {
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s;
}

.side-nav .navbar-nav .nav-item:nth-child(4) {
  -webkit-transition-delay: .4s;
  -o-transition-delay: .4s;
  transition-delay: .4s;
}

.side-nav .navbar-nav .nav-item:nth-child(5) {
  -webkit-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s;
}

.side-nav .navbar-nav .nav-item:nth-child(6) {
  -webkit-transition-delay: .6s;
  -o-transition-delay: .6s;
  transition-delay: .6s;
}

.side-nav .navbar-nav .nav-item:nth-child(7) {
  -webkit-transition-delay: .7s;
  -o-transition-delay: .7s;
  transition-delay: .7s;
}

.side-nav .navbar-nav .nav-item:nth-child(8) {
  -webkit-transition-delay: .8s;
  -o-transition-delay: .8s;
  transition-delay: .8s;
}

.side-nav .navbar-nav .nav-item:nth-child(9) {
  -webkit-transition-delay: .9s;
  -o-transition-delay: .9s;
  transition-delay: .9s;
}

.side-menu.side-menu-active .side-nav .navbar-nav .nav-item {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.side-nav .navbar-nav .nav-link {
  display: inline-table;
  color: #fff;
  padding: 2px 0 3px 0 !important;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: normal;
  position: relative;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.side-nav .navbar-nav .nav-link::after {
  content: "";
  position: absolute;
  background: #fff;
  display: inline-block;
  width: 0;
  height: 3px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
  -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
}

.transparent-sidemenu .side-nav .navbar-nav .nav-link::after {
  display: none;
}

.side-nav .navbar-nav .nav-link:hover::after,
.side-nav .navbar-nav .nav-link:focus::after,
.side-nav .navbar-nav .nav-link.active::after {
  width: 100%;
}

.side-nav .navbar-nav .nav-link.active {
  background: transparent;
}

.transparent-sidemenu .side-nav .navbar-nav .nav-link:hover,
.transparent-sidemenu .side-nav .navbar-nav .nav-link:focus {
  margin-left: 5px;
}

.side-menu p {
  font-size: 13px;
  margin-top: .5rem;
  margin-bottom: 0;
}

/*Header Social Icons*/
.navbar .social-icons li a {
  height: 28px;
  width: 28px;
  line-height: 28px;
  font-size: 14px;
}

.sidebar-nav .social-icons {
  position: absolute;
  bottom: 30px;
}

.side-menu .social-icons-simple li a:hover,
.side-menu .social-icons-simple li a:focus {
  color: #fff;
}

/*wrapper on opened sidebar*/
.wrapper {
  padding-left: 290px;
}

.wrapper .full-screen {
  width: 100% !important;
}

@media (max-width: 1366px) {
  .center-brand .sidemenu_btn {
    right: 0;
  }
}

@media (max-width: 1200px) {
  .side-menu .inner-wrapper {
    padding: 2rem 3.5rem;
  }
}

@media (min-width: 1025px) and (max-width: 1091px) {
  .navbar-nav.ml-auto {
    margin-right: 30px;
  }
  .sidemenu_btn {
    right: 0;
  }
}

@media (max-width: 1024px) {
  .boxed-nav .sidemenu_btn {
    display: none !important;
  }
  .center-brand .navbar-brand {
    width: 100px;
  }
  .navbar-nav .nav-link {
    font-size: 13px;
  }
}

@media (min-width: 810px) and (max-width: 1024px) {
  .sidemenu_btn {
    position: relative;
  }
}

@media (max-width: 992px) {
  .boxed-nav {
    background: #fff;
  }
  .center-brand .navbar-brand {
    position: relative;
    top: auto;
    margin: 16px 0;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  .navbar-nav .nav-item {
    margin: 5px 0;
  }
  .center-brand .navbar-nav .nav-link,
  .transparent-bg .navbar-nav .nav-link {
    background: #00bcd4;
    color: #fff;
  }
  .center-brand.fixedmenu .navbar-nav .nav-link,
  .transparent-bg.fixedmenu .navbar-nav .nav-link {
    background: transparent;
  }
  .side-menu {
    width: 50%;
  }
  .side-menu .inner-wrapper {
    padding: 2rem 2.5rem;
  }
  .side-nav .navbar-nav .nav-link {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .center-brand .navbar-brand {
    width: 100px;
  }
  .side-menu {
    width: 55%;
  }
  .side-nav .navbar-nav .nav-link {
    font-size: 1.5rem;
  }
}

@media (max-width: 767px) {
  .navbar.sidebar-nav {
    width: 100%;
    height: auto;
    padding: 0 1rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .wrapper {
    padding-left: 0;
  }
  .sidemenu_btn {
    margin-top: 4px;
    right: 0;
    position: absolute;
  }
  .side-menu {
    width: 300px;
  }
  .side-nav .navbar-nav .nav-item {
    margin: 5px 0;
  }
  .side-nav p {
    display: none;
  }
  .sidebar-nav .social-icons {
    display: none;
  }
}

/*-------------------------------*/
/*Navigation Ends */
/*-------------------------------*/
/*-------------------------------*/
/*OWl Slider*/
/*-------------------------------*/
/*Dots*/
.owl-dots {
  margin-top: 2rem;
  text-align: center;
}

.vertical-dot .owl-dots {
  display: inline-table;
  width: 24px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 40px;
}

.owl-dots .owl-dot {
  height: 12px;
  width: 12px;
  background: transparent;
  margin: 2px 3px;
  position: relative;
  border: 1px solid #00bcd4;
  display: inline-block;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.35);
  cursor: pointer;
}

#text-fading .owl-dots .owl-dot {
  background: #fff;
  border: none;
}

s .vertical-dot .owl-dots .owl-dot {
  border: none;
  background: rgba(255, 255, 255, 0.5);
}

.owl-dots .owl-dot::after {
  background-color: #00bcd4;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  visibility: hidden;
  width: 100%;
}

.vertical-dot .owl-dots .owl-dot::after {
  display: none;
}

.vertical-dot .owl-dots .owl-dot.active {
  height: 15px;
  width: 15px;
  background: #fff;
}

.owl-dots .owl-dot.active::after,
.owl-dots .owl-dot:hover:after {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  visibility: visible;
}

.animate-out {
  -webkit-animation-delay: 0ms;
  -o-animation-delay: 0ms;
  animation-delay: 0ms;
}

/*Buttons*/
.owl-nav .owl-prev {
  left: 5px;
}

.owl-nav .owl-next {
  right: 5px;
}

.owl-nav .owl-prev, .owl-nav .owl-next {
  background: #00bcd4;
  font-size: 14px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  position: absolute;
  top: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  opacity: 0;
  visibility: hidden;
}

.owl-carousel:hover .owl-nav .owl-prev,
.owl-carousel:hover .owl-nav .owl-next {
  opacity: 1;
  visibility: visible;
}

.owl-nav .owl-prev:hover, .owl-nav .owl-next:hover,
.owl-nav .owl-prev:focus, .owl-nav .owl-next:focus {
  background: #643094;
}

/*Service Slider*/
#services-slider {
  margin: -140px 0 0;
  min-height: 350px;
  position: relative;
  z-index: 21;
}

#services-slider.owl-carousel .owl-stage-outer {
  padding-top: 25px;
  padding-bottom: 25px;
}

#services-slider .service-box {
  position: relative;
  padding: 3rem 1rem;
  background: #00bcd4;
  color: #fff;
  text-align: center;
  -webkit-transition: all .5s ease .1s;
  -o-transition: all .5s ease .1s;
  transition: all .5s ease .1s;
  z-index: 2;
  overflow: hidden;
}

#services-slider .service-box::before {
  content: "";
  top: -100px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  background: -webkit-linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
  background: -webkit-gradient(linear, left top, right top, color-stop(31%, #00e4ce), color-stop(69%, #00abc9));
  background: -webkit-linear-gradient(left, #00e4ce 31%, #00abc9 69%);
  background: -o-linear-gradient(left, #00e4ce 31%, #00abc9 69%);
  background: linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
}

#services-slider .owl-item.center .service-box::before {
  background: -webkit-linear-gradient(90deg, #423f9c 31%, #862359 69%);
  background: -webkit-gradient(linear, left top, right top, color-stop(31%, #423f9c), color-stop(69%, #862359));
  background: -webkit-linear-gradient(left, #423f9c 31%, #862359 69%);
  background: -o-linear-gradient(left, #423f9c 31%, #862359 69%);
  background: linear-gradient(90deg, #423f9c 31%, #862359 69%);
}

#services-slider .service-box > span {
  display: inline-block;
  font-size: 32px;
  height: 50px;
  width: 50px;
  overflow: hidden;
}

#services-slider .service-box > span > i {
  -webkit-transition: all .9s ease .2s;
  -o-transition: all .9s ease .2s;
  transition: all .9s ease .2s;
}

#services-slider .service-box h4 {
  font-weight: bold;
}

#services-slider .service-box:hover span {
  font-size: 38px;
}

#services-slider .owl-item.center .service-box {
  background: #643094;
  padding: 4.5rem 1rem;
  margin-top: -25px;
}

#services-slider .service-box:hover::before {
  opacity: 1;
  visibility: visible;
  top: 0;
}

@media screen and (max-width: 768px) {
  #services-slider {
    margin: -70px 0 0;
  }
}

/*-------------------------------*/
/*OWL Slider ends*/
/*-------------------------------*/
/*-------------------------------*/
/*Revolution SLider*/
/*-------------------------------*/
#main-banner .fontbold {
  font-weight: 800;
}

.tp-bullet {
  height: 15px;
  width: 15px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
}

.tp-bullet:hover, .tp-bullet:focus {
  background: rgba(255, 255, 255, 0.6);
}

.tp-bullet::before {
  content: "";
  height: 100%;
  width: 100%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  opacity: 0;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.tp-bullet.selected::before {
  opacity: 1;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.uranus .tp-bullet-inner {
  background: #00bcd4;
}

.uranus .tp-bullet::before {
  display: none;
}

.uranus .tp-bullet.selected, .uranus .tp-bullet:hover {
  -webkit-box-shadow: 0 0 0 2px #643094;
  box-shadow: 0 0 0 2px #643094;
}

.uranus .tp-bullet.selected .tp-bullet-inner,
.uranus .tp-bullet:hover .tp-bullet-inner {
  background: #643094;
}

/*
FIXME WEBPACK LOADER PROBLEM
.tp-carousel-wrapper {
    cursor: url('../images/openhand.cur'), move;
}
*/
.rev_slider li.rev_gradient::after {
  background: -webkit-linear-gradient(90deg, #48459f, #8a2a5d);
  background: -webkit-gradient(linear, left top, right top, color-stop(#48459f), color-stop(#8a2a5d));
  background: -webkit-linear-gradient(left, #48459f, #8a2a5d);
  background: -o-linear-gradient(left, #48459f, #8a2a5d);
  background: -webkit-gradient(linear, left top, right top, from(#48459f), to(#8a2a5d));
  background: linear-gradient(90deg, #48459f, #8a2a5d);
}

.rev_slider li.rev_gradient::after,
.banner-overlay::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.banner-overlay::after {
  background: rgba(0, 0, 0, 0.39);
}

.zeus.tparrows.tp-rightarrow::before {
  content: "\f105";
}

.zeus.tparrows.tp-leftarrow::before {
  content: "\f104";
}

/*
@media (max-width: 767px) {
   .rev_slider .button {
	display: inline-block !important;
      left: 50% !important;
      -webkit-transform: translateX(-50%) !important;
      -ms-transform: translateX(-50%) !important;
      -o-transform: translateX(-50%) !important;
      transform: translateX(-50%) !important;
   }
}*/
/*-------------------------------*/
/* Main Banner Ends*/
/*-------------------------------*/
/*-------------------------------*/
/* Horizontal Half Blocks*/
/*-------------------------------*/
.half-section {
  width: 100%;
}

.half-section,
.half-section .img-container {
  position: relative;
  overflow: hidden;
}

.half-section .imgone {
  background: url("../images/split-img1.jpg") no-repeat;
}

.half-section .imgtwo {
  background: url("../images/split-img2.jpg") no-repeat;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .split-box .padding {
    padding: 3rem 0;
  }
}

@media (max-width: 992px) {
  .split-box {
    padding-left: 15px;
    padding-right: 15px;
  }
  .half-section .img-container {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) {
  #twocopies .half-section:first-of-type .container-fluid, #twocopies .half-section:first-of-type .container-sm, #twocopies .half-section:first-of-type .container-md, #twocopies .half-section:first-of-type .container-lg, #twocopies .half-section:first-of-type .container-xl {
    border-bottom: 1px solid #ececec;
  }
}

/*-------------------------------*/
/*Horizontal Half Blocks ends*/
/*-------------------------------*/
/*-------------------------------*/
/*Page Headers */
/*-------------------------------*/
.page-header {
  background: url(../images/page-header.jpg) no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
}

.page-header .page-titles {
  position: relative;
  margin-top: 5rem;
  text-transform: capitalize;
}

.breadcrumb {
  background: transparent;
  padding: 0;
}

.breadcrumb .breadcrumb-item {
  color: #fff;
  font-size: 14px;
}

.breadcrumb .breadcrumb-item:hover,
.breadcrumb .breadcrumb-item:focus,
.breadcrumb .breadcrumb-item.active {
  color: #00bcd4;
}

#particles canvas {
  position: absolute;
}

/*-------------------------------*/
/*Page Headers */
/*-------------------------------*/
/*-------------------------------*/
/* Work Process */
/*-------------------------------*/
.process-wrapp {
  overflow: hidden;
  display: table;
  table-layout: fixed;
  margin: 0 15px;
}

.process-wrapp, .process-wrapp li,
.process-wrapp li > .pro-step {
  position: relative;
  z-index: 1;
}

.process-wrapp li {
  text-align: center;
  padding: 70px  15px 0 15px;
  display: table-cell;
}

.process-wrapp li:hover {
  cursor: pointer;
}

.process-wrapp li > .pro-step {
  display: inline-block;
  height: 90px;
  width: 90px;
  line-height: 90px;
  border: 1px solid #fff;
  font-size: 28px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.process-wrapp li p:first-child {
  font-size: 15px;
}

.process-wrapp li p:last-child {
  font-size: 13px;
}

.process-wrapp li:hover > .pro-step {
  -webkit-box-shadow: 0 0 25px 50px #fff inset;
  box-shadow: 0 0 25px 50px #fff inset;
  color: #00bcd4;
}

.process-wrapp li::before, .process-wrapp li::after {
  content: "";
  height: 1px;
  top: 115px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  width: 50%;
  z-index: -1;
  margin: 0 -45px;
}

.process-wrapp li:first-child::before,
.process-wrapp li:last-child::after {
  opacity: 0;
  visibility: hidden;
}

.process-wrapp li::before {
  left: 0;
}

.process-wrapp li::after {
  right: 0;
}

/*process with box */
.process-number {
  background: #fff;
  padding: 0 1rem 2.5rem 1rem;
  position: relative;
  text-align: center;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.process-number,
.process-number .pro-step {
  -webkit-transition: all .5s linear;
  -o-transition: all .5s linear;
  transition: all .5s linear;
}

.process-number:hover {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.process-number .pro-step {
  font-weight: bold;
  font-size: 1.75rem;
  font-family: 'Open Sans', sans-serif;
  margin-right: 5px;
  color: #fff;
  line-height: 1;
  background: #00bcd4;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  margin-top: -50px;
}

.process-number .pro-step::after {
  content: "";
  height: 5px;
  width: 5px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  background: #fff;
  margin-left: 3px;
}

.process-number.even .pro-step {
  background: #643094;
}

/*Process icoin inside*/
.process-number.iconic {
  padding: 2.5rem 1rem;
}

.process-number.iconic > span {
  height: 90px;
  width: 90px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  line-height: 90px;
  display: inline-block;
  text-align: center;
  font-size: 48px;
  background: #00bcd4;
  color: #fff;
}

.process-number.iconic:hover > span {
  -webkit-box-shadow: 0 0 20px 45px #643094 inset;
  box-shadow: 0 0 20px 45px #643094 inset;
}

@media screen and (max-width: 992px) {
  .process-wrapp {
    text-align: center;
  }
  .process-wrapp li {
    display: inline-block;
    max-width: 300px;
  }
  .process-wrapp li:first-child::before,
  .process-wrapp li:last-child::after {
    opacity: 1;
    visibility: visible;
  }
}

/*-------------------------------*/
/*Work Process ends */
/*-------------------------------*/
/*-------------------------------*/
/* Our Team */
/*-------------------------------*/
.team-box,
.team-box .team-content {
  -webkit-transition: all .5s ease-in .1s;
  -o-transition: all .5s ease-in .1s;
  transition: all .5s ease-in .1s;
}

.team-box {
  position: relative;
  padding: 20px 20px 100px 20px;
  overflow: hidden;
}

.team-box.grey-shade {
  padding: 0;
  background: #f6f6f6;
}

.team-box.no-hover {
  padding: 0;
  overflow: inherit;
}

.team-box::before {
  content: "";
  border: 20px solid #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 50px;
}

.team-box.grey-shade::before,
.team-box.no-hover::before {
  display: none;
}

.team-box.grey-shade img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.team-box.grey-shade:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.team-box .team-content {
  padding: 30px 1.25rem;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  left: 20px;
  right: 20px;
  -webkit-transform: translateY(53%);
  -ms-transform: translateY(53%);
  -o-transform: translateY(53%);
  transform: translateY(53%);
  text-align: center;
}

.team-box.single .team-content {
  -webkit-transform: translateY(38%);
  -ms-transform: translateY(38%);
  -o-transform: translateY(38%);
  transform: translateY(38%);
}

.team-box.no-hover .team-content {
  -webkit-box-shadow: 0px 10px 5px -10px #b7b7b7;
  box-shadow: 0px 10px 5px -10px #b7b7b7;
  background: #fff;
}

.team-box.no-hover .team-content,
.team-box.grey-shade .team-content {
  -webkit-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  position: relative;
  left: 0;
  right: 0;
}

.team-box .team-content h3 {
  font-weight: normal;
  font-size: 1.75rem;
}

.team-box.no-hover .team-content h3 {
  color: #212331;
}

.team-box .team-content p {
  font-size: 12px;
}

.team-box.no-hover .team-content p {
  font-size: 12px;
  margin-bottom: 10px;
}

.team-box:hover .team-content,
.team-box.single:hover .team-content {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.team-box.no-hover .team-content::before {
  content: "";
  height: 100%;
  width: 100%;
  background: -webkit-linear-gradient(90deg, #423f9c, #862359);
  background: -webkit-gradient(linear, left top, right top, color-stop(#423f9c), color-stop(#862359));
  background: -webkit-linear-gradient(left, #423f9c, #862359);
  background: -o-linear-gradient(left, #423f9c, #862359);
  background: -webkit-gradient(linear, left top, right top, from(#423f9c), to(#862359));
  background: linear-gradient(90deg, #423f9c, #862359);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.team-box.no-hover:hover .team-content::before {
  opacity: 1;
  visibility: visible;
}

.team-box.no-hover:hover .team-content::before,
.team-box.no-hover:hover .team-content,
.team-box.no-hover:hover .team-content h3,
.team-box.no-hover:hover .team-content ul.social-icons li a {
  color: #fff;
}

.team-box .overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.team-box:hover .overlay {
  opacity: 1;
  visibility: visible;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.team-box ul.social-icons li a {
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 14px;
}

/*Progress Bars*/
.progress-bars .progress {
  position: relative;
  overflow: visible;
  height: 12px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  opacity: 0;
}

.progress-bars .progress + .progress {
  margin-top: 40px;
}

.progress-bars .progress p {
  margin-bottom: 10px;
  position: relative;
  left: 0;
  display: inline-block;
}

.progress-bars .progress p,
.progress-bars .progress .progress-bar span {
  top: -24px;
}

.progress-bars .progress .progress-bar {
  float: none;
  position: absolute;
  height: 100%;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: width 1s ease-in-out .3s;
  -o-transition: width 1s ease-in-out .3s;
  transition: width 1s ease-in-out .3s;
  background: -webkit-linear-gradient(90deg, #f6663f 31%, #ed145b 69%);
  background: -webkit-gradient(linear, left top, right top, color-stop(31%, #f6663f), color-stop(69%, #ed145b));
  background: -webkit-linear-gradient(left, #f6663f 31%, #ed145b 69%);
  background: -o-linear-gradient(left, #f6663f 31%, #ed145b 69%);
  background: linear-gradient(90deg, #f6663f 31%, #ed145b 69%);
}

.progress-bars .progress .progress-bar span {
  display: block;
  position: absolute;
  right: 0;
  width: 40px;
  padding: 0;
}

@media (max-width: 992px) {
  .team-box {
    padding: 20px 20px 89px 20px;
  }
  .team-box .team-content {
    -webkit-transform: translateY(56%);
    -ms-transform: translateY(56%);
    -o-transform: translateY(56%);
    transform: translateY(56%);
  }
  .team-box.single .team-content {
    -webkit-transform: translateY(40%);
    -ms-transform: translateY(40%);
    -o-transform: translateY(40%);
    transform: translateY(40%);
  }
}

@media (max-width: 768px) {
  .team-box {
    padding: 20px 20px 85px 20px;
  }
  .team-box .team-content {
    -webkit-transform: translateY(57%);
    -ms-transform: translateY(57%);
    -o-transform: translateY(57%);
    transform: translateY(57%);
  }
}

/*-------------------------------*/
/*Our Team ends */
/*-------------------------------*/
/*-------------------------------*/
/* Gallery Portfolio */
/*-------------------------------*/
/*filters*/
.cbp-l-filters .cbp-filter-item {
  cursor: pointer;
  margin: 0 .2rem;
  display: inline-block;
}

.cbp-l-filters .cbp-filter-item span {
  color: #b7b7b7;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  position: relative;
  padding: 5px 1.125rem;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  border: 1px solid #b7b7b7;
}

.cbp-l-filters .cbp-filter-item-active {
  cursor: default;
}

.cbp-l-filters .cbp-filter-item:hover span {
  border: 1px solid #643094;
  color: #fff;
  background: #643094;
}

.cbp-l-filters .cbp-filter-item-active span,
.cbp-l-filters .cbp-filter-item:focus span {
  border: 1px solid #00bcd4;
  color: #fff;
  background: #00bcd4;
}

#portfolio_top {
  position: relative;
  overflow: hidden;
}

#portfolio_top::before,
#portfolio_top::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  height: 90px;
  width: 100%;
}

#portfolio_top::before {
  top: 0;
}

#portfolio_top::after {
  bottom: 0;
}

.text_wrap {
  padding: 50px 2rem 0.5rem 2rem;
}

.itemshadow .cbp-item-wrapper {
  -webkit-box-shadow: 0 0 4px 1px rgba(101, 101, 101, 0.2);
  box-shadow: 0 0 4px 1px rgba(101, 101, 101, 0.2);
  padding-bottom: 2px;
  padding-top: 5px;
}

.border-portfolio .cbp-item img {
  border: 20px solid #fff;
}

.bottom-text {
  display: table;
}

.bottom-text > .cells {
  display: table-cell;
  vertical-align: middle;
  padding: 15px;
}

.bottom-text p {
  font-size: 15px;
  margin-bottom: 0;
}

.bottom-text .port_head {
  font-weight: bold;
  font-size: 5.625rem;
  line-height: 1;
  font-family: 'Open Sans', sans-serif;
}

/*Overlays*/
.overlay {
  position: absolute;
  left: 20px;
  top: 20px;
  right: 20px;
  bottom: 20px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: rgba(0, 207, 204, 0.9);
  -webkit-transition: all .5s ease-in .1s;
  -o-transition: all .5s ease-in .1s;
  transition: all .5s ease-in .1s;
}

.simple_overlay .overlay {
  -webkit-border-radius: 0;
  border-radius: 0;
}

.dark_overlay .overlay {
  background: rgba(33, 35, 49, 0.9);
}

.cbp-item:hover .overlay,
.cbp-item:focus .overlay {
  opacity: 1;
  visibility: visible;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.cbp-item:hover .overlay {
  opacity: 1;
  visibility: visible;
}

.overlay > .plus {
  display: inline-block;
  height: 70px;
  width: 70px;
  position: relative;
  overflow: hidden;
}

.overlay > .plus::before,
.overlay > .plus::after {
  content: " ";
  position: absolute;
  background: #fff;
}

.overlay > .plus::after {
  height: 2px;
  margin-top: -1px;
  top: 50%;
  left: 5px;
  right: 5px;
}

.overlay > .plus::before {
  width: 2px;
  margin-left: -1px;
  left: 50%;
  top: 5px;
  bottom: 5px;
}

.overlay p {
  font-size: 13px;
}

.gradient_text {
  background: -webkit-gradient(linear, left top, right top, from(#00e3ce), to(#00abc9));
  background: -webkit-linear-gradient(left, #00e3ce, #00abc9);
  background: -o-linear-gradient(left, #00e3ce, #00abc9);
  background: linear-gradient(90deg, #00e3ce, #00abc9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*Single Porfolio*/
.item-one {
  background: url(../images/bg-single-1.jpg) no-repeat;
}

.item-two {
  background: url(../images/bg-single-2.jpg) no-repeat;
}

.item-three {
  background: url(../images/bg-single-3.jpg) no-repeat;
}

.item-four {
  background: url(../images/bg-single-4.jpg) no-repeat;
}

.item-five {
  background: url(../images/bg-single-5.jpg) no-repeat;
}

.item-six {
  background: url(../images/bg-single-6.jpg) no-repeat;
}

.item-seven {
  background: url(../images/bg-single-7.jpg) no-repeat;
}

.item-eight {
  background: url(../images/bg-single-8.jpg) no-repeat;
}

.item-nine {
  background: url(../images/bg-single-9.jpg) no-repeat;
}

.single-items {
  background-attachment: fixed;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;
  width: 100%;
}

@media (max-width: 768px) {
  #portfolio_top::after {
    background: inherit;
  }
  .bottom-text > .cells {
    display: table;
  }
}

@media (max-width: 767px) {
  #portfolio_top::before {
    display: none;
  }
  #portfolio_top .text_wrap {
    padding-top: 0;
  }
  #portfolio_top .bottom-text {
    text-align: center;
    display: block;
  }
  .bottom-text > .cells {
    display: inline-block;
    width: 100%;
    padding: 30px 15px;
  }
}

/*-------------------------------*/
/*Gallery ends */
/*-------------------------------*/
/*-------------------------------*/
/* Mobile Apps & Features */
/*-------------------------------*/
/*single feature*/
.single-feature, .bg-apps {
  position: relative;
}

/*apps background*/
.bg-apps {
  background: url(../images/bg-apps.jpg) no-repeat;
  background-attachment: fixed;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
}

/*app features*/
.feature-item .icon {
  -webkit-transition: all .5s ease-in;
  -o-transition: all .5s ease-in;
  transition: all .5s ease-in;
}

.opacities {
  opacity: .5;
}

#app-feature .feature-item {
  padding: 15px 0;
}

#app-feature .feature-item:hover {
  cursor: pointer;
}

.feature-item img,
.feature-item .icon {
  display: inline-block;
}

.feature-item img {
  max-width: 290px;
}

.feature-item h4 {
  font-weight: 600;
  color: #222222;
  font-size: 1.125rem;
  margin-bottom: 10px;
}

.feature-item .icon {
  background: #00bcd4;
  width: 90px;
  height: 90px;
  text-align: center;
  position: relative;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  z-index: 1;
  font-size: 34px;
  line-height: 90px;
  color: #fff;
}

.feature-item .icon::before {
  content: "";
  background: transparent;
  top: 1px;
  left: 1px;
  bottom: 1px;
  right: 1px;
  position: absolute;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 4px solid #fff;
}

.feature-item:hover .icon {
  -webkit-animation: itg_pulse 1s infinite;
  -o-animation: itg_pulse 1s infinite;
  animation: itg_pulse 1s infinite;
  -webkit-box-shadow: 0 0 0 0 #643094;
  box-shadow: 0 0 0 0 #643094;
}

@-webkit-keyframes itg_pulse {
  0% {
    -webkit-box-shadow: 0 0 0 5px rgba(100, 48, 148, 0.9);
    box-shadow: 0 0 0 5px rgba(100, 48, 148, 0.9);
  }
  25% {
    -webkit-box-shadow: 0 0 0 10px rgba(100, 48, 148, 0.6);
    box-shadow: 0 0 0 10px rgba(100, 48, 148, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(100, 48, 148, 0.3);
    box-shadow: 0 0 0 15px rgba(100, 48, 148, 0.3);
  }
  100% {
    -webkit-box-shadow: 0 0 0 60px rgba(100, 48, 148, 0.1);
    box-shadow: 0 0 0 20px rgba(100, 48, 148, 0.1);
  }
}

@-o-keyframes itg_pulse {
  0% {
    box-shadow: 0 0 0 5px rgba(100, 48, 148, 0.9);
  }
  25% {
    box-shadow: 0 0 0 10px rgba(100, 48, 148, 0.6);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(100, 48, 148, 0.3);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(100, 48, 148, 0.1);
  }
}

@keyframes itg_pulse {
  0% {
    -webkit-box-shadow: 0 0 0 5px rgba(100, 48, 148, 0.9);
    box-shadow: 0 0 0 5px rgba(100, 48, 148, 0.9);
  }
  25% {
    -webkit-box-shadow: 0 0 0 10px rgba(100, 48, 148, 0.6);
    box-shadow: 0 0 0 10px rgba(100, 48, 148, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(100, 48, 148, 0.3);
    box-shadow: 0 0 0 15px rgba(100, 48, 148, 0.3);
  }
  100% {
    -webkit-box-shadow: 0 0 0 60px rgba(100, 48, 148, 0.1);
    box-shadow: 0 0 0 20px rgba(100, 48, 148, 0.1);
  }
}

#app-feature .feature-item:hover .icon {
  background: #643094;
}

#app-feature .content-left {
  text-align: right;
}

#app-feature .content-right {
  text-align: left;
}

#app-feature .content-left span {
  float: right;
  margin-left: 15px;
}

#app-feature .content-right span {
  float: left;
  margin-right: 15px;
}

#app-feature .content-left .text {
  margin-right: 105px;
}

#app-feature .content-right .text {
  margin-left: 105px;
}

.feature-item.active {
  background: #f7f7f7;
}

.image.mobile-apps > img {
  opacity: 0;
}

.image.mobile-apps.active > img {
  opacity: 1;
}

@media screen and (max-width: 992px) {
  #app-feature,
  #app-feature .content-left,
  #app-feature .content-right {
    text-align: center;
  }
  #app-feature .feature-item {
    padding: 0;
    max-width: 300px;
    display: inline-block;
    float: none;
  }
  #app-feature .content-left span,
  #app-feature .content-right span {
    float: none;
    margin: 0 auto 15px auto;
    display: inline-block;
  }
  #app-feature .content-left .text,
  #app-feature .content-right .text {
    margin: 0;
  }
}

/*-------------------------------*/
/*Mobile Apps ends */
/*-------------------------------*/
/*-------------------------------*/
/* Counters */
/*-------------------------------*/
.fact-iconic, .counter-photo {
  position: relative;
}

.counter-photo ::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 17px;
  background: #00bcd4;
  top: 10px;
  left: 10px;
  bottom: 0;
  display: inline-block;
  z-index: 1;
}

.counter-photo img {
  z-index: 2;
  padding: 0 15px 15px 0;
}

.fact-iconic h3, .counters .count_nums {
  font-weight: bold;
}

.fact-iconic h3::after {
  content: "";
  height: 3px;
  width: 50px;
  background: #ffffff;
  display: block;
  margin-top: 15px;
}

.text-center .fact-iconic h3::after,
.text-center.fact-iconic h3::after {
  margin-left: auto;
  margin-right: auto;
}

.icon-counters.table-display {
  display: table;
}

.icon-counters .img-icon {
  text-align: center;
  font-size: 40px;
  height: 70px;
  width: 70px;
  line-height: 70px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all .7s ease-in .3s;
  -o-transition: all .7s ease-in .3s;
  transition: all .7s ease-in .3s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.bg-counter-light .icon-counters .img-icon {
  height: 100px;
  width: 100px;
  line-height: 100px;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.icon-counters.table-display .img-icon {
  display: table-cell;
  vertical-align: middle;
  font-size: 50px;
  width: 90px;
}

.icon-counters.table-display .img-icon,
.icon-counters.table-display:hover .img-icon,
.bg-counter-light .icon-counters .img-icon,
.bg-counter-light .icon-counters:hover .img-icon {
  -webkit-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.icon-counters:hover .img-icon {
  -webkit-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.bg-counter-light .icon-counters:hover .img-icon {
  color: #fff;
  background: #00bcd4;
}

.icon-counters .img-icon,
.counters .count_nums, .counters i {
  display: inline-block;
}

.counters .count_nums {
  font-size: 1.875rem;
}

.icon-counters.table-display .count_nums {
  font-size: 2.5rem;
}

.counters .count_nums, .counters i {
  vertical-align: middle;
}

.bg-counter-light {
  background: url(../images/bg-counter-light.jpg) no-repeat;
  background-attachment: fixed;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
}

@media screen and (max-width: 767px) {
  .fact-iconic h3::after {
    margin-left: auto;
    margin-right: auto;
  }
}

/*-------------------------------*/
/*Counters ends */
/*-------------------------------*/
/*-------------------------------*/
/* Pricings */
/*-------------------------------*/
.price-table, .price-table * {
  -webkit-transition: all .4s ease-in;
  -o-transition: all .4s ease-in;
  transition: all .4s ease-in;
}

.pricing-bg {
  background: url(../images/page-header-2.jpg) no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  width: 100%;
}

.pricing-bg::before {
  content: "";
  height: 50%;
  width: 100%;
  bottom: 0;
  position: absolute;
  background: #f6f6f6;
}

.price-table {
  padding: 4.375rem 5.5rem;
  border: 1px solid #f6f3f3;
  background: #fff;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.square-layout .price-table {
  -webkit-border-radius: 0;
  border-radius: 0;
}

.three-col-pricing .price-table,
.centered-table .price-table {
  padding: 3rem 1.5rem;
}

.three-col-pricing .price-table,
.centered-table .price-table,
.three-col-pricing .price-table ul,
.centered-table .price-table ul {
  text-align: center;
}

.price-table h3 {
  font-size: 1.875rem;
}

.price-table h3, .price-table .ammount h2 {
  font-weight: normal;
}

.centered-table .price-table h3,
.centered-table .price-table .ammount h2 {
  font-weight: bold;
}

.price-table .ammount *,
.price-table ul, .price-table ul li > span {
  display: inline-block;
}

.price-table .ammount * {
  vertical-align: middle;
}

.price-table .ammount .dur {
  font-size: 1rem;
  color: #545661;
  margin-top: 10px;
}

.price-table .ammount i {
  margin-top: -5px;
  font-size: 80%;
  margin-right: -5px;
}

.price-table .ammount h2 {
  line-height: 1;
  font-size: 3.125rem;
}

.price-table.active .ammount h2 {
  color: #643094;
}

.price-table ul {
  text-align: left;
}

.price-table ul li {
  margin-top: 1.25rem;
}

.price-table ul li {
  color: #6e6e6e;
  font-size: 14px;
}

.price-table ul li.not-support {
  color: #9b9b9b;
}

.price-table ul li.not-support > span {
  text-decoration: line-through;
}

.price-table ul li > span {
  padding-left: 20px;
}

.price-table ul li > span::before {
  content: "\f00c";
  color: #30e512;
  margin-right: 8px;
  width: 20px;
  margin-left: -20px;
}

.price-table ul li.not-support > span::before {
  content: "\f00d";
  color: #ff434b;
}

.centered-table .price-table ul li > span::before {
  display: none;
}

.centered-table .price-table ul li > span::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #f6f6f6;
  margin: 0 auto;
  display: inline-block;
}

.centered-table .price-table ul li:last-child > span::after {
  width: 0;
}

.price-table:hover {
  background: #00bcd4;
  border-color: #00bcd4;
}

.price-table.active:hover {
  background: #643094;
  border-color: #643094;
}

.price-table:hover *,
.price-table:hover .ammount *,
.price-table:hover .ammount h2,
.price-table:hover .ammount .dur,
.price-table:hover ul li > span::before,
.price-table:hover .btnsecondary,
.price-table:focus .btnsecondary,
.price-table:hover .btnprimary,
.price-table:focus .btnprimary {
  color: #fff;
}

.price-table:hover .btnsecondary,
.price-table:focus .btnsecondary,
.price-table:hover .btnprimary,
.price-table:focus .btnprimary {
  border-color: #fff;
  background: transparent;
}

.price-table .btnsecondary:hover,
.price-table .btnsecondary:focus,
.price-table .btnprimary:hover,
.price-table .btnprimary:focus {
  border-color: #fff;
  background: #fff;
  color: #212331;
}

.price-table:hover {
  -webkit-box-shadow: 0 0 5px rgba(101, 101, 101, 0.2);
  box-shadow: 0 0 5px rgba(101, 101, 101, 0.2);
}

@media (max-width: 992px) {
  .price-table {
    padding: 3.375rem 4rem;
  }
}

@media (max-width: 768px) {
  .price-table {
    padding: 2.375rem 2rem;
  }
}

@media (max-width: 768px) {
  .price-table {
    padding: 2.375rem 2rem;
  }
}

/*-------------------------------*/
/* Pricings ends */
/*-------------------------------*/
/*-------------------------------*/
/*Backgrounds Parallax */
/*-------------------------------*/
/*banner parallax*/
#video-parallax {
  background: url(../images/bg-video.jpg) no-repeat;
}

.rotating-slider {
  background: url(../images/page-header-2.jpg) no-repeat;
}

.rotating-words {
  background: url(../images/page-header-2.jpg) no-repeat;
}

.rotating-slider {
  background: url(../images/nexpi_img/blocchi.jpg) no-repeat;
}

.rotating-slider.studio-ver {
  background: url(../images/studio-fade.jpg) no-repeat;
}

.ineractive-ver {
  background: url(../images/banner-interactive-agency.jpg) no-repeat;
}

.rotating-slider, .rotating-words, #video-parallax, .rotating-slider, .ineractive-ver {
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  width: 100%;
}

#bgndVideo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

iframe {
  height: 100%;
  width: 100%;
}

.jquery-background-video-pauseplay {
  opacity: 0;
}

.fontregular {
  font-weight: normal;
}

.video-parallax h2 {
  font-size: 3.125rem;
}

.video-parallax .heading-title > span {
  font-weight: 300;
  font-size: 1.25rem;
  text-transform: inherit;
}

.video-parallax .button {
  padding-bottom: .9rem;
}

.button-play {
  height: 70px;
  width: 70px;
  line-height: 70px;
  color: #fff;
  display: inline-block;
  text-align: center;
  font-size: 30px;
  top: 50%;
  left: 50%;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: -webkit-linear-gradient(90deg, #423f9c, #862359);
  background: -webkit-gradient(linear, left top, right top, color-stop(#423f9c), color-stop(#862359));
  background: -webkit-linear-gradient(left, #423f9c, #862359);
  background: -o-linear-gradient(left, #423f9c, #862359);
  background: -webkit-gradient(linear, left top, right top, from(#423f9c), to(#862359));
  background: linear-gradient(90deg, #423f9c, #862359);
}

.button-play, .button-play::before {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
}

.button-play::before {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  background: -webkit-linear-gradient(90deg, #643094, #00bcd4);
  background: -webkit-gradient(linear, left top, right top, color-stop(#643094), color-stop(#00bcd4));
  background: -webkit-linear-gradient(left, #643094, #00bcd4);
  background: -o-linear-gradient(left, #643094, #00bcd4);
  background: -webkit-gradient(linear, left top, right top, from(#643094), to(#00bcd4));
  background: linear-gradient(90deg, #643094, #00bcd4);
}

.button-play:hover::before,
.button-play:focus::before {
  opacity: 1;
  visibility: visible;
}

.button-play:hover, .button-play:focus {
  color: #fff;
}

/*Background Video*/
.bg-video-container::before {
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background: rgba(33, 35, 49, 0.7);
  z-index: 1;
}

.bg-video-container .video-content {
  position: relative;
  z-index: 5;
}

/*Background Particle*/
#particles-js {
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: .6;
}

/*-------------------------------*/
/*Backgrounds Parallax ends */
/*-------------------------------*/
/*-------------------------------*/
/* Testimonials*/
/*-------------------------------*/
.testimonial-wrapp,
.testimonial-wrapp .testimonial-text,
.testimonial-wrapp .testimonial-photo,
.testimonial-wrapp .quoted, .testimonial-quote {
  position: relative;
  -webkit-transition: all .4s ease-in;
  -o-transition: all .4s ease-in;
  transition: all .4s ease-in;
}

.testimonial-wrapp,
.testimonial-wrapp .testimonial-text {
  text-align: center;
}

.testimonial-wrapp .quoted,
.testimonial-wrapp .testimonial-photo {
  display: inline-block;
}

.testimonial-wrapp .quoted {
  background: #643094;
  color: #fff;
  font-size: 20px;
  line-height: 50px;
  height: 50px;
  width: 50px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  -o-transform: translateY(50%);
  transform: translateY(50%);
  z-index: 1;
}

.testimonial-wrapp .testimonial-text {
  background: #f6f6f6;
  padding: 4.25rem 2.5rem;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
}

.testimonial-wrapp .testimonial-photo {
  height: 100px;
  width: 100px;
  margin-top: -50px;
}

.testimonial-wrapp .testimonial-photo,
.testimonial-wrapp .testimonial-photo > img {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

#testimonial-slider .owl-item:nth-child(2n) .testimonial-wrapp .quoted,
#testimonial-slider .owl-item:nth-child(2n) .testimonial-wrapp:hover .testimonial-text {
  background: #00bcd4;
}

.testimonial-wrapp:hover .testimonial-text {
  background: #643094;
  color: #fff;
}

/*Testimonial Quotes*/
.testimonial-bg {
  background: url(../images/bg-testimonial.jpg) no-repeat;
}

.testimonial-bg-light {
  background: url(../images/bg-testimonial-light.jpg) no-repeat;
}

.testimonial-bg, .testimonial-bg-light {
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  width: 100%;
}

.testimonial-quote {
  text-align: left;
  padding-top: 10px;
}

.no-quote .testimonial-quote {
  text-align: center;
}

.testimonial-quote h3 {
  position: relative;
}

.no-quote .testimonial-quote h3::before,
.no-quote .testimonial-quote h3::after {
  display: none;
}

.testimonial-quote h3::before, .testimonial-quote h3::after {
  display: inline-block;
  font-size: 17px;
}

.testimonial-quote h3::before {
  content: "\f10d";
  margin-right: 3px;
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
}

.testimonial-quote h3::after {
  content: "\f10e";
  margin-left: 3px;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
}

.testimonial-quote h6 {
  font-weight: 300;
}

#owl-thumbs.owl-dots {
  text-align: left;
}

#owl-thumbs.owl-dots .owl-dot {
  background: transparent;
  height: 60px;
  margin: 0;
  position: relative;
  width: 60px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  margin-left: -30px;
  opacity: .65;
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
}

.testimonial-bg-light #owl-thumbs.owl-dots .owl-dot {
  border: 5px solid rgba(0, 0, 0, 0.3);
}

#owl-thumbs.owl-dots .owl-dot > img {
  width: 100%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

#owl-thumbs.owl-dots .owl-dot:first-child {
  margin-left: 0;
}

#owl-thumbs.owl-dots .owl-dot::after {
  display: none;
}

#owl-thumbs.owl-dots .owl-dot:hover,
#owl-thumbs.owl-dots .owl-dot.active {
  z-index: 5;
  opacity: 1;
  border: 5px solid rgba(255, 255, 255, 0.53);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.testimonial-bg-light #owl-thumbs.owl-dots .owl-dot.active {
  border: 5px solid rgba(0, 0, 0, 0.53);
}

/*-------------------------------*/
/* Testimonials ends*/
/*-------------------------------*/
/*-------------------------------*/
/* Partner/ Logo's*/
/*-------------------------------*/
#partners-slider .item {
  text-align: center;
}

#partners-slider .item,
#partners-slider .logo-item {
  position: relative;
}

#partners-slider .logo-item {
  width: 160px;
  height: 60px;
  overflow: hidden;
}

#partners-slider .logo-item, #partners-slider .logo-item > img {
  display: inline-block;
}

.logo-item > img {
  width: 98%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
}

/*-------------------------------*/
/* Partner/ Logo's ends*/
/*-------------------------------*/
/*-------------------------------*/
/* Our Blog*/
/*-------------------------------*/
.news_item {
  background: #fff;
}

.news_item, .eny_profile,
blockquote.blockquote {
  position: relative;
}

.news_item.shadow {
  -webkit-box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.news_item .news_desc {
  padding: 40px 30px;
}

.meta-tags li, .meta-tags li a,
blockquote.blockquote::before,
ul.rounded li::before {
  display: inline-block;
}

.meta-tags > li + li {
  margin-left: 10px;
}

.meta-tags li a {
  color: #a5a5a5;
  font-size: 12px;
  font-weight: 600;
}

.meta-tags li a > i {
  font-size: 17px;
  margin-right: 5px;
}

.meta-tags li a > i,
.meta-tags > li + li::before {
  vertical-align: middle;
}

.news_item:hover h3,
.meta-tags li a:hover,
.meta-tags li a:focus {
  color: #00bcd4;
}

/*Pagination*/
.pagination li > a {
  height: 36px;
  width: 36px;
  padding: 0;
  line-height: 36px;
  font-size: 18px;
  color: #a5a5a5;
  text-align: center;
  margin: 0 4px;
  border: none;
}

.pagination li > a,
.pagination li:first-child a,
.pagination li:last-child a {
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important;
}

.pagination li > a:hover, .pagination li > a:focus {
  background: #00bcd4;
}

.pagination li > a:hover, .pagination li > a:focus,
.pagination li.active > a {
  color: #fff;
}

.pagination li.active > a {
  background: #643094 !important;
}

blockquote.blockquote {
  font-style: italic;
}

blockquote.blockquote::before {
  content: "\f10d";
  width: 40px;
  font-size: 28px;
  margin-right: 5px;
  color: #a1a1a1;
}

ul.rounded > li + li {
  margin-top: 20px;
}

ul.rounded li::before {
  content: "";
  height: 10px;
  width: 10px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #00bcd4;
  margin-right: 10px;
  vertical-align: middle;
}

/*Post Comments*/
.eny_profile {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 30px;
}

.eny_profile .profile_photo, .eny_profile .profile_text {
  display: table-cell;
  vertical-align: top;
}

.eny_profile .profile_photo {
  height: 90px;
  width: 90px;
}

.eny_profile .profile_photo > img {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 100%;
}

.eny_profile .profile_text {
  padding-left: 20px;
}

/*Widget Newlatter or search*/
.widget_search .input-group {
  width: 260px;
}

.widget_search .form-control {
  width: 214px;
  -webkit-border-bottom-left-radius: 20px;
  border-bottom-left-radius: 20px;
  -webkit-border-top-left-radius: 20px;
  border-top-left-radius: 20px;
}

.widget_search .form-control, .widget_search .input-group-addon {
  border-color: #d6d5d5;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: table-cell;
}

.widget_search .input-group-addon {
  height: 38px;
  background-color: #00bcd4;
  color: #fff;
  position: relative;
  top: 0;
  left: -5px;
  width: 40px;
  -webkit-border-top-right-radius: 20px;
  border-top-right-radius: 20px;
  -webkit-border-bottom-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.widget_search .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #414141;
}

/*web Tags*/
.webtags li {
  display: inline-block;
  margin: 1px;
}

.webtags li a, .btn_reply {
  -webkit-border-radius: 24px;
  border-radius: 24px;
  color: #6a6a6a;
  font-size: 12px;
  margin: 2px 0;
  padding: 5px 16px;
  border: 1px solid #c7c7c7;
  display: block;
}

.webtags li a:hover, .webtags li a:focus {
  color: #fff;
  background: #00bcd4;
  border: 1px solid #00bcd4;
}

/*Recent Sidebar*/
.single_post {
  overflow: hidden;
  display: table;
}

.single_post .post, .single_post .text {
  display: table-cell;
}

.single_post .post {
  height: 60px;
  width: 60px;
}

.single_post .post > img {
  width: 100%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.single_post .text {
  vertical-align: middle;
  padding-left: 10px;
  font-size: 14px;
}

.single_post .text > a {
  font-weight: 600;
}

.single_post span {
  font-size: 11px;
  display: block;
}

.single_post:hover a, .single_post:focus a {
  color: #00bcd4;
}

/*Categories*/
.webcats li, .webcats li a {
  display: block;
}

.webcats > li + li {
  margin-top: 10px;
}

.webcats li a::before {
  content: "";
  height: 8px;
  width: 8px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #00bcd4;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

.webcats li a > span {
  display: inline-block;
  font-weight: 600;
  margin-left: 5px;
  font-size: .875rem;
}

.webcats li a:hover, .webcats li a:focus {
  color: #00bcd4;
}

.whitebox .widget {
  padding: 40px 30px;
  background: #fff;
  -webkit-box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.1);
}

/*-------------------------------*/
/* Our Blog ends*/
/*-------------------------------*/
/*-------------------------------*/
/* Ccntact US*/
/*-------------------------------*/
#map-container {
  height: 450px;
  width: 100%;
  position: relative;
}

.our-address h5 {
  color: #222;
  font-weight: bold;
}

.our-address .pickus {
  font-size: 13px;
  text-transform: uppercase;
  color: #00bcd4;
  font-weight: 800;
  position: relative;
}

.our-address .pickus,
.our-address .pickus::after {
  display: inline-block;
}

.our-address .pickus::after {
  content: attr(data-text);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  color: #212331;
  overflow: hidden;
  -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
  -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
}

.our-address .pickus:hover::after {
  width: 100%;
}

.our-address .pickus:hover,
.our-address .pickus:focus {
  color: #212331;
}

/*Contact Form*/
.getin_form .form-control {
  border: none;
  border-bottom: 1px solid #a5a5a5;
  padding: 12px 0;
  background: #fff;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 44px;
  color: #a5a5a5;
  font-size: 14px;
  position: relative;
  -webkit-transition: border .9s ease;
  -o-transition: border .9s ease;
  transition: border .9s ease;
}

textarea {
  min-height: 175px;
  resize: none;
}

button {
  border: none;
  cursor: pointer;
}

.getin_form .form-control:focus {
  border-bottom: 1px solid #212331;
}

.border-form .form-control {
  border: 1px solid #dedada;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-border-radius: 24px;
  border-radius: 24px;
}

.getin_form .button {
  width: 100%;
}

.logincontainer {
  padding: 3.5rem 1.5rem;
}

.border-form .form-control:focus {
  border: 1px solid #212331;
}

/*CheckBox*/
.form-check {
  position: relative;
  display: block;
  font-size: 13px;
}

.form-group label, .checkbox label {
  font-size: 12px;
  font-weight: 500;
  color: #a5a5a5;
  display: inline-block;
  position: relative;
  padding-left: 10px;
  min-height: 20px;
  cursor: pointer;
}

.form-check label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.form-check input[type="checkbox"]:checked + label::before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00c";
  font-size: 14px;
  text-align: center;
  line-height: 16px;
  font-weight: 300;
}

.form-check input[type="checkbox"]:checked + label::before {
  background-color: #fff;
}

.form-check input[type="checkbox"]:checked + label::before {
  background-color: #00bcd4;
  border-color: #00bcd4;
  color: #fff;
}

.log-meta {
  margin-bottom: 0;
}

.log-meta > a:hover, .log-meta > a:focus {
  color: #00bcd4;
}

/* Form general- */
::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: #a5a5a5;
}

:-moz-placeholder,
.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #a5a5a5;
}

::-moz-placeholder,
.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #a5a5a5;
}

:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: #a5a5a5;
}

textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder {
  color: #a5a5a5;
  opacity: 1;
}

/*-------------------------------*/
/*  COntact US */
/*-------------------------------*/
/*-------------------------------*/
/* Error */
/*-------------------------------*/
#error .error, .error h1 {
  position: relative;
}

.error h1, .error h2 {
  font-weight: bold;
}

.error h1 {
  color: #ebebeb;
  font-size: 10rem;
}

.error h2 {
  color: #414141;
  font-size: 3.2rem;
  left: 50%;
  position: absolute;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#error p {
  margin-left: 10%;
  margin-right: 10%;
}

/*-------------------------------*/
/* Error ends */
/*-------------------------------*/
/*-------------------------------*/
/* Tabs & Accordions*/
/*-------------------------------*/
.accordion .card-header:after {
  font-family: 'FontAwesome';
  content: "\f068";
  float: right;
}

.accordion .card-header.collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\f067";
}

/*-------------------------------*/
/* Tabs & Accordions*/
/*-------------------------------*/
/*-------------------------------*/
/*Crypto*/
/*-------------------------------*/
.crypto-ver {
  background: url(../images/banner-crypto.jpg) no-repeat fixed center center;
  -webkit-background-size: cover;
  background-size: cover;
}

.btc-calcus {
  background: #fff;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 2.5rem 1rem;
}

.btc-calcus .cal-convertor .ccc-widget.ccc-converter > div:first-child {
  border: none !important;
  padding: 0px !important;
  background: none !important;
}

.convertForm label.priceLabel {
  font-weight: 300;
  padding-bottom: 0;
}

.convertForm label.priceLabel,
.convertForm .fromSymbolLabel,
.convertForm .styledSelect {
  font-size: 14px;
  color: #212331 !important;
}

.convertForm .fromSymbolLabel {
  line-height: 44px;
  border: 1px solid #00bcd4;
  background: #00bcd4;
  -webkit-border-top-right-radius: 20px;
  border-top-right-radius: 20px;
  -webkit-border-bottom-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.convertForm input.priceInput {
  font-family: 'Open Sans', sans-serif;
  height: 45px;
  -webkit-border-top-left-radius: 20px !important;
  border-top-left-radius: 20px !important;
  -webkit-border-bottom-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.convertForm .styledSelect {
  -webkit-border-top-right-radius: 20px;
  border-top-right-radius: 20px;
  -webkit-border-bottom-right-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 45px;
}

.convertForm .poweredByWrapper {
  display: none;
}

.section-converter-ccc {
  margin-bottom: 20px;
}

/*-------------------------------*/
/* Crypto ends */
/*-------------------------------*/
/*-------------------------------*/
/* Footer*/
/*-------------------------------*/
footer .copyrights {
  font-size: 13px;
}

.footer-logo {
  display: inline-block;
  max-width: 230px;
}

footer ul.social-icons:not(.small) li a {
  height: 60px;
  width: 60px;
  line-height: 60px;
  font-size: 26px;
  border: none;
  background: transparent;
}

footer ul.social-icons li a {
  background: #f5f5f5;
}

footer .copyrights > a:hover,
footer .copyrights > a:focus {
  color: #00bcd4;
}

/*-------------------------------*/
/* Footer ends*/
/*-------------------------------*/
/*-------------------------------*/
/*PreLoader*/
/*-------------------------------*/
.loader {
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1111;
  background: #fff;
  overflow-x: hidden;
}

.loader-inner {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader-inner > img {
  width: 120px;
  max-width: 120px;
  display: inline-block;
}

.loader-blocks {
  height: 58px;
  width: 58px;
}

.loader span {
  background: -webkit-linear-gradient(90deg, #423f9c 31%, #862359 69%);
  background: -webkit-gradient(linear, left top, right top, color-stop(31%, #423f9c), color-stop(69%, #862359));
  background: -webkit-linear-gradient(left, #423f9c 31%, #862359 69%);
  background: -o-linear-gradient(left, #423f9c 31%, #862359 69%);
  background: linear-gradient(90deg, #423f9c 31%, #862359 69%);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: block;
  height: 12px;
  opacity: 0;
  position: absolute;
  width: 12px;
  animation: load 4.6s ease-in-out infinite;
  -o-animation: load 4.6s ease-in-out infinite;
  -ms-animation: load 4.6s ease-in-out infinite;
  -webkit-animation: load 4.6s ease-in-out infinite;
  -moz-animation: load 4.6s ease-in-out infinite;
}

.loader span.block-1 {
  animation-delay: 1.06s;
  -o-animation-delay: 1.06s;
  -ms-animation-delay: 1.06s;
  -webkit-animation-delay: 1.06s;
  -moz-animation-delay: 1.06s;
  left: 0px;
  top: 0px;
}

.loader span.block-2 {
  animation-delay: 0.97s;
  -o-animation-delay: 0.97s;
  -ms-animation-delay: 0.97s;
  -webkit-animation-delay: 0.97s;
  -moz-animation-delay: 0.97s;
  left: 16px;
  top: 0px;
}

.loader span.block-3 {
  animation-delay: 0.87s;
  -o-animation-delay: 0.87s;
  -ms-animation-delay: 0.87s;
  -webkit-animation-delay: 0.87s;
  -moz-animation-delay: 0.87s;
  left: 31px;
  top: 0px;
}

.loader span.block-4 {
  animation-delay: 0.78s;
  -o-animation-delay: 0.78s;
  -ms-animation-delay: 0.78s;
  -webkit-animation-delay: 0.78s;
  -moz-animation-delay: 0.78s;
  left: 47px;
  top: 0px;
}

.loader span.block-5 {
  animation-delay: 0.69s;
  -o-animation-delay: 0.69s;
  -ms-animation-delay: 0.69s;
  -webkit-animation-delay: 0.69s;
  -moz-animation-delay: 0.69s;
  left: 0px;
  top: 16px;
}

.loader span.block-6 {
  animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  left: 16px;
  top: 16px;
}

.loader span.block-7 {
  animation-delay: 0.51s;
  -o-animation-delay: 0.51s;
  -ms-animation-delay: 0.51s;
  -webkit-animation-delay: 0.51s;
  -moz-animation-delay: 0.51s;
  left: 31px;
  top: 16px;
}

.loader span.block-8 {
  animation-delay: 0.41s;
  -o-animation-delay: 0.41s;
  -ms-animation-delay: 0.41s;
  -webkit-animation-delay: 0.41s;
  -moz-animation-delay: 0.41s;
  left: 47px;
  top: 16px;
}

.loader span.block-9 {
  animation-delay: 0.32s;
  -o-animation-delay: 0.32s;
  -ms-animation-delay: 0.32s;
  -webkit-animation-delay: 0.32s;
  -moz-animation-delay: 0.32s;
  left: 0px;
  top: 31px;
}

.loader span.block-10 {
  animation-delay: 0.23s;
  -o-animation-delay: 0.23s;
  -ms-animation-delay: 0.23s;
  -webkit-animation-delay: 0.23s;
  -moz-animation-delay: 0.23s;
  left: 16px;
  top: 31px;
}

.loader span.block-11 {
  animation-delay: 0.14s;
  -o-animation-delay: 0.14s;
  -ms-animation-delay: 0.14s;
  -webkit-animation-delay: 0.14s;
  -moz-animation-delay: 0.14s;
  left: 31px;
  top: 31px;
}

.loader span.block-12 {
  animation-delay: 0.05s;
  -o-animation-delay: 0.05s;
  -ms-animation-delay: 0.05s;
  -webkit-animation-delay: 0.05s;
  -moz-animation-delay: 0.05s;
  left: 47px;
  top: 31px;
}

.loader span.block-13 {
  animation-delay: -0.05s;
  -o-animation-delay: -0.05s;
  -ms-animation-delay: -0.05s;
  -webkit-animation-delay: -0.05s;
  -moz-animation-delay: -0.05s;
  left: 0px;
  top: 47px;
}

.loader span.block-14 {
  animation-delay: -0.14s;
  -o-animation-delay: -0.14s;
  -ms-animation-delay: -0.14s;
  -webkit-animation-delay: -0.14s;
  -moz-animation-delay: -0.14s;
  left: 16px;
  top: 47px;
}

.loader span.block-15 {
  animation-delay: -0.23s;
  -o-animation-delay: -0.23s;
  -ms-animation-delay: -0.23s;
  -webkit-animation-delay: -0.23s;
  -moz-animation-delay: -0.23s;
  left: 31px;
  top: 47px;
}

.loader span.block-16 {
  animation-delay: -0.32s;
  -o-animation-delay: -0.32s;
  -ms-animation-delay: -0.32s;
  -webkit-animation-delay: -0.32s;
  -moz-animation-delay: -0.32s;
  left: 47px;
  top: 47px;
}

@keyframes load {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-97px);
    -o-transform: translateY(-97px);
    transform: translateY(-97px);
  }
  15% {
    opacity: 0;
    -webkit-transform: translateY(-97px);
    -o-transform: translateY(-97px);
    transform: translateY(-97px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  70% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  85% {
    opacity: 0;
    -webkit-transform: translateY(97px);
    -o-transform: translateY(97px);
    transform: translateY(97px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(97px);
    -o-transform: translateY(97px);
    transform: translateY(97px);
  }
}

@-o-keyframes load {
  0% {
    opacity: 0;
    -o-transform: translateY(-97px);
  }
  15% {
    opacity: 0;
    -o-transform: translateY(-97px);
  }
  30% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  70% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  85% {
    opacity: 0;
    -o-transform: translateY(97px);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(97px);
  }
}

@-webkit-keyframes load {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-97px);
  }
  15% {
    opacity: 0;
    -webkit-transform: translateY(-97px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  70% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  85% {
    opacity: 0;
    -webkit-transform: translateY(97px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(97px);
  }
}

/*-------------------------------*/
/*Loader ends*/
/*-------------------------------*/
@media screen and (max-width: 768px) {
  .container {
    max-width: 98%;
  }
}

/*Large devices (desktops, less than 1200px)*/
@media (max-width: 1200px) {
  html {
    font-size: 15px;
  }
}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 992px) {
  html {
    font-size: 14px;
  }
  h2 {
    font-size: 3rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.125rem;
  }
  p {
    font-size: 14px;
  }
  .heading-title > span {
    font-size: 1rem;
  }
}

/*Small devices (landscape phones, less than 768px)*/
@media (max-width: 768px) {
  html {
    font-size: 13px;
  }
}

@media (max-width: 576px) {
  html {
    font-size: 12px;
  }
}

.buttonBar {
  display: none;
}

.navbar-brand img {
  width: 230px;
}

.feature-item .icon:hover {
  background: #0f4c81 !important;
}

.heading-title > span, .process-wrapp li:hover > .pro-step, #app-feature h4, h2.darkcolor,
#contactus h5 {
  color: #0f4c81 !important;
}

.feature-item .icon, .side-menu {
  background: #0f4c81 !important;
}

.navbar-nav .nav-link {
  font-size: 16px;
}

.fixedmenu .navbar-nav .nav-link.active,
.fixedmenu .navbar-nav .nav-link.active:hover,
.fixedmenu .navbar-nav .nav-link.active:focus,
.center-brand.fixedmenu .navbar-nav .nav-link.active {
  background: #0a3153 !important;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus,
.sidebar-nav .navbar-nav .nav-link.active {
  color: #ccc !important;
}

.gradient_bg_default {
  background: #9d9d9d !important;
  background: linear-gradient(90deg, #9d9d9d 4%, #254561 59%, #0a3153 90%) !important;
}

#app-feature .feature-item:hover .icon {
  background: #4b4a4a !important;
}

.feature-item:hover .icon {
  -webkit-animation: itg_pulse 1s infinite;
  -o-animation: itg_pulse 1s infinite;
  animation: itg_pulse 1s infinite;
  -webkit-box-shadow: 0 0 0 0 #0a3153 !important;
  box-shadow: 0 0 0 0 #0a3153 !important;
}

#our-apps {
  background: white !important;
  background: radial-gradient(circle, white 22%, #d5d5d5 100%) !important;
}

.btnprimary, .back-top {
  background: #4b4a4a !important;
  border: 1px solid #4b4a4a !important;
}

.btnprimary:hover, .btnprimary:focus, .back-top:hover, .back-top::before {
  background: #0f4c81 !important;
  border: 1px solid #0f4c81 !important;
}

.loader span {
  background: #0f4c81 !important;
}

.process-wrapp {
  margin: 0 auto;
}

.process-wrapp li {
  min-width: 200px;
}

.owl-nav {
  display: none !important;
}
